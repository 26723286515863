<div class="flex-normal flex-direction-column flex-justify-between max-height-overflow comments-panel flex-1">
  <div id="commentTitle" class="flex-normal ap-pl-spacing-5 ap-py-spacing-3 under-line">
    <span class="Appkit4-icon icon-comment-outline"></span>
    <span class="ap-ml-spacing-2">
      {{panelTypeNme}} & Comments are {{panelInfo?.taskInfo?.visible ? '' : ' not'}} visible to customer.
    </span>
  </div>
  <div class="comments-panel ap-m-spacing-6 max-height-overflow flex-direction-column"
    *ngIf="commentList?.length > 0; else emptyComments">
    <ng-container *ngFor="let comment of commentList; let i = index;">
      <div class="flex-normal" [ngClass]="{'ap-mt-spacing-3': i > 0}">
        <div class="ap-mr-spacing-3">
          <div class="flex-normal align-items-center" *ngIf="comment.user; else noUser">
            <app-cus-avatar tabindex="-1" diameter="32" borderWidth="0" imageSrc="{{ comment.user?.avatar }}"
              name="{{ comment.user?.abbr }}">
            </app-cus-avatar>
          </div>
          <ng-template #noUser>N/A</ng-template>
        </div>
        <div class="ap-mt-spacing-2">
          <div class="flex-normal">
            <span class="app-text-heading ap-font-weight-3 ap-mr-spacing-4">
              {{comment.user?.firstNme}} {{comment.user?.lastNme}}
            </span>
            <span class="app-text-light">
              {{comment?.updatedAt | date:'MM/dd/yyyy hh:mm a'}}
            </span>
          </div>
          <div class="ap-mt-spacing-2 break-word" [innerHTML]="comment.content"></div>
          <div class="fixed-height flex align-items-center ap-mt-spacing-4" *ngIf="comment.attachedFileName">
            <span class="Appkit4-icon icon-document-text-outline"></span>
            <span appTruncateLongName class="file-name ap-ml-spacing-2 file-link text-ellipsis"
              (click)="downloadAttachment(comment)" ap-tooltip [direction]="'top'" [tooltipId]="'fileName'"
              [tooltipContent]="comment.attachedFileName" role="link" tabindex="0"
              (keyup.enter)="downloadAttachment(comment)"
              [attr.aria-label]="'download attachment ' + comment.attachedFileName">
              {{comment.attachedFileName}}
            </span>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <ng-template #emptyComments>
    <div id="noResult" class="flex-direction-column ap-m-spacing-6 flex-justify-center align-items-center max-height-overflow">
      <div class="full-max-height">
        <img src="./assets/images/empty-templates.svg" alt="No Comment Found.">
        <h2 class="ap-typography-heading-m ap-mt-spacing-5 ap-mb-spacing-6 text-center" role="alert">No Comment found.</h2>
      </div>
    </div>
  </ng-template>
  <div class="ap-mx-spacing-6 ap-mb-spacing-6">
    <h3 class="ap-typography-heading-s app-text-heading">Add a new Comment</h3>
    <div class="ap-mt-spacing-5">
      <div class="write-container" [class.focus]="isFocused">
        <ap-text-editor #textEditor [(data)]="text" [config]="textEditorConfig" (onFocus)="isFocused = true;" [id]="'commentsTextarea'"
          (onBlur)="isFocused = false;" (onChange)="onWriteChange($event)"></ap-text-editor>
      </div>
      <app-validation-message [condition]="isFailedToSend" [message]="'Failed to send the comment. Please try again.'">
      </app-validation-message>
      <app-validation-message [condition]="isOverSize" [message]="'File size exceeds 2 MB limit.'">
      </app-validation-message>
    </div>

    <div class="flex-normal flex-justify-between align-items-center ap-mt-spacing-6">
      <span (click)="fileInput.click(); $event.preventDefault();"
        class="Appkit4-icon icon-upload-light-outline cursor-pointer" ap-tooltip [direction]="'top'"
        [tooltipId]="'uploadFileBtn'"
        [tooltipContent]="'You can upload JPG, PNG, PDF, XLS, CSV or ZIP files. The max file size is 2MB.'"
        (keyup.enter)="fileInput.click(); $event.preventDefault();" tabindex="0" role="button" aria-label="upload file">
      </span>
      <input type="file" class="display-hide" accept=".csv, .xlsx, .xls, .doc, .PNG, .JPG, .JPEG, .zip, application/pdf"
        (change)="selectFile($event)" #fileInput>
      <ap-button [btnType]="'primary'" [label]="'Add Comment'" (onClick)="sendComments(); text = '';"
        [disabled]="(!comments?.length || !comments?.trim()?.length) && !selectedFile">
      </ap-button>
    </div>

    <div *ngIf="selectedFile"
      class="app-text-heading flex-normal align-items-center flex-justify-between fixed-height ap-mt-spacing-6">
      <div>
        <span class="Appkit4-icon icon-document-text-outline"></span>
        <span appTruncateLongName class="selected-file-name ap-ml-spacing-2 text-ellipsis" ap-tooltip
          [direction]="'top'" [tooltipId]="'FileName'" [tooltipContent]="attachmentFileName" ariaDescribedby="File Name"
          ariaDescribedby="FileName">
          {{attachmentFileName}}
        </span>
      </div>
      <span role="button" [attr.aria-label]="'Delete attachment ' + attachmentFileName"
        (click)="removeFile(); $event.stopPropagation();" tabindex="0"
        (keyup.enter)="removeFile(); $event.stopPropagation();"
        class="Appkit4-icon icon-circle-delete-outline cursor-pointer">
      </span>
    </div>
  </div>
</div>