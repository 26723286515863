import gql from 'graphql-tag';

export const getEmails = gql`
  query listEmails($input: ListEmailStatsInput) {
    listEmailStats(input: $input) {
      status
      count
      data {
        emailStatKey
        subject
        status
        updatedAt
        sender {
          firstNme
          lastNme
          email
          avatar
        }
        customer {
          nme
        }
      }
    }
  }
`;

export const sendEmail = gql`
  mutation sendEmail($input: SendEmailInput) {
    sendEmail(input: $input) {
      status
      message
      emailStatKey
    }
  }
`;

export const getEmailTemplateNameList = gql`
  query listEmailTemplates($input: ListEmailTemplateInput) {
    listEmailTemplates(input: $input) {
      status
      count
      data {
        templateKey
        displayName
        isActive
      }
    }
  }
`;

export const getEmailStatDetail = gql`
  query getEmailStatDetail($emailStatKey: Int!) {
    getEmailStatDetail(emailStatKey: $emailStatKey) {
      status
      message
      data {
        emailStatKey
        emailTemplateKey
        subject
        html
        theme
        status
        updatedAt
        sender {
          firstNme
          lastNme
          email
          userKey
        }
        recipientUsers {
          firstNme
          lastNme
          email
          userKey
        }
        ccUsers {
          firstNme
          lastNme
          email
          userKey
        }
        bccUsers {
          firstNme
          lastNme
          email
          userKey
        }
        customer {
          nme
          tenantKey
        }
        emailAttachments {
          emailAttachmentKey
          fileName
        }
      }
    }
  }
`;

export const getRelatedRecipientList = gql`
  query getRelatedRecipientList($tenantKey: Int) {
    getRelatedRecipientList(tenantKey: $tenantKey) {
      status
      message
      data {
        userKey
        email
        firstNme
        lastNme
      }
    }
  }
`;


export interface UpdateEmailDetailInput {
  emailStatKey: number;
  displayName: string;
  subject?: string;
  body?: string;
  theme?: string;
  ownerKey: number;
}

export interface CreateEmailDetailInput {
  emailStatKey: number;
  displayName: string;
  subject?: string;
  body?: string;
  theme?: string;
  ownerKey: number;
}

export interface SendEmailInput {
  draft: boolean;
  emailStatKey: number;
  emailTemplateKey?: number;
  tenantKey?: number;
  recipientUserKeys?: number[];
  ccUserKeys?: number[];
  bccUserKeys?: number[];
  subject?: string;
  html?: string;
  theme?: string;
  newAtchs?: any[];
  existingAtchKeys?: number[];
}
