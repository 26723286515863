import gql from 'graphql-tag';

export const listBusinessFunctions = gql`
  query listBusinessFunctions {
    listBusinessFunctions {
      status
      message
      data {
        businessFunctionKey
        name
      }
    }
  }
`;

export const onboardCustomer = gql`
  mutation onboardCustomer($customerOnboardInput: CustomerOnboardInput!) {
    onboardCustomer(input: $customerOnboardInput) {
      status
      message
      tenantKey
      data {
        tenantKey
        nme
        parent
        lineage
      }
    }
  }
`;

export const updateCustomerOnboardForm = gql`
  mutation updateCustomerOnboardForm(
    $updateCustomerOnboardFormInput: UpdateCustomerOnboardFormInput!
  ) {
    updateCustomerOnboardForm(input: $updateCustomerOnboardFormInput) {
      status
      message
    }
  }
`;

export const getOnboardCustomerForm = gql`
  query getOnboardCustomerForm($tenantKey: Int!) {
    getOnboardCustomerForm(tenantKey: $tenantKey) {
      status
      message
      data {
        tenantKey
        content
      }
    }
  }
`;

export const onboardFormCheck = gql`
  query onboardFormCheck($onboardFormCheckInput: OnboardFormCheckInput!) {
    onboardFormCheck(input: $onboardFormCheckInput) {
      status
      message
    }
  }
`;

export const getTenantAttachment = gql`
  query getTenantAttachment($tenantAttachmentKey: Int!) {
    getTenantAttachment(tenantAttachmentKey: $tenantAttachmentKey) {
      status
      message
      data {
        tenantKey
        tenantAttachmentKey
        fileName
        storageFileName
        category
        attachment {
          name
          base64Content
        }
      }
    }
  }
`;

export const getGeneralLedgers = gql`
  query getGeneralLedgers {
    listGeneralLedgers {
      status
      message
      data {
        integrationKey
        nme
        displayNme
      }
    }
  }
`;

export interface BusinessFunction {
  businessFunctionKey: number;
  name: string;
}

export interface UpdateCustomerOnboardFormInput {
  tenantKey: number;
  sectionType: SectionType;
  customer?: CustomerInfo;
  contractBilling?: ContractBillingInfo;
  bkTeam?: BKTeamInfo;
  relationship?: RelationshipInfo;
}

export interface CustomerOnboardInput {
  customer: CustomerInfo;
  contractBilling: ContractBillingInfo;
  bkTeam: BKTeamInfo;
}

export interface OnboardFormCheckInput {
  ignoreSpecials?: boolean;
  type: FormCheckType;
  data: FormUinqueCheckEntity;
}

export enum SectionType {
  CUSTOMER = 'CUSTOMER',
  CONTRACT_BILLING = 'CONTRACT_BILLING',
  BK_TEAM = 'BK_TEAM',
  RELATIONSHIP = 'RELATIONSHIP',
}

export enum EntityType {
  PARENT = 'PARENT',
  SINGLE = 'SINGLE',
  SUBENTITY = 'SUBENTITY',
}

export enum FormCheckType {
  TENANT = 'TENANT',
  USER = 'USER',
}

export interface FormUinqueCheckEntity {
  tenantName: string;
  email: string;
}

export interface ParentInfo {
  tenantKey: number;
  nme: string;
}

export interface CustomerInfo {
  entityName: string;
  parent?: number;
  industry: number;
  address: string;
  address2?: string;
  city: string;
  state: string;
  zip: string;
  timeZone: string;
  accountBasis: string;
  financialYear: string;
  monthClose: number;
  dayClose?: number | any;
  entityLevel?: number;
  generalLedger?: string;
  payBy?: string;
  isCogs?: boolean;
  dashboardDateFilter?: string;
}

export interface Integration {
  integrationKey: number;
  nme: string;
  displayNme: string;
}

export interface ContractBillingInfo {
  signedDate: Date;
  contractRenewal: Date;
  fee: number;
  arr: number;
  billingRepeat?: string;
  closeDueRepeat?: string;
  billingMonth?: number;
  closeDueMonth?: number;
  billingStartFrom?: Date;
  closeDueStartFrom?: Date;
  frequency?: number;
  monthlyCloseDue?: number;
  expansionArr: ExpansionArrInfo[];
  billingWbsCode: string;
  tenantAttachments: TenantAttachments[];
}
export interface PrimaryUserInfo {
  firstNme: string;
  formFirstNme: string;
  lastNme: string;
  formLastNme: string;
  email: string;
  phone: string;
  businessFunc?: number;
  sendInvitation: boolean;
  reason?: string;
  aliasEmail?: string;
}
export interface BKTeamInfo {
  teamLead: number;
  bk: any[];
  reviewer: number;
  budgeting?: boolean;
  kpi?: boolean;
  tax?: boolean;
  comment: string;
  tenantAttachments?: TenantAttachments;
  atchKeysToBeRmvd?: number[];
  newAtchs?: any[];
}

export interface TenantAttachments {
  taskAttachmentKey: number;
  tenantAttachmentKey?: number;
  taskLibraryKey: number;
  fileName: string;
  storageFileName: string;
  attachment: {
    name: string;
    base64Content: any;
  };
}

export interface RelationshipInfo {
  timeChargeWbsCode?: string;
  partners?: PartnerInfo[];
}

export interface BaseResponse {
  status: string;
  message: string;
  tenantKey: number;
  parentKey: number;
}

export interface ExpansionArrInfo {
  expansionArrKey: number;
  operation: OperationType;
  arr: number;
  comment: string;
}

export interface PartnerInfo {
  partnerInfoKey: number;
  operation: OperationType;
  firstNme?: string;
  lastNme?: string;
  serviceNme?: string;
  createdAt?: string;
  updatedAt?: string;
  tenantKey?: number;
}

export enum OperationType {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

export interface ExpansionArr {
  arr: string | number;
  comment: string;
  createdAt?: string;
  expansionArrKey?: string | number;
  tenantKey?: number;
  updatedAt?: string;
  operation?: OperationType;
}
