import gql from 'graphql-tag';

export const getFormTemplate = gql`
  query getFormTemplate($formDataQueryInput: FormDataQueryInput) {
    getFormTemplate(input: $formDataQueryInput) {
      status
      message
      content
    }
  }
`;

export const submitForm = gql`
  mutation submitForm($formResponseInput: FormResponseInput) {
    submitForm(input: $formResponseInput) {
      status
      message
      content
    }
  }
`;

export interface FormResponseInput {
  templateKey?: number;
  tenantKey?: number;
  content: string;
}

export interface FormResponseVariable {
  formResponseInput?: FormResponseInput;
}

export interface FormDataVariable {
  tenantKey?: number;
  uploadDate?: Date;
}
