import { unescape } from 'lodash';

export function Unescape(): PropertyDecorator {
  return (target: object, propertyKey: string) => {
    const fieldKey = `_${propertyKey}`;

    // property getter
    const getter = function() {
      return this[fieldKey];
    };

    // property setter
    const setter = function(newVal: string) {
      this[fieldKey] = unescape(newVal);
    };

    // Delete property.
    if (delete target[propertyKey]) {
      // Create new property with getter and setter
      Object.defineProperty(target, propertyKey, {
        get: getter,
        set: setter,
        enumerable: true,
        configurable: true,
      });
    }
  };
}
