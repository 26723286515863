import gql from 'graphql-tag';

export const updateFeatureFlagging = gql`
  mutation updateFeatureFlagging($input: FeatureFlaggingInput!) {
    updateFeatureFlagging(input: $input) {
      status
      message
    }
  }
`;

export const getFeatureFlagging = gql`
  query getFeatureFlagging($tenantKey: Int!) {
    getFeatureFlagging(tenantKey: $tenantKey) {
      status
      message
      data {
        disableFlagging
      }
    }
  }
`;

export const listPermissions4CustomerAdmin = gql`
  query listPermissions4CustomerAdmin {
    listPermissions4CustomerAdmin {
      status
      message
      data {
        permissionKey
        nme
        displayNme
      }
    }
  }
`;

export const listFeatureTypes = gql`
  query listFeatureTypes {
    listFeatureTypes {
      status
      message
      data {
        featureTypeKey
        name
        displayName
      }
    }
  }
`;

export const getTenantDisPermission = gql`
  query getTenantDisPermission($tenantKey: Int!) {
    getTenant(where: { tenantKey: $tenantKey }) {
      tenantKey
      nme
      disablePermissions
    }
  }
`;

export interface FeatureFlaggingInput {
  tenantKey: number;
  disableFlagging?: number[];
}

export interface FeatureType {
  featureTypeKey: number;
  name: String;
  displayName: String;
}

export interface Permission {
  permissionKey: number;
  name: String;
  displayName: String;
}
