<app-info-modal title="Your account has been deactivated" saveText="Back to homepage" [hideCancel]="true"
  [hideCross]="true" btnType="primary" (save)="logout()">
  <div class="ap-mt-spacing-5 ap-ml-spacing-4 flex-normal flex-gap-4 align-items-center">
    <app-cus-avatar diameter="40" borderWidth="0" name="{{data?.firstNme[0] + data?.lastNme[0]}}">
    </app-cus-avatar>
    <div>
      <div class="ap-typography-body break-word ap-font-weight-2">
        {{data?.firstNme + ' ' + data?.lastNme}}
      </div>
      <div class="ap-typography-body app-text-light break-word">
        {{data?.email}}
      </div>
    </div>
  </div>
  <p class="ap-mt-spacing-7">
    It seems that the administration deactivated your Bookkeeping Connect account.
  </p>
</app-info-modal>