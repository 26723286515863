import { Injectable } from '@angular/core';
import { monthsShort } from 'moment';
import {
  CurrencyCalcPipe,
  MinusToParenthesesPipe,
  PercentCalcPipe,
  ShortNumberSuffixPipe,
} from '../../shared/pipe';

@Injectable({
  providedIn: 'root',
})
export class FormatService {
  constructor(
    private readonly shortNumberSuffixPipe: ShortNumberSuffixPipe,
    private readonly minusToParentheses: MinusToParenthesesPipe,
    private readonly percentCalc: PercentCalcPipe,
    private readonly currencyCalc: CurrencyCalcPipe,
  ) {}

  formatPercent = (value: number) =>
    this.minusToParentheses.transform(this.percentCalc.transform(value));

  formatCurrency = (value: number) =>
    this.minusToParentheses.transform(
      this.currencyCalc.transform(this.shortNumberSuffixPipe.transform(value)),
    );

  formatShortCurrency = (value: number) =>
    this.minusToParentheses.transform(
      this.currencyCalc.transform(
        this.shortNumberSuffixPipe.transform(value, 0, '', '', true),
      ),
    );

  formatWithUnit = (unit?: string) => (value: number) =>
    this.minusToParentheses.transform(
      this.shortNumberSuffixPipe.transform(value, 0, '', unit),
    );

  formatShortWithUnit = (unit?: string) => (value: number) =>
    this.minusToParentheses.transform(
      this.shortNumberSuffixPipe.transform(value, 0, '', unit, true),
    );

  formatMonth = (month: string | number) =>
    (typeof month === 'number' ? monthsShort()[month - 1] : month)
      .slice(0, 3)
      .toUpperCase();
}
