import { Injectable } from '@angular/core';
import { GqlService } from '@core';
import { DomoEmbedInput, getDomoEmbeds } from '@core/gql/domo-dashboard.gql';

@Injectable({
  providedIn: 'root',
})
export class DomoDashboardService {
  constructor(private readonly gqlService: GqlService) {}

  getDomoEmbeds(domoEmbedInput: DomoEmbedInput) {
    return this.gqlService.query(getDomoEmbeds, { domoEmbedInput });
  }
}
