import { Injectable } from '@angular/core';
import { GqlService } from '@core';
import {
  ConfirmNewTaskInput,
  CreateTaskLibraryInput,
  ListTaskProgressInput,
  UpdateAgnOrRewerInput,
  UpdateSkipApprovalInput,
  UpdateTaskLibraryInput,
  UpdateTaskProgressInput,
  UpdateTaskProgressListInput,
  UpdateTaskStatusInput,
  WEEK_DATA,
  confirmNewTaskAssignment,
  createTaskLibrary,
  deleteTasks,
  getTaskAttachment,
  getTaskLibrary,
  getTaskProgress,
  getTenantInfo,
  getUsersInTaskList,
  listAssigneeOrReviewer4Tsks,
  listTaskProgressByIsPubished,
  listTaskStatus,
  updateAgnOrRewer,
  updateSkipApproval,
  updateTaskLibrary,
  updateTaskProgress,
  updateTaskProgressList,
  updateTaskStatus,
  UpdateTaskVisibilityInput,
  updateTaskVisibility,
  updateTaskProgressListForAppkit4,
} from '@core/gql/task.gql';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TaskService {
  public dynamicFilterCounts$: BehaviorSubject<any> = new BehaviorSubject(null);
  public resetFilters$: Subject<any> = new Subject();
  constructor(private readonly gqlService: GqlService) {}
  createTaskLibrary(createTaskLibraryInput: CreateTaskLibraryInput) {
    return this.gqlService.mutate(
      createTaskLibrary,
      {
        createTaskLibraryInput,
      },
      {
        useMultipart: true,
      },
    );
  }

  updateTaskLibrary(updateTaskLibraryInput: UpdateTaskLibraryInput) {
    return this.gqlService.mutate(
      updateTaskLibrary,
      {
        updateTaskLibraryInput,
      },
      {
        useMultipart: true,
      },
    );
  }

  updateTaskProgress(updateTaskProgressInput: UpdateTaskProgressInput) {
    return this.gqlService.mutate(
      updateTaskProgress,
      {
        updateTaskProgressInput,
      },
      {
        useMultipart: true,
      },
    );
  }

  getTaskLibrary(taskLibraryKey: number) {
    return this.gqlService.query(getTaskLibrary, {
      taskLibraryKey,
    });
  }

  getTaskProgress(taskProgressKey: number) {
    return this.gqlService.query(getTaskProgress, {
      taskProgressKey,
    });
  }

  getTaskAttachment(taskAttachmentKey: number) {
    return this.gqlService.query(getTaskAttachment, { taskAttachmentKey });
  }

  listTaskProgress(listTaskProgressInput: ListTaskProgressInput) {
    return this.gqlService.query(listTaskProgressByIsPubished, {
      listTaskProgressInput,
    });
  }

  listTaskStatus(): Observable<any> {
    return this.gqlService.query(listTaskStatus);
  }

  getUsersInTaskList(tenantKey: number) {
    return this.gqlService.query(getUsersInTaskList, { tenantKey });
  }

  getTenantInfo(tenantKey: number) {
    return this.gqlService.query(getTenantInfo, { tenantKey });
  }

  listAssigneeOrReviewer4Tasks(input: number[]) {
    return this.gqlService.query(listAssigneeOrReviewer4Tsks, {
      input: { tenantKeys: input },
    });
  }

  updateAgnOrRewer(input: UpdateAgnOrRewerInput) {
    return this.gqlService.mutate(updateAgnOrRewer, { input });
  }

  updateTaskStatus(updateTaskStatusInput: UpdateTaskStatusInput) {
    return this.gqlService.mutate(updateTaskStatus, {
      updateTaskStatusInput,
    });
  }

  updateSkipApproval(updateSkipApprovalInput: UpdateSkipApprovalInput) {
    return this.gqlService.mutate(updateSkipApproval, {
      updateSkipApprovalInput,
    });
  }

  updateTaskProgressList(
    updateTaskProgressListInput: UpdateTaskProgressListInput,
  ) {
    return this.gqlService.mutate(updateTaskProgressList, {
      updateTaskProgressListInput,
    });
  }

  updateTaskProgressListForAppkit4(
    updateTaskProgressListInput: UpdateTaskProgressListInput,
  ) {
    return this.gqlService.mutate(updateTaskProgressListForAppkit4, {
      updateTaskProgressListInput,
    });
  }

  confirmNewTaskAssignment(confirmNewTaskInput: ConfirmNewTaskInput[]) {
    return this.gqlService.mutate(confirmNewTaskAssignment, {
      confirmNewTaskInput,
    });
  }

  deleteTasks(taskProgressKeys: number[]) {
    return this.gqlService.mutate(deleteTasks, {
      taskProgressKeys,
    });
  }
  updateTaskVisibility(updateTaskVisibilityInput: UpdateTaskVisibilityInput[]) {
    return this.gqlService.mutate(updateTaskVisibility, {
      updateTaskVisibilityInput,
    });
  }
}

export const weeklyData = (
  isIncludeWeekend: boolean = false,
): Array<{ label: string; value: string }> => {
  return Object.keys(WEEK_DATA)
    .slice(0, isIncludeWeekend ? 7 : 5)
    .map((key) => ({ label: key, value: WEEK_DATA[key] }));
};
