import { Injectable } from '@angular/core';
import { GqlService } from '@core';
import {
  FeatureFlaggingInput,
  getFeatureFlagging,
  getTenantDisPermission,
  listFeatureTypes,
  listPermissions4CustomerAdmin,
  updateFeatureFlagging,
} from '@core/gql/feature-management.gql';

@Injectable({
  providedIn: 'root',
})
export class FeatureManagementService {
  oriPermission;
  constructor(private readonly gqlService: GqlService) {}

  getFeatureFlagging(tenantKey: number) {
    return this.gqlService.query(getFeatureFlagging, { tenantKey });
  }

  updateFeatureFlagging(input: FeatureFlaggingInput) {
    return this.gqlService.mutate(updateFeatureFlagging, { input });
  }

  listPermissions4CustomerAdmin() {
    return this.gqlService.query(listPermissions4CustomerAdmin);
  }

  listFeatureTypes() {
    return this.gqlService.query(listFeatureTypes);
  }

  getTenantDisPermission(tenantKey: number) {
    return this.gqlService.query(getTenantDisPermission, { tenantKey });
  }
}
