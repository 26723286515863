import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ContentChildren,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  SimpleChange,
  TemplateRef,
} from '@angular/core';
import { DropdownService } from '@appkit4/angular-components/dropdown';
import {
  DropdownListItemComponent,
  DropdownListItemModule,
} from '@appkit4/angular-components/dropdown-list-item';
import { generateRandomValue } from '@core/services/util.service';
import { NgClickOutsideDirective } from 'ng-click-outside2';
import { Observable, Subscription, fromEvent } from 'rxjs';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[ngTemplate]',
  standalone: true,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {},
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class CustomizeTemplate {
  @Input() type: string;

  @Input('ngTemplate') name: string;

  constructor(public template: TemplateRef<any>) {}
}
@Component({
  selector: 'app-cus-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  standalone: true,
  imports: [CommonModule, DropdownListItemModule, NgClickOutsideDirective],
  providers: [DropdownService],
})
export class AvatarComponent implements OnInit, AfterViewInit {
  @Input() name: string = '';

  @Input() diameter: string = '';

  @Input() backgroundColor: string = '';

  @Input() fontColor: string = '';

  @Input() borderColor: string = '';

  @Input() borderWidth = '4';

  @Input() marginLeft: string = '0';

  @Input() zIndex: string = '0';

  @Input() imageSrc: string = '';

  @Input() iconClass = '';

  @Input() style: any = '';

  @Input() styleClass: string = '';

  @Input() role: string = 'button';

  @Input() disabled: boolean = false;

  @Input() withMask: boolean = false;

  @Input() tabindex: number = 0;

  @Input() ariaLabel: string = '';

  @Input() withDropdown: boolean = false;

  @Input() list: any;

  @Input() compact: boolean = false;

  @Input() alignDirection: string = 'right';

  @ContentChildren('dropdownListItem') dropdownListItem:
    | QueryList<DropdownListItemComponent>
    | any;

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onClick: EventEmitter<Event> = new EventEmitter();
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onFocus: EventEmitter<Event> = new EventEmitter();
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onBlur: EventEmitter<Event> = new EventEmitter();

  public templateMap: any;
  @ContentChildren(CustomizeTemplate) templates: QueryList<CustomizeTemplate>;

  avatarTabindex: number = 0;

  avatarStyle = {};

  imageStyle = {};

  // maskStyle: string = '';

  isFocused: boolean = false;
  showDropdown: boolean = false;
  normalTempItem: any = {};
  avatarId: string = '';
  marginTopValue: string = '';

  keydownObservable$!: Observable<Event>;
  keydownSubscription$!: Subscription;
  clickObservable$!: Observable<Event>;
  clickSubscription$!: Subscription;

  constructor(
    private el: ElementRef,
    public render: Renderer2,
    public dropdownService: DropdownService,
  ) {}

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnChanges(changes: SimpleChange | any) {
    if (changes.disabled) {
      if (changes.disabled.currentValue) this.avatarTabindex = -1;
      else if (!changes.disabled.currentValue)
        this.avatarTabindex = this.tabindex;
    }

    if (changes.tabindex) {
      this.avatarTabindex = this.disabled ? -1 : changes.tabindex.currentValue;
    }

    if (
      changes.imageSrc ||
      changes.backgroundColor ||
      changes.color ||
      changes.borderColor ||
      changes.borderWidth ||
      changes.diameter ||
      changes.fontColor ||
      changes.marginLeft ||
      changes.zIndex ||
      changes.withDropdown
    ) {
      this.avatarStyle = {
        width: `${this.diameter}px`,
        height: `${this.diameter}px`,
        backgroundColor: this.backgroundColor,
        color: this.fontColor,
        borderColor: `${this.borderColor}`,
        borderWidth: `${this.borderWidth}px`,
        marginLeft: `${this.marginLeft}px`,
        zIndex: `${this.zIndex}`,
        position: 'relative',
      };

      this.imageStyle = {
        width: `${this.diameter}px`,
        height: `${this.diameter}px`,
      };

      this.marginTopValue = `${parseInt(this.diameter) + 8}px`;

      let avatarContainerBox = document.getElementById(this.avatarId);
      if (avatarContainerBox)
        avatarContainerBox.style.setProperty(
          '--marginTopValue',
          this.marginTopValue,
        );

      // this.maskStyle = `width: ${parseInt(this.diameter) + 8}px; height: ${parseInt(this.diameter) + 8}px; backgroundColor: ${this.backgroundColor}`;
      if (this.imageSrc) {
        Object.assign(this.avatarStyle, { overflow: 'hidden' });
        Object.assign(this.avatarStyle, { backgroundColor: '#ffffff' });
      } else {
        Object.assign(this.avatarStyle, { backgroundColor: '' });
      }
      if (this.iconClass) {
        Object.assign(this.avatarStyle, {
          backgroundColor: this.backgroundColor,
        });
      }
    }

    setTimeout(() => {
      this.dropdownListItem.toArray().forEach((o: any) => {
        o.onSelectItem.subscribe((event: any) => {
          this.list.forEach((item) => {
            item.checked = item.value === event.selected.value;
          });
          this.onSelectedChange(event);
        });
      });
    }, 300);
  }

  ngOnInit() {
    if (!this.avatarId) {
      this.avatarId = 'avatar' + generateRandomValue();
    }
    if (this.compact) {
      if (!this.diameter) {
        this.diameter = '32';
      }
    } else {
      if (!this.diameter) {
        this.diameter = '40';
      }
    }
    this.avatarStyle = {
      width: `${this.diameter}px`,
      height: `${this.diameter}px`,
      backgroundColor: this.backgroundColor,
      color: this.fontColor,
      borderColor: `${this.borderColor}`,
      borderWidth: `${this.borderWidth}px`,
      marginLeft: `${this.marginLeft}px`,
      zIndex: `${this.zIndex}`,
      position: 'relative',
    };
    this.imageStyle = {
      width: `${this.diameter}px`,
      height: `${this.diameter}px`,
    };

    this.marginTopValue = `${parseInt(this.diameter) + 8}px`;

    // this.maskStyle = `width: ${parseInt(this.diameter) + 8}px; height: ${parseInt(this.diameter) + 8}px; backgroundColor: ${this.backgroundColor}`;
    if (this.imageSrc) {
      Object.assign(this.avatarStyle, { overflow: 'hidden' });
      Object.assign(this.avatarStyle, { backgroundColor: '#ffffff' });
    }
    if (this.iconClass) {
      Object.assign(this.avatarStyle, {
        backgroundColor: this.backgroundColor,
      });
    }

    if (this.disabled) {
      this.avatarTabindex = -1;
    } else {
      this.avatarTabindex = this.tabindex;
    }

    if (!this.ariaLabel) {
      this.ariaLabel = this.name || '';
    }
    this.keydownObservable$ = fromEvent(window, 'keydown');
    this.keydownSubscription$ = this.keydownObservable$.subscribe(
      (event: any) => {
        const keycode = window.event ? event.keyCode : event.which;
        if (keycode === 9) {
          this.render.addClass(
            this.el.nativeElement.querySelector('.ap-avatar'),
            'keyboard-focus',
          );
        }
      },
    );
    this.clickObservable$ = fromEvent(window, 'click');
    this.clickSubscription$ = this.clickObservable$.subscribe((event: any) => {
      this.render.removeClass(
        this.el.nativeElement.querySelector('.ap-avatar'),
        'keyboard-focus',
      );
    });
  }

  ngAfterViewInit(): void {
    if (this.style) {
      if (typeof this.style === 'string') {
        this.el.nativeElement.querySelector('.ap-avatar').style.cssText +=
          this.style;
      } else {
        Object.assign(this.avatarStyle, this.style);
      }
    }
    if (this.withDropdown) {
      let avatarContainerBox = document.getElementById(this.avatarId);
      avatarContainerBox.style.setProperty(
        '--marginTopValue',
        this.marginTopValue,
      );
    }
  }

  onFocusEvent(event: Event) {
    if (this.disabled) {
      return;
    }
    this.isFocused = true;
    this.onFocus.emit(event);
  }

  onBlurEvent(event: Event) {
    if (this.disabled) {
      return;
    }
    this.isFocused = false;
    this.onBlur.emit(event);
  }

  clickAvatar(event: MouseEvent) {
    if (this.disabled) return;
    this.showDropdown = !this.showDropdown;
    // if (this.role === 'button' || this.role === 'link') {
    this.onClick.emit(event);
    // }
    this.dropdownService.normalDataRemoveKeyboard(this.list);
  }

  onKeyDown(event: KeyboardEvent) {
    if (this.disabled) return;
    let keyCode = event.keyCode;
    if (this.withDropdown) {
      switch (keyCode) {
        case 27: //esc
          // this.showDropdown = !this.showDropdown;
          if (this.showDropdown) {
            this.showDropdown = false;
          }
          event.preventDefault();
          break;
        case 40: //down;
          this.downNext(this.normalTempItem);
          event.preventDefault();
          event.stopPropagation();
          break;
        case 38: // up
          this.upNext(this.normalTempItem);
          event.preventDefault();
          event.stopPropagation();
          break;
        case 13: //enter
        case 32: //space
          if (!this.showDropdown) {
            this.showDropdown = true;
            if (Object.keys(this.normalTempItem).length === 0) {
              this.setSingleFocusedItem(0);
            }
            this.dropdownService.normalDataUpdateStatus(
              this.list,
              'label',
              this.normalTempItem,
            );
          } else {
            let selectTemp = this.dropdownListItem
              .toArray()
              .find((o: any) => o.item.focused === true);
            if (selectTemp) {
              selectTemp.onSelectItem.next({
                originEvent: event,
                selected: this.normalTempItem,
                eventType: 'keydown',
              });
            }
          }
          event.preventDefault();
          break;
        case 9: //tab
          if (this.showDropdown) {
            this.showDropdown = false;
          }
          break;
      }
    }

    if (
      (keyCode === 13 && (this.role === 'button' || this.role === 'link')) ||
      (keyCode === 32 && this.role === 'button')
    ) {
      event.preventDefault();
      this.onClick.emit();
    }
  }

  onClickOutside(event: any) {
    this.showDropdown = false;
  }

  onSelectedChange(event) {
    this.showDropdown = false;
    if (event.eventType && event.eventType === 'click') {
      this.dropdownService.normalDataRemoveKeyboard(this.list);
      this.normalTempItem = event.selected;
    }
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngAfterContentInit() {
    if (this.templates.length) {
      this.templateMap = {};
    }
    this.templates.forEach((item) => {
      this.templateMap[item.name] = item.template;
    });
  }

  downNext(nextItem?: any): void {
    this.normalTempItem = nextItem;
    let selectedItemIndex = this.dropdownService.findOptionIndex(
      this.normalTempItem,
      this.list,
      'label',
    );

    if (selectedItemIndex !== -1) {
      let nextItemIndex = selectedItemIndex + 1;
      if (nextItemIndex < this.list.length) {
        this.normalTempItem = this.list[nextItemIndex];
      } else {
        this.normalTempItem = this.list[0];
      }
    } else {
      this.normalTempItem = this.list[0];
    }
    if (this.normalTempItem.disabled) {
      this.downNext(this.normalTempItem);
    } else {
      this.dropdownService.normalDataUpdateStatus(
        this.list,
        'label',
        this.normalTempItem,
      );
    }
  }

  upNext(nextItem?: any): void {
    this.normalTempItem = nextItem;
    let selectedItemIndex = this.dropdownService.findOptionIndex(
      this.normalTempItem,
      this.list,
      'label',
    );

    if (selectedItemIndex !== -1) {
      let preItemIndex = selectedItemIndex - 1;
      if (preItemIndex >= 0) {
        this.normalTempItem = this.list[preItemIndex];
      } else {
        let length = this.list.length - 1;
        this.normalTempItem = this.list[length];
      }
    } else {
      let length = this.list.length - 1;
      this.normalTempItem = this.list[length];
    }
    if (this.normalTempItem.disabled) {
      this.upNext(this.normalTempItem);
    } else {
      this.dropdownService.normalDataUpdateStatus(
        this.list,
        'label',
        this.normalTempItem,
      );
    }
  }

  setSingleFocusedItem(index) {
    let disabledStatus = this.list[index].disabled;
    if (!disabledStatus) {
      this.normalTempItem = this.list[index];
    } else {
      index = index + 1;
      this.setSingleFocusedItem(index);
    }
  }
}
