import { Pipe, PipeTransform } from '@angular/core';
import * as changeCase from 'change-case';

export type ChangeCaseType = 'no' | 'noCase' | 'dot' | 'dotCase' | 'swap' | 'swapCase' | 'path' | 'pathCase'
  | 'upper' | 'upperCase' | 'lower' | 'lowerCase' | 'camel' | 'camelCase' | 'snake' | 'snakeCase'
  | 'title' | 'titleCase' | 'param' | 'paramCase' | 'kebab' | 'kebabCase' | 'hyphen' | 'hyphenCase'
  | 'header' | 'headerCase' | 'pascal' | 'pascalCase' | 'constant' | 'constantCase' | 'sentence' | 'sentenceCase'
  | 'ucFirst' | 'upperCaseFirst' | 'lcFirst' | 'lowerCaseFirst';

@Pipe({
    name: 'changeCase',
    standalone: true
})
export class ChangeCasePipe implements PipeTransform {

  transform(value: any, changeCaseType: ChangeCaseType): any {
    if (value === null || value === undefined) {
      return null;
    }

    return changeCase[changeCaseType](value);
  }

}
