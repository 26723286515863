import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../../environments/environment';
@Pipe({
  name: 'minusToParentheses',
  standalone: true,
})
export class MinusToParenthesesPipe implements PipeTransform {
  transform(value: any): string | null {
    if (value === null || value === undefined) {
      return null;
    }

    const strValue = value.toString();
    const useMinus = environment.useMinus; // default to use ()

    if (useMinus) {
      return strValue;
    } else {
      return strValue.charAt(0) === '-'
        ? `(${strValue.substring(1)})`
        : strValue;
    }
  }
}
