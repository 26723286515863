import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ApolloModule } from 'apollo-angular';
import {
  ChangeCasePipe,
  CurrencyCalcPipe,
  LastTimePipe,
  MinusToParenthesesPipe,
  NotAvailablePipe,
  PercentCalcPipe,
  ShortNumberSuffixPipe,
} from '../shared/pipe';
import { FormatAmountPipe } from '../shared/pipe/format-amount.pipe';

@NgModule({
  imports: [
    ApolloModule,
    CommonModule,
    ChangeCasePipe,
    CurrencyCalcPipe,
    LastTimePipe,
    MinusToParenthesesPipe,
    NotAvailablePipe,
    PercentCalcPipe,
    ShortNumberSuffixPipe,
    FormatAmountPipe,
  ],
  providers: [
    ChangeCasePipe,
    CurrencyCalcPipe,
    LastTimePipe,
    MinusToParenthesesPipe,
    NotAvailablePipe,
    PercentCalcPipe,
    ShortNumberSuffixPipe,
  ],
  exports: [
    ChangeCasePipe,
    CurrencyCalcPipe,
    LastTimePipe,
    MinusToParenthesesPipe,
    NotAvailablePipe,
    PercentCalcPipe,
    ShortNumberSuffixPipe,
    FormatAmountPipe,
  ],
})
export class CoreModule {}
