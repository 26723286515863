<div class="ap-grid">
  <div class="cus-header-container g-col-12 ap-mb-spacing-6">
    <app-breadcrumb-cus [crumbList]="crumbList"></app-breadcrumb-cus>
    <div class="page-title-container flex-normal flex-gap-4">
      <ap-button [ariaLabel]="'back'" [btnType]="'tertiary'" [icon]="'arrow-left-outline'"
        (onClick)="goBack()"></ap-button>
      <h1 class="ap-typography-heading-l page-title-text app-text-heading text-wrap-ellipsis spacing-right">
        {{backText | decodeString}}
      </h1>
    </div>
  </div>
</div>
