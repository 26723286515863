import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'numberToDaywithLast',
    standalone: true
})
export class NumberToDaywithLastPipe implements PipeTransform {
  transform(value: number): string {
    if (!value) {
      return '';
    }
    if (value == 31) {
      return 'Last';
    }
    return `${value}${this.nth(value)}`;
  }

  nth(d: number): string {
    const j = d % 10;
    if (j == 1 && d != 11) {
      return 'st';
    }
    if (j == 2 && d != 12) {
      return 'nd';
    }
    if (j == 3 && d != 13) {
      return 'rd';
    }
    return 'th';
  }
}
