import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnChanges,
  SimpleChanges,
  OnDestroy,
  AfterViewInit,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SingleDropdownModule, SingleDropdownComponent } from '@appkit4/angular-components/single-dropdown';
import { PaginationModule } from '@appkit4/angular-components/pagination';
import { NgIf } from '@angular/common';
import { Subject, Subscription } from 'rxjs';
import { skip } from 'rxjs/operators';

export const DEFAULT_LIMIT = 25;

@Component({
  selector: 'app-table-pagination',
  templateUrl: './table-pagination.component.html',
  styleUrls: ['./table-pagination.component.scss'],
  standalone: true,
  imports: [FormsModule, NgIf, PaginationModule, SingleDropdownModule],
})
export class TablePaginationComponent implements OnChanges, OnDestroy, AfterViewInit{
  @Input() count = 0;
  @Input() offset = 0;
  @Input() limitValue = DEFAULT_LIMIT;
  @Output() pageChange: EventEmitter<number> = new EventEmitter();
  @Output() limitChange: EventEmitter<number> = new EventEmitter();

  totalPage = 1;
  page = 1;
  limitList = [
    { label: DEFAULT_LIMIT, value: DEFAULT_LIMIT, checked: false },
    { label: 50, value: 50, checked: false},
    { label: 100, value: 100, checked: false },
  ];
  limit = { label: DEFAULT_LIMIT, value: DEFAULT_LIMIT };

  onPageChange$ = new Subject();
  pageChangeSub: Subscription;

  subscribePageChange() {
    // the skip(1) below is to avoid the init page value from ap-pagination
    this.pageChangeSub = this.onPageChange$
      .pipe(skip(1))
      .subscribe((page: number) => {
        this.pageChange.emit(page);
      });
  }

  unsubscribePageChange() {
    if (this.pageChangeSub) {
      this.pageChangeSub.unsubscribe();
    }
  }

  calculateTotalPage() {
    this.totalPage = Math.max(Math.ceil(this.count / this.limit.value), 1);
  }

  calculatePage(offset = this.offset) {
    this.page = Math.floor(offset / this.limit.value + 1);
  }

  onPageChange(page: number) {
    this.onPageChange$.next(page);
  }

  selectLimit() {
    this.calculateTotalPage();
    this.calculatePage(0);
    this.limitChange.emit(this.limit.value);
  }

  ngAfterViewInit() {
    SingleDropdownComponent.prototype.itemDataScroll = () => { };
  }

  ngOnChanges(changes: SimpleChanges): void {
    // to make use of the skip(1) (inside subscribePageChange) as needed,
    // we have to unsubscribe when the input count is once set to 0
    if (changes.count?.currentValue === 0) {
      this.unsubscribePageChange();
    } else if (changes.count?.previousValue === 0) {
      this.subscribePageChange();
    }

    // do some calculations when the input values change
    if (changes.count?.currentValue) {
      this.calculateTotalPage();
    }
    if (!isNaN(changes.offset?.currentValue)) {
      this.calculatePage();
    }
    if (changes.limitValue?.currentValue) {
      this.limit = this.limitList.find(
        (limit) => limit.value === this.limitValue,
      );
      this.calculateTotalPage();
      this.calculatePage();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribePageChange();
  }
}
