import { Injectable } from '@angular/core';
import { environment } from '@env';
import { OrgStatus, Tenant } from '@models';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { plainToClass } from 'class-transformer';
import { Subscription } from 'rxjs';
import { skip } from 'rxjs/operators';
import {
  dataSyncUpdate,
  getAssignedTenants,
  GetAssignedTenantsVariable,
  getTenant,
} from '../../gql';
import { GqlService } from '../../services';
import {
  RetrieveAllAssignedTenants,
  RetrieveAssignedTenants,
  RetrieveTenant,
} from './bookkeeper.action';

export interface BookkeeperStateModel {
  tenants: Tenant[];
  tenant: Tenant;
  cache: number;
  loading: boolean;
}

@State<BookkeeperStateModel>({
  name: 'bookkeeper',
  defaults: {
    tenants: [],
    tenant: null,
    cache: 0,
    loading: false,
  },
})
@Injectable()
export class BookkeeperState {
  private subscription: Subscription;

  constructor(
    private readonly gqlService: GqlService,
    private readonly store: Store,
  ) {}

  @Selector()
  static getBookkeeperTenants(state: BookkeeperStateModel): Tenant[] {
    return plainToClass(Tenant, state.tenants);
  }

  @Selector()
  static getAssignedTenants(state: BookkeeperStateModel): Tenant[] {
    return plainToClass(Tenant, state.tenants);
  }

  @Selector()
  static getActiveBookkeeperTenants(state: BookkeeperStateModel): Tenant[] {
    return plainToClass(Tenant, state.tenants).filter(
      (t) => t.orgStatus === OrgStatus.ACTIVE,
    );
  }

  @Selector()
  static getInactiveBookkeeperTenants(state: BookkeeperStateModel): Tenant[] {
    return plainToClass(Tenant, state.tenants).filter(
      (t) => t.orgStatus === OrgStatus.INACTIVE,
    );
  }

  @Selector()
  static getTenant(state: BookkeeperStateModel): Tenant {
    return plainToClass(Tenant, state.tenant);
  }

  @Selector()
  static getLoadingState(state: BookkeeperStateModel): boolean {
    return state.loading;
  }

  @Action(RetrieveAllAssignedTenants)
  async getAllAssignedTenants({
    patchState,
  }: StateContext<BookkeeperStateModel>) {
    await patchState({ loading: true });
    const response = await this.gqlService
      .query(getAssignedTenants, { skipBkUsers: false })
      .toPromise();
    await patchState({ tenants: response, loading: false });
  }

  @Action(RetrieveAssignedTenants)
  async getAssignedTenants({ patchState }: StateContext<BookkeeperStateModel>) {
    const queryRef = this.gqlService.subscription(
      getAssignedTenants,
      dataSyncUpdate,
    );
    const sub = !!this.subscription;
    if (sub) {
      this.subscription.unsubscribe();
      this.subscription = null;
    } else {
      patchState({ loading: true, tenants: [] });
    }
    this.subscription = queryRef.valueChanges
      .pipe(skip(sub ? 1 : 0))
      .subscribe((data: any) => {
        patchState({ tenants: data.data.getAssignedTenants, loading: false });
      });
    queryRef.refetch();
  }

  @Action(RetrieveTenant)
  async getTenant(
    { getState, patchState }: StateContext<BookkeeperStateModel>,
    { payload: tenantKey, force, orgGroupId }: RetrieveTenant,
  ) {
    const now = new Date().getTime();

    const { tenant: oldTenant, cache } = getState();
    if (
      !force &&
      oldTenant &&
      oldTenant.tenantKey ===
        (tenantKey ||
          this.store.selectSnapshot(
            (store) => store.user.user.tenant.tenantKey,
          )) &&
      cache &&
      now - cache < environment.cacheTime
    ) {
      // if old tenant exist, old tenant sames as request or user tenant and cache not expired skip patch
      return;
    }

    // clear store with cached field
    this.store.reset({
      ...this.store.snapshot(),
      bookkeeper: { ...getState(), tenant: null, cache: now },
      accounts: { accounts: [] },
    });

    let tenant;
    if (tenantKey) {
      tenant = await this.gqlService
        .query(getTenant, { tenantKey })
        .toPromise();
    } else if (orgGroupId) {
      tenant = await this.gqlService
        .query(getTenant, { orgGroupId })
        .toPromise();
    } else {
      tenant = await this.gqlService.query(getTenant, {}).toPromise();
    }
    patchState({ tenant });
  }
}
