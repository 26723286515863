import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'lastTime',
    standalone: true
})
export class LastTimePipe implements PipeTransform {

  transform(value: any): string | null {
    if (value === null || value === undefined) {
      return null;
    }

    const lastTime = new Date(value).getTime();
    if (lastTime !== lastTime) {
      return null;
    }
    const interval = (new Date().getTime() - lastTime) / 1000;
    if (interval < 120) {
      return `1 minute ago`;
    } else if (interval >= 120 && interval < 3600) {
      const num = Math.floor(interval / 60);
      return `${num} minutes ago`;
    } else if (interval >= 3600 && interval < 7200) {
      return '1 hour ago';
    } else if (interval >= 7200 && interval < 86400) {
      const num = Math.floor(interval / 3600);
      return `${num} hours ago`;
    } else if (interval >= 86400 && interval < 172800) {
      return `1 day ago`;
    } else if (interval >= 172800 && interval < 691200) {
      const num = Math.floor(interval / 86400);
      return `${num} days ago`;
    } else {
      return moment(value).format('MM/DD/YYYY');
    }
  }

}
