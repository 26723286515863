<div class="general-modal" role="dialog" aria-modal="true" [attr.aria-label]="title">
  <div class="ap-modal-header">
    <h2 class="ap-modal-title">{{ title }}</h2>
  </div>
  <div class="ap-modal-body content app-text-body" [innerHTML]="content"></div>
  <div class="ap-modal-footer">
    <div class="ap-modal-footer-customize">
      <div tabindex="0"></div>
      <ap-button [btnType]="'secondary'" [label]="'Cancel'" (onClick)="cancel()"></ap-button>
      <ap-button [hidden]="!extraLabel" [btnType]="'secondary'" [label]="extraLabel" (onClick)="extra()"></ap-button>
      <ap-button [btnType]="primaryBtnType" (onClick)="continue()" (keyup.enter)="continue()"
        [label]="labelStr"></ap-button>
    </div>
  </div>
  <div class="ap-modal-header-icons-container">
    <button type="button" aria-label="Close" (click)="cancel()" class="ap-modal-header-icon ap-modal-header-close">
      <span class="Appkit4-icon icon-close-outline"></span>
    </button>
  </div>
</div>