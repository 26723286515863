import gql from 'graphql-tag';

export const listNotifications = gql`
  query listNotifications($listNotifInput: ListNotifInput) {
    listNotifications(input: $listNotifInput) {
      status
      message
      unreadCount
      remainCount
      data {
        id
        isRead
        type
        tenantKey
        tenant {
          tenantKey
          nme
        }
        content {
          fileComment {
            content
            report {
              reportId
            }
            user {
              firstNme
              lastNme
            }
            attachedFileName
            task {
              name
            }
          }
          fileNote {
            content
            report {
              reportId
            }
            user {
              firstNme
              lastNme
            }
            attachedFileName
            task {
              name
            }
          }
          quickLink {
            name
            createdAt
          }
          taskLib {
            taskProgressKey
            taskNotifType
            taskNm
            userFullNm
            targetUserFullNm
          }
          taskComment {
            commentKey
            content
            attachedFileName
            user {
              firstNme
              lastNme
            }
            createdAt
            updatedAt
            task {
              name
              taskProgressKey
            }
          }
          taskNote {
            commentKey
            content
            attachedFileName
            user {
              firstNme
              lastNme
            }
            createdAt
            updatedAt
            task {
              name
              taskProgressKey
            }
          }
        }
        addition {
          quickLinkOper {
            firstNme
            lastNme
          }
        }
        createdAt
      }
    }
  }
`;

export const markNotification = gql`
  mutation markNotification($markNotificationInput: MarkNotifInput) {
    markNotification(input: $markNotificationInput) {
      status
      message
    }
  }
`;

export interface ListNotifInput {
  limit?: number;
  tenantKey: number;
  type?: string;
  truncTime?: Date;
}

export interface MarkNotificationInput {
  userXNotificationKey: number;
  tenantKey: number;
  markAll?: boolean;
  isRead: boolean;
}

export enum NOTIFICATION_TYPE {
  ALL = 'ALL',
  FILE_COMMENTS = 'FILE_COMMENTS',
  FILE_NOTES = 'FILE_NOTES',
  QUICK_LINK = 'QUICK_LINK',
  CUSTOMER_CHANGED = 'CUSTOMER_CHANGED',
  TASKS = 'TASKS',
  TASK_COMMENTS = 'TASK_COMMENTS',
  TASK_NOTES = 'TASK_NOTES',
}

export enum NOTIFICATION_TASK_TYPE {
  TASKLIST_PUBLISHED = 'TASKLIST_PUBLISHED',
  ASSIGN_TASK_ASSIGNEE = 'ASSIGN_TASK_ASSIGNEE',
  ASSIGN_TASK_REVIEWER = 'ASSIGN_TASK_REVIEWER',
  MARK_TASK_CLOSED = 'MARK_TASK_CLOSED',
  MARK_TASK_COMPLETED = 'MARK_TASK_COMPLETED',
  MARK_TASK_ON_HOLD = 'MARK_TASK_ON_HOLD',
  MARK_TASK_IN_PROGRESS = 'MARK_TASK_IN_PROGRESS',
  MARK_TASK_WAITING_ON_CLIENT = 'MARK_TASK_WAITING_ON_CLIENT',
  MARK_TASK_READY_FOR_REVIEW = 'MARK_TASK_READY_FOR_REVIEW',
  MARK_TASK_NOT_STARTED = 'MARK_TASK_NOT_STARTED',
  TASK_PAST_DUE = 'TASK_PAST_DUE',
  TASKLIST_PAST_DUE = 'TASKLIST_PAST_DUE',
  TASK_DUE_SOON = 'TASK_DUE_SOON',
  TASK_SKIP_APPROVAL = 'TASK_SKIP_APPROVAL',
  TASKLIST_DUE_SOON = 'TASKLIST_DUE_SOON',
  RECURRING_TASK_ASSIGNEE = 'RECURRING_TASK_ASSIGNEE',
  RECURRING_TASK_REVIEWER = 'RECURRING_TASK_REVIEWER',
}
