import { Tenant } from '@models';
import gql from 'graphql-tag';
import { QuickLink } from './quick-links.gql';

export const createGroup = gql`
  mutation createGroup($input: GroupInput) {
    createGroup(input: $input) {
      status
      message
    }
  }
`;

export const updateGroup = gql`
  mutation updateGroup($input: GroupInput) {
    updateGroup(input: $input) {
      status
      message
    }
  }
`;

export const deleteGroup = gql`
  mutation deleteGroup($groupKey: Int!) {
    deleteGroup(groupKey: $groupKey) {
      status
      message
    }
  }
`;

export const getGroups = gql`
  query getGroups($input: GroupQueryInput) {
    getGroups(input: $input) {
      status
      message
      count
      data {
        groupKey
        name
        description
        status
        customerCount
        quickLinkCount
      }
    }
  }
`;

export const getGroupName = gql`
  query getGroup($input: GetGroupInput) {
    getGroup(input: $input) {
      status
      message
      data {
        name
        description
      }
    }
  }
`;

export const getCustomers4Group = gql`
  query getCustomers4Group($input: GetCusto4GrpInput) {
    getCustomers4Group(input: $input) {
      count
      message
      status
      customers {
        tenantKey
        nme
      }
    }
  }
`;

export const getQuickLinks4Group = gql`
  query getQuickLinks4Group($input: GetQLs4GrpInput) {
    getQuickLinks4Group(input: $input) {
      count
      message
      status
      quickLinks {
        quickLinkKey
        name
        description
        url
        updatedAt
        createdAt
        textToDisplay
      }
    }
  }
`;

export const getGroup = gql`
  query getGroup($input: GetGroupInput, $customersOnly: Boolean = false) {
    getGroup(input: $input) {
      data {
        groupKey
        name
        description
        status
        customers {
          tenantKey
          nme
        }
        quickLinks @skip(if: $customersOnly) {
          quickLinkKey
          name
          description
          url
          createdAt
          updatedAt
          textToDisplay
        }
      }
    }
  }
`;

export const getCustomerGroups = gql`
  query getCustomerGroups($tenantKey: Int!) {
    groupStats4Customer(tenantKey: $tenantKey) {
      data {
        group {
          groupKey
          name
        }
        checked
      }
    }
  }
`;

export const updateCustomerGroups = gql`
  mutation updateCustomerGroups($input: CustomerGroupsInput) {
    updateCustomerGroups(input: $input) {
      status
    }
  }
`;

export const entityCheck = gql`
  query entityCheck($input: EntityCheckInput) {
    entityCheck(input: $input) {
      updatable
    }
  }
`;

export const listTenants = gql`
  query listTenants {
    listTenants {
      status
      message
      data {
        industry {
          industryKey
          name
        }
        tenants {
          tenantKey
          nme
          lineage
          entityType
          assigned
        }
      }
    }
  }
`;

export const listTenantsForBK = gql`
  query listTenants {
    listTenants {
      status
      message
      data {
        industry {
          industryKey
          name
        }
        tenants {
          tenantKey
          nme
          teamLead {
            userKey
          }
          reviewer {
            userKey
          }
        }
      }
    }
  }
`;

export interface CustomerGroupsInput {
  tenantKey: number;
  groupActions: [GroupAction];
}

export enum GroupMemberAction {
  ADD = 'ADD',
  DELETE = 'DELETE',
}

export interface GroupAction {
  groupKey: number;
  action: GroupMemberAction;
}

export interface GroupQueryInput {
  groupKey?: number;
  limit?: number;
  offset?: number;
  orderBy?: string;
  sortingType?: string;
  search?: string;
}

export interface GetCusNameQLs4GrpInput {
  groupKey: number;
  limit?: number;
  offset?: number;
  orderBy?: string;
  sortingType?: string;
  searchText?: string;
}

export interface GetGroupInput {
  groupKey: number;
  customerSortingType?: string;
}

export interface EntityCheckInput {
  entityType?: string;
  data: UniqueEntity;
}

export interface UniqueEntity {
  name: string;
  groupKey?: number;
}

export interface Group {
  groupKey?: number;
  name?: string;
  description?: string;
  status?: string;
  customers?: Tenant[];
  quickLinks?: QuickLink[];
}
