import { Injectable } from '@angular/core';
import { GqlService, listTenants, listUsers } from '@core';
import { getIndustries } from '@core/gql/chart-of-accounts.gql';
import {
  CustomerOnboardInput,
  getOnboardCustomerForm,
  getTenantAttachment,
  listBusinessFunctions,
  onboardCustomer,
  onboardFormCheck,
  OnboardFormCheckInput,
  updateCustomerOnboardForm,
  UpdateCustomerOnboardFormInput,
  getGeneralLedgers,
} from '@core/gql/customer-form.gql';

@Injectable({
  providedIn: 'root',
})
export class CustomerFormService {
  constructor(private readonly gqlService: GqlService) {}

  listTenants() {
    return this.gqlService.query(listTenants);
  }

  getIndustries() {
    return this.gqlService.query(getIndustries);
  }

  listUsers(assignBK = true) {
    return this.gqlService.query(listUsers, {
      listUsersInput: {
        bkOpt: { includeSA: true, includeSelf: true, registered: true },
      },
    });
  }

  listBusinessFunctions() {
    return this.gqlService.query(listBusinessFunctions);
  }

  onboardCustomer(customerOnboardInput: CustomerOnboardInput) {
    return this.gqlService.mutate(
      onboardCustomer,
      { customerOnboardInput },
      {
        useMultipart: true,
      },
    );
  }

  getOnboardCustomerForm(tenantKey: number) {
    return this.gqlService.query(getOnboardCustomerForm, { tenantKey });
  }

  getTenantAttachment(tenantAttachmentKey: number) {
    return this.gqlService.query(getTenantAttachment, { tenantAttachmentKey });
  }

  updateCustomerOnboardForm(
    updateCustomerOnboardFormInput: UpdateCustomerOnboardFormInput,
  ) {
    return this.gqlService.mutate(
      updateCustomerOnboardForm,
      {
        updateCustomerOnboardFormInput,
      },
      {
        useMultipart: true,
      },
    );
  }

  onboardFormCheck(onboardFormCheckInput: OnboardFormCheckInput) {
    return this.gqlService.mutate(
      onboardFormCheck,
      { onboardFormCheckInput },
      {
        useMultipart: true,
      },
    );
  }

  getGeneralLedgers() {
    return this.gqlService.query(getGeneralLedgers);
  }

  concatOtherOption(generalLedgers = []) {
    return (generalLedgers || []).concat([
      { displayNme: 'Other', nme: 'other' },
    ]);
  }

  readonly TIME_ZONES = [
    '(UTC-10:00)Hawaii',
    '(UTC-09:00)Alaska',
    '(UTC-08:00)Pacific',
    '(UTC-07:00)Mountain',
    '(UTC-06:00)Central',
    '(UTC-05:00)Eastern',
  ];

  readonly US_STATES = [
    'Alabama',
    'Alaska',
    'Arizona',
    'Arkansas',
    'California',
    'Colorado',
    'Connecticut',
    'Delaware',
    'District of Columbia',
    'Florida',
    'Georgia',
    'Hawaii',
    'Idaho',
    'Illinois',
    'Indiana',
    'Iowa',
    'Kansas',
    'Kentucky',
    'Louisiana',
    'Maine',
    'Maryland',
    'Massachusetts',
    'Michigan',
    'Minnesota',
    'Mississippi',
    'Missouri',
    'Montana',
    'Nebraska',
    'Nevada',
    'New Hampshire',
    'New Jersey',
    'New Mexico',
    'New York',
    'North Carolina',
    'North Dakota',
    'Ohio',
    'Oklahoma',
    'Oregon',
    'Pennsylvania',
    'Rhode Island',
    'South Carolina',
    'South Dakota',
    'Tennessee',
    'Texas',
    'Utah',
    'Vermont',
    'Virginia',
    'Washington',
    'West Virginia',
    'Wisconsin',
    'Wyoming',
  ];
}

export const PAY_BY_OPTIONS = ['Customer', 'PwC', 'Other'];

export const BILLING_REPEAT = ['Monthly', 'Quarterly', 'Yearly', 'N/A'];
