import gql from 'graphql-tag';

export const getVendors = gql`
  query getVendors($tenantKey: Int!) {
    getVendors(tenantKey: $tenantKey) {
      status
      message
      data {
        vendorKey
        name
        displayName
        type
        parentRec {
          vendorKey
          name
          displayName
          type
        }
      }
    }
  }
`;

