<div class="ap-avatar-container" (clickOutside)="onClickOutside($event)" [attr.id]="avatarId">
  <div *ngIf="withDropdown " class="ap-avatar {{styleClass}}" (click)="clickAvatar($event)"
    (keydown)="onKeyDown($event)" [class.disabled]="disabled" [class.mask]="withMask" [ngStyle]="avatarStyle"
    [attr.role]="role" [attr.aria-label]="ariaLabel" [attr.aria-expanded]="withDropdown && showDropdown"
    (focus)="onFocusEvent($event)" (blur)="onBlurEvent($event)" [tabindex]="avatarTabindex">
    <span class="ap-avatar-text" [ngClass]="compact ? 'compact': ''"
      *ngIf="!imageSrc && !iconClass && name">{{name}}</span>
    <img [ngStyle]="imageStyle" *ngIf="imageSrc&&!iconClass" [src]="imageSrc" alt="">
    <span class="Appkit4-icon" [class]="iconClass" *ngIf="iconClass&&!imageSrc"></span>
    <ng-content></ng-content>
    <!-- <div [style]="isFocused ? maskStyle : ''" [class.back-mask]="isFocused"></div> -->
  </div>
  <div *ngIf="!withDropdown" class="ap-avatar {{styleClass}}" (click)="clickAvatar($event)"
    (keydown)="onKeyDown($event)" [class.disabled]="disabled" [class.mask]="withMask" [ngStyle]="avatarStyle"
    [attr.role]="role" [attr.aria-label]="ariaLabel" (focus)="onFocusEvent($event)" (blur)="onBlurEvent($event)"
    [tabindex]="avatarTabindex">
    <span class="ap-avatar-text" [ngClass]="compact ? 'compact': ''"
      *ngIf="!imageSrc && !iconClass && name">{{name}}</span>
    <img [ngStyle]="imageStyle" *ngIf="imageSrc&&!iconClass" [src]="imageSrc" alt="">
    <span class="Appkit4-icon" [class]="iconClass" *ngIf="iconClass&&!imageSrc"></span>
    <ng-content></ng-content>
    <!-- <div [style]="isFocused ? maskStyle : ''" [class.back-mask]="isFocused"></div> -->
  </div>
  <ng-container *ngIf="withDropdown">
    <div class="avatar-list-box {{styleClass}}" [attr.role]="'listbox'" [attr.aria-hidden]="withDropdown ? false : null"
      [attr.aria-labelle]="'avatar dropdown list'" [class.show]="showDropdown"
      [class.alignLeft]="alignDirection === 'left'" [class.alignRight]="alignDirection === 'right'">
      <div *ngIf="templateMap && templateMap['dropdownTemp']">
        <ng-container *ngTemplateOutlet="templateMap['dropdownTemp']"></ng-container>
      </div>
    </div>
  </ng-container>
</div>