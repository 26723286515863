import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';

import { Observable } from 'rxjs';

export interface DeactivationGuarded {
  canDeactivate(): Observable<boolean>;
}

@Injectable({
  providedIn: 'root',
})
export class DeactivateGuard {
  constructor(private router: Router) {}
  canDeactivate(
    component: DeactivationGuarded,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot,
  ): Observable<boolean> | boolean {
    return component && component.canDeactivate
      ? component.canDeactivate()
      : true;
  }
}
