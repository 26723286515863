import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthService } from '@core';
import { LayoutConfig, User } from '@models';
import { Store } from '@ngxs/store';
import { plainToClass } from 'class-transformer';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { filter, map, mergeMap, tap } from 'rxjs/operators';
import { EmitService } from './emit.service';
import { isEmpty } from 'lodash';

export enum AccessMode {
  BOOKKEEPER,
  BK_VIEW_CUSTOMER,
  CUSTOMER,
  NON_LOGIN,
}

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  queryParams: any;
  // TODO: update to observable
  layoutConfig: LayoutConfig = {
    overview: '',
    tenantKey: null,
    templateLibraryKey: null,
    userKey: null,
    isBKViewCustomer: false,
    hasBack: false,
    fullScreen: true,
  };

  public fullScreen$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  previousUrl: string;
  currentUrl: string;
  urlAfterRedirects: string;

  public backInfo$: Subject<{ title?: string; crumbTitle?: string }> =
    new Subject();
  setBackInfo(backInfo: { title?: any; crumbTitle?: any }) {
    setTimeout(() => {
      this.backInfo$.next(backInfo);
    });
  }

  // get tenant info by tenantKey from route
  public tenantByRoute$: BehaviorSubject<any> = new BehaviorSubject(null);
  // login user
  userInfo: User;
  // get user info by userKey from route
  userByRoute$: BehaviorSubject<any> = new BehaviorSubject(null);

  _accessMode = AccessMode.BOOKKEEPER;

  constructor(
    public router: Router,
    private activatedRoute: ActivatedRoute,
    public emitService: EmitService,
    private readonly store: Store,
    private authService: AuthService,
  ) {
    this.currentUrl = router.url;
    router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        tap((event) => {
          this.previousUrl = this.currentUrl;
          this.currentUrl = (event as NavigationEnd).url;
          this.urlAfterRedirects = (event as NavigationEnd).urlAfterRedirects;
          this.queryParams = this.getParams();
          document.body.scrollTop = 0; // For Safari
          document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        }),
        map(() => activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) =>
          combineLatest([
            route.data,
            route.paramMap,
            // this.store.select((state) => state.user.user),
            this.authService.user$,
          ]),
        ),
        tap(([data, paramMap, userInfo]) => {
          this.userInfo = plainToClass(User, userInfo);
          this.layoutConfig.hasBack = data && data.hasBack;
          this.layoutConfig.fullScreen = data && !!data.fullScreen;
          /** from layout.service.deprecated.ts ---start */
          this.layoutConfig.overview = data && data.overview;
          this.layoutConfig.isBKViewCustomer = data && data.isBKViewCustomer;
          /** from layout.service.deprecated.ts ---end */

          const { tenantKey, templateLibraryKey, userKey } = (paramMap as any)
            .params;
          this.layoutConfig.tenantKey = tenantKey
            ? Number(tenantKey)
            : undefined;
          this.setAccessMode();
          this.layoutConfig.templateLibraryKey = templateLibraryKey
            ? Number(templateLibraryKey)
            : undefined;
          this.layoutConfig.userKey = userKey ? Number(userKey) : undefined;
          this.fullScreen$.next(this.layoutConfig.fullScreen);
          // When BK user views Customer, the isBKViewCustomer value is [true],
          // and in this view, bell in banner has hidden, notifications panel also closed.
          if (this.layoutConfig.isBKViewCustomer) {
            this.emitService.showNotificationsPanel$.next(false);
          }
        }),
      )
      .subscribe();
  }

  isFromSubRoute() {
    const currentUrl = window.location.pathname;
    const urlParts = currentUrl.split('/');
    let pathName;
    const len = urlParts.length;
    if (len >= 5) {
      pathName = urlParts.slice(0, 5).join('/');
    } else {
      pathName = urlParts.slice(-(len - 1)).join('/');
    }
    let previousUrl = this.previousUrl;
    previousUrl = previousUrl?.split('?')[0];
    if (previousUrl === currentUrl) {
      return false;
    } else {
      return previousUrl?.includes(pathName);
    }
  }

  setAccessMode() {
    if (this.urlAfterRedirects.startsWith('/bookkeeper/')) {
      this._accessMode = AccessMode.BOOKKEEPER;
    } else if (this.urlAfterRedirects.startsWith('/customer/')) {
      if (this.layoutConfig.tenantKey) {
        this._accessMode = AccessMode.BK_VIEW_CUSTOMER;
      } else {
        this._accessMode = AccessMode.CUSTOMER;
      }
    } else {
      this._accessMode = AccessMode.NON_LOGIN;
    }
  }

  get accessMode() {
    return this._accessMode;
  }

  getInitialTenantKey() {
    if (
      this.accessMode === AccessMode.BK_VIEW_CUSTOMER ||
      this.accessMode === AccessMode.BOOKKEEPER
    ) {
      return this.layoutConfig.tenantKey;
    }
    if (this.accessMode === AccessMode.CUSTOMER) {
      return this.queryParams?.tenantKey || this.userInfo?.tenant.tenantKey;
    }
  }

  getParams() {
    const urlString = this.currentUrl;

    // Extract the query part of the URL
    const queryString = urlString.split('?')[1];

    // Create a URLSearchParams object from the query string
    const urlParams = new URLSearchParams(queryString);

    // Initialize an empty object to hold the parameters
    const params = {};

    // Iterate over the URLSearchParams entries
    for (const [key, val] of urlParams.entries()) {
      //remove the fragment part of the URL
      const value = val.split('#')[0];
      // Convert string values to their appropriate types
      if (value === 'true') {
        params[key] = true;
      } else if (value === 'false') {
        params[key] = false;
      } else if (!Number.isNaN(Number(value))) {
        params[key] = parseFloat(value);
      } else {
        params[key] = value;
      }
    }
    return isEmpty(params) ? {} : params;
  }
}
