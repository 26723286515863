import { Injectable } from '@angular/core';
import { WrappedLoading } from '@core';
import { User } from '@models';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { plainToClass } from 'class-transformer';
import { listUsers } from '../../gql';
import { GqlService } from '../../services';
import { RetrieveMembers } from './member.action';
import { MemberStateModel } from './member.model';

@State<MemberStateModel>({
  name: 'members',
  defaults: {
    data: {
      members: [],
      adminCount: 0,
      registeredAdminCount: 0,
      count: 0
    },
    loading: false,
  },
})
@Injectable()
export class MemberState {
  constructor(private readonly gqlService: GqlService) {}

  @Selector()
  static getMembers(state: MemberStateModel): WrappedLoading<any> {
    return {
      data: {
        members: plainToClass(User, state.data.members),
        adminCount: state.data.adminCount,
        registeredAdminCount: state.data.registeredAdminCount,
        count: state.data.count
      },
      loading: state.loading,
    };
  }

  @Action(RetrieveMembers)
  async retrieve(
    { patchState }: StateContext<MemberStateModel>,
    { input }: RetrieveMembers,
  ) {
    patchState({ loading: true });
    const temp = await this.gqlService
      .query(listUsers, {
        listUsersInput: {
          ...input
        },
      })
      .toPromise();
    if (temp !== null) {
      const { data: members, adminCount, registeredAdminCount, count } = temp;
      patchState({ data: {
        members,
        adminCount,
        registeredAdminCount,
        count
      }, loading: false });
    } else {
      patchState({ data: {
        members: [],
        adminCount: 0,
        registeredAdminCount: 0,
        count: 0
      }, loading: false });
    }
  }
}
