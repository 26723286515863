import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  NavigationItem,
  NavigationModule,
} from '@appkit4/angular-components/navigation';
import { Router, NavigationEnd } from '@angular/router';
import { LayoutService } from '@core';
import { filter } from 'rxjs/operators';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
  selector: 'app-bk-sidenav',
  standalone: true,
  imports: [CommonModule, NavigationModule],
  templateUrl: './bk-sidenav.component.html',
  styleUrls: ['./bk-sidenav.component.scss'],
})
export class BkSidenavComponent {
  solid: boolean = false;
  simple: boolean = true;
  public collapsed: boolean = false;
  selectedIndex: number = -1;
  selectedSubIndex: number = -1;
  navList: Array<any> = [
    {
      name: 'Customers',
      prefixIcon: 'community',
      suffixIcon: 'down-chevron',
      open: true,
      children: [
        {
          name: 'Customers',
          routerLink: '/bookkeeper/customers',
          permission: 'VIEW_CUSTOMER_DETAILS',
        },
        {
          name: 'Customer Groups',
          routerLink: '/bookkeeper/customer-groups',
          permission: 'VIEW_CUSTOMER_GROUP',
        },
        {
          name: 'Deactivated Customers',
          routerLink: '/bookkeeper/deactivated-customers',
          permission: 'VIEW_DEACTIVATE_CUSTOMER',
        },
      ],
    },
    {
      name: 'Tasks',
      prefixIcon: 'check-hook',
      suffixIcon: 'down-chevron',
      open: true,
      children: [
        {
          name: 'My Tasks',
          routerLink: '/bookkeeper/task-list/bk-task-list',
          permission: 'VIEW_TASK_LIST_PWC',
        },
        {
          name: 'Task Library',
          routerLink: '/bookkeeper/task-library',
          permission: 'VIEW_ALL_TASK_LIBS',
        },
        {
          name: 'Task Templates',
          routerLink: '/bookkeeper/template-library',
          permission: 'VIEW_TEMPLATE_LIBRARY',
        },
      ],
    },
    {
      name: 'Mail',
      prefixIcon: 'email',
      suffixIcon: 'down-chevron',
      open: true,
      children: [
        {
          name: 'Inbox',
          routerLink: '/bookkeeper/mail-box',
          permission: 'VIEW_EMAIL_INBOX',
        },
        {
          name: 'Mail Templates',
          routerLink: '/bookkeeper/email-templates',
          permission: 'MANAGE_EMAIL_TEMPLATE',
        },
      ],
    },
    {
      name: 'Cases',
      routerLink: '/bookkeeper/cases',
      prefixIcon: 'document-file',
      permission: 'VIEW_CASE',
    },
  ];

  constructor(
    private router: Router,
    public layoutService: LayoutService,
    private readonly permissionsService: NgxPermissionsService,
  ) {
    this.sideItemInit();
    this.matchNavItem(this.router.url);
    router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        this.matchNavItem((event as NavigationEnd).url);
      });
  }

  sideItemInit() {
    this.navList = this.mapNestedArray(this.navList);
  }

  mapNestedArray(list: any[]) {
    return list
      .map((item) => {
        if (
          item.permission &&
          !this.permissionsService.getPermission(item.permission)
        ) {
          return null;
        } else {
          if (item.children && Array.isArray(item.children)) {
            item.children = this.mapNestedArray(item.children);
          }
        }
        return item;
      })
      .filter((item) => this.filterNestedArray(item));
  }

  filterNestedArray(item) {
    if (!item) {
      return false;
    } else {
      if (
        item.children &&
        Array.isArray(item.children) &&
        item.children.length !== 0
      ) {
        item.children = item.children.filter((_item) =>
          this.filterNestedArray(_item),
        );
      }
      return true;
    }
  }

  matchNavItem(url: string) {
    this.selectedIndex = -1;
    this.selectedSubIndex = -1;
    url = url.split('?')[0];
    const urlParts = url.split('/');
    this.navList.forEach((item, index) => {
      if (item.children) {
        item.children?.forEach((subItem, subIndex) => {
          const linkParts = subItem.routerLink.split('/');
          if (linkParts.every((part, i) => part === urlParts[i])) {
            this.selectedIndex = index;
            this.selectedSubIndex = subIndex;
          }
        });
      } else {
        const linkParts = item.routerLink.split('/');
        if (linkParts.every((part, i) => part === urlParts[i])) {
          this.selectedIndex = index;
        }
      }
    });
  }

  redirect(event: any) {
    // trigger a global click to close all kinds (notification/comment/note) panel
    document.querySelector('body').dispatchEvent(
      new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
      }),
    );
    if (event?.subItem) {
      this.router.navigateByUrl(event.subItem.routerLink);
    }
    if (event.item?.routerLink) {
      this.router.navigateByUrl(event.item.routerLink);
    }
  }

  onCollapsedSidebar({ status }): void {
    this.collapsed = status;
    this.layoutService.fullScreen$.next(this.collapsed);
  }
}
