import dateHolidays from 'date-holidays';
import moment from 'moment-business-days';
import momentBusiness from 'moment-business-days';

export class BusinessDaysHelper {
  constructor() {
    momentBusiness.updateLocale('us', {
      nextBusinessDayLimit: 31,
      prevBusinessDayLimit: 31,
      workingWeekdays: [1, 2, 3, 4, 5],
    });
    this.hd = new dateHolidays({
      types: ['public', 'observance'],
    });
    this.hd.init('US');
  }

  private holidays = {};
  private businessDays = {};
  private hd: dateHolidays;
  private customHolidays = [
    "New Year's Day",
    "New Year's Day (substitute day)",
    'Martin Luther King Jr. Day',
    'Memorial Day',
    'Independence Day',
    'Independence Day (substitute day)',
    'Labor Day',
    'Thanksgiving Day',
    'Day after Thanksgiving Day',
    'Christmas Day',
    'Christmas Day (substitute day)',
  ];

  public currentOrNextBusinessDay(date: Date) {
    const currentDate = moment(date); // use a clone
    // date is not weekend and custom holiday
    if (
      currentDate.isoWeekday() !== 6 &&
      currentDate.isoWeekday() !== 7 &&
      !this.isHoliday(date)
    ) {
      return date;
    }
    return this.nextBusinessDay(date);
  }

  public nextBusinessDay(date: Date) {
    let nextDate = moment(date).add(1, 'days'); // use a clone
    while (
      nextDate.isoWeekday() === 6 ||
      nextDate.isoWeekday() === 7 ||
      this.isHoliday(nextDate.toDate())
    ) {
      nextDate = nextDate.add(1, 'days');
    }
    return nextDate.toDate();
  }

  public nextNBusinessDay(date: Date, n: number) {
    let endDate: Date = date;
    Array.from({ length: n }, (_, i) => {
      endDate = this.nextBusinessDay(endDate);
    });
    return endDate;
  }

  public currentOrPrevBusinessDay(date: Date) {
    const currentDate = moment(date); // use a clone
    // date is not weekend and custom holiday
    if (
      currentDate.isoWeekday() !== 6 &&
      currentDate.isoWeekday() !== 7 &&
      !this.isHoliday(date)
    ) {
      return date;
    }

    return this.prevBusinessDay(date);
  }

  public prevBusinessDay(date: Date) {
    let prevDate = moment(date).subtract(1, 'days'); // use a clone
    while (
      prevDate.isoWeekday() === 6 ||
      prevDate.isoWeekday() === 7 ||
      this.isHoliday(prevDate.toDate())
    ) {
      prevDate = prevDate.subtract(1, 'days');
    }
    return prevDate.toDate();
  }

  public isBusinessDay(date: Date) {
    const currentDate = moment(date); // use a clone
    return (
      currentDate.isoWeekday() !== 6 &&
      currentDate.isoWeekday() !== 7 &&
      !this.isHoliday(date)
    );
  }

  public isMonday(date: Date) {
    const currentDate = moment(date); // use a clone
    return currentDate.isoWeekday() === 1;
  }

  public isHoliday(date: Date) {
    const year = date.getFullYear();
    if (!this.holidays[year]) {
      this.holidays[year] = this.hd
        .getHolidays(year)
        .filter((holiday) => this.customHolidays.includes(holiday.name));
    }
    return this.holidays[year].find(
      (holiday) =>
        holiday.date.split(' ')[0] === moment(date).format('YYYY-MM-DD'),
    );
  }

  // Note: this is different from above function. It is handled by momentBusiness
  public getMomentCurrentOrNextBusinessDay(date: Date): Date {
    if (this.isBusinessDay(date)) {
      return date;
    } else {
      let nextBusinessDay = momentBusiness(date).nextBusinessDay();
      while (this.isHoliday(nextBusinessDay.toDate())) {
        nextBusinessDay = nextBusinessDay.nextBusinessDay();
      }
      return nextBusinessDay.toDate();
    }
  }

  public getMomentPrevBusinessDay(date: Date): Date {
    let prevBusinessDay = momentBusiness(date).prevBusinessDay();
    while (this.isHoliday(prevBusinessDay.toDate())) {
      prevBusinessDay = prevBusinessDay.prevBusinessDay();
    }
    return prevBusinessDay.toDate();
  }

  public isMomentBusinessDay(date: Date) {
    // momentBusiness uses weekend and holiday to verify if it is a business day
    return momentBusiness(date).isBusinessDay() && !this.isHoliday(date);
  }
}

export default new BusinessDaysHelper();
