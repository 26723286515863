import gql from 'graphql-tag';
import { tenantFragment } from './base.gql';

export const getAssignedTenants = gql`
  ${tenantFragment}
  query getAssignedTenants(
    $skipBkUsers: Boolean = true
    $tenantKey: Int
    $userKey: Int
  ) {
    getAssignedTenants(where: { tenantKey: $tenantKey, userKey: $userKey }) {
      ...tenantFragment
      tenantKey
      connectionStatus
      tenantType {
        tenantTypeKey
        nme
      }
      bkUsers @skip(if: $skipBkUsers) {
        email
        firstNme
        lastNme
        userType {
          nme
        }
        specialRole
        avatar
      }
      asgMtVersion
      tenantStatus
      adminEmail
      orgStatus
      orgStatusUpdatedAt
      initialLoaded
    }
  }
`;

export const getAssignedTenantsForBK = gql`
  query getAssignedTenants($userKey: Int) {
    getAssignedTenants(where: { userKey: $userKey }) {
      tenantKey
    }
  }
`;

export const dataSyncUpdate = gql`
  ${tenantFragment}
  subscription dataSyncUpdate($skipBkUsers: Boolean = true) {
    dataSyncUpdate {
      ...tenantFragment
      connectionStatus
      tenantType {
        tenantTypeKey
        nme
      }
      bkUsers @skip(if: $skipBkUsers) {
        email
        firstNme
        lastNme
        userType {
          nme
        }
      }
      asgMtVersion
      tenantStatus
      tenantStatus
      adminEmail
      orgStatus
      orgStatusUpdatedAt
      initialLoaded
    }
  }
`;

export const bkAssignmentMgmt = gql`
  mutation bkAssignmentMgmt($manageBKInput: ManageBKInput) {
    bkAssignmentMgmt(input: $manageBKInput) {
      message
    }
  }
`;

export const getTenant = gql`
  ${tenantFragment}
  query getTenant($tenantKey: Int!, $orgGroupId: String) {
    getTenant(where: { tenantKey: $tenantKey, nextOrgGroupId: $orgGroupId }) {
      ...tenantFragment
      lastNotifiedAt
      isBudgeting
      onboardingStatus
      customerViewTaskCount
      publishedTaskCount
      taskCount
      pendingTaskCount
      allUserCount
      activityLogCount
      primaryContact {
        avatar
        bio
        firstNme
        lastNme
        appointmentLink
      }
      lineage
      industry {
        industryKey
        name
      }
      entityType
      bkUsers {
        userKey
        userType {
          nme
        }
        firstNme
        lastNme
        email
        phone
        bio
        avatar
        appointmentLink
      }
      disablePermissions
    }
  }
`;

export const onboardOrganization = gql`
  mutation onboardOrganization($input: OnboardOrganizationInput) {
    onboardOrganization(input: $input) {
      message
      status
      organization {
        product
        organizationId
        organizationName
        onboardAdminEmail
      }
    }
  }
`;

export const resendUserInvite = gql`
  mutation resendUserInvite($input: ResendUserInviteInput) {
    resendUserInvite(input: $input) {
      message
      status
      user {
        email
      }
    }
  }
`;

export interface OnboardOrganizationInput {
  product: string;
  organizationName: string;
  adminEmail: string;
  allowedDomains?: string;
  restrictedDomains?: string;
}

export interface ResendUserInviteInput {
  requestingUserEmail?: string;
  userEmail: string;
}

export interface TenantUserWhere {
  userKey?: number;
}

export interface GetAssignedTenantsVariable {
  where?: TenantUserWhere;
}

export interface GetTenantVariable {
  tenantKey?: number;
}

export interface ManageBKInput {
  asgMtVersion: string;
  tenantKey: number;
  emails: string[];
}
