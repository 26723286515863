import { Component } from '@angular/core';
import { SortingType } from '@core';
import { NgClass, NgIf } from '@angular/common';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { IHeaderParams } from 'ag-grid-community';
import { CheckboxModule } from '@appkit4/angular-components/checkbox';
import { TooltipModule } from '@appkit4/angular-components/tooltip';
import { FormsModule } from '@angular/forms';

export interface ICustomHeaderParams {
  ORDER_BY: string;
  orderBy: string;
  sortDir: SortingType;
  onChangeSort: Function;
  styleClass: string;
  checkbox?: any;
  icon?: any;
  defaultSort: 'asc' | 'desc';
}

@Component({
  selector: 'app-table-header',
  templateUrl: './table-header.component.html',
  styleUrls: ['./table-header.component.scss'],
  standalone: true,
  imports: [NgClass, NgIf, CheckboxModule, TooltipModule, FormsModule],
})
export class TableHeaderComponent implements IHeaderAngularComp {
  params!: IHeaderParams & ICustomHeaderParams;
  displayName: string;
  enableSorting: boolean;
  isActive: boolean;
  isAscend: boolean;
  SortingType = SortingType;
  styleClass = '';
  checkbox = null;
  icon = null;
  defaultSort: 'asc' | 'desc' = 'asc';
  // The agInit(params) method is called on the header component once.
  agInit(params: IHeaderParams & ICustomHeaderParams) {
    this.params = params;
    const {
      displayName,
      enableSorting,
      ORDER_BY,
      orderBy,
      sortDir,
      styleClass,
      checkbox,
      icon,
      defaultSort,
    } = params;
    this.isActive = orderBy && ORDER_BY && orderBy === ORDER_BY;
    this.isAscend = sortDir === SortingType.ASC;
    this.displayName = displayName;
    this.enableSorting = enableSorting;
    this.styleClass = styleClass;
    if (checkbox) {
      this.checkbox = checkbox;
    }
    if (icon) {
      this.icon = icon;
    }
    if (defaultSort) {
      this.defaultSort = defaultSort;
    }

    const colId = this.params.column.getColId();
    if (colId) {
      this.params.api.getColumnState().forEach((col) => {
        if (col.colId === colId && col.sort) {
          this.isActive = true;
          this.isAscend = col.sort === 'asc';
        }
      });
    }
  }

  refresh(params: IHeaderParams & ICustomHeaderParams) {
    return true;
  }

  onSortChanged() {
    if (this.enableSorting) {
      const colId = this.params.column.getColId();
      if (colId) {
        const state = this.params.api.getColumnState().map((col) => {
          if (col.colId !== colId) {
            Object.assign(col, { sort: null });
          } else {
            Object.assign(col, {
              sort:
                col.sort === null
                  ? this.defaultSort
                  : col.sort === 'asc'
                  ? 'desc'
                  : 'asc',
            });
          }
          return col;
        });
        this.params.api.applyColumnState({ state });
        this.params.api.refreshHeader();
      }

      if (typeof this.params.onChangeSort === 'function') {
        this.params.onChangeSort(this.params);
      }
    }
  }

  clickIcon() {
    if (typeof this.icon.click === 'function') {
      this.icon.click();
    }
  }

  constructor() {}
}
