import { AfterViewInit, Component } from '@angular/core';
import { NgClass, NgIf } from '@angular/common';
import { INoRowsOverlayAngularComp } from 'ag-grid-angular';
import { INoRowsOverlayParams } from 'ag-grid-community';
import { ButtonModule } from '@appkit4/angular-components/button';

export interface ICustomNoRowsParams {
  clearAll: Function;
}

@Component({
  standalone: true,
  selector: 'app-table-no-rows',
  templateUrl: './table-no-rows.component.html',
  imports: [ButtonModule],
})
export class TableNoRowsComponent implements INoRowsOverlayAngularComp {
  params: any;
  agInit(params: INoRowsOverlayParams & ICustomNoRowsParams): void {
    this.params = params;
  }
}
