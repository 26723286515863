
import gql from 'graphql-tag';
export const submitTransactionSplit = gql`
  mutation submitTransactionSplit($transactionSplitInput: TransactionSplitInput) {
    submitTransactionSplit(input:$transactionSplitInput){
      status
      message
    }
  }
`;

export interface TransactionSplitInput {
  bankFeedKey: number;
  transactions: [TransactionItem];
}
export interface TransactionItem {
  accountKey: number;
  classKey: number;
  amount: number;
  vendorKey: number;
  customerProjectKey?: number;
}
export interface TransactionSplitVariable {
  transactionSplitInput: TransactionSplitInput;
}
