import { enableProdMode, importProvidersFrom } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { environment } from '@env';
import { AppComponent } from './app/app.component';
import { GraphQLModule } from './app/graphql.module';
import { NgxPermissionsModule } from 'ngx-permissions';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';
import { NgxPendoModule } from 'ngx-pendo';
import {
  withInterceptorsFromDi,
  provideHttpClient,
} from '@angular/common/http';
import { CoreModule, states } from '@core';
import { AppRoutingModule } from './app/app-routing.module';
import { provideAnimations } from '@angular/platform-browser/animations';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      BrowserModule,
      AppRoutingModule,
      CoreModule,
      NgxPendoModule.forRoot({
        pendoApiKey: environment.pendoApi,
        pendoIdFormatter: (value: any) => value.toString().toLowerCase(),
      }),
      NgxsModule.forRoot(states, { developmentMode: !environment.production }),
      NgxsStoragePluginModule.forRoot(),
      NgxsReduxDevtoolsPluginModule.forRoot({
        disabled: environment.production,
      }),
      NgxsLoggerPluginModule.forRoot({ disabled: environment.production }),
      NgxPermissionsModule.forRoot(),
      GraphQLModule,
    ),
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
  ],
}).catch((err) => console.error(err));
