import { Type } from 'class-transformer';
import { EmployeeType } from './employee-type.model';
import { Rate } from './rate.model';

export class Employee {
  employeeKey: number;
  firstNme: string;
  lastNme: string;
  email: string;

  @Type(() => EmployeeType)
  employeeType: EmployeeType;
  @Type(() => Rate)
  rates: Rate[];

  get displayNme() {
    let nme = '';
    if (this.firstNme) {
      nme += this.firstNme;
    }

    if (this.lastNme) {
      nme += ` ${this.lastNme}`;
    }

    return nme;
  }

  get standardRate(): number {
    let rate = 0;
    if (this.rates.length > 0) {
      rate = this.rates[0].hourlyRate;
    }
    return rate;
  }
}
