import gql from 'graphql-tag';
export const getDomoEmbeds = gql`
  query getDomoEmbeds($domoEmbedInput: DomoEmbedInput!) {
    getDomoEmbeds(input: $domoEmbedInput) {
      status
      message
      embedStr
    }
  }
`;

export const updateTheme = gql`
  mutation updateTheme($theme: AppTheme!) {
    updateTheme(theme: $theme) {
      status
      message
    }
  }
`;

export interface DomoEmbedInput {
  mode: DashboardTheme;
  dashboardType: DashboardTypes;
  tenantKey?: number;
}

export enum DashboardTheme {
  DARK = 'DARK',
  LIGHT = 'LIGHT',
}

export enum DashboardTypes {
  FINANCIALS = 'FINANCIALS',
  WORKING_CAPITAL = 'WORKING_CAPITAL',
  TRENDS_ANALYSIS = 'TRENDS_ANALYSIS',
  BUDGETING = 'BUDGETING',
  CONSOLIDATED = 'CONSOLIDATED',
}

export enum DashboardTDateFilterTypes {
  CURRENT_MONTH = 'CURRENT_MONTH',
  PRIOR_MONTH = 'PRIOR_MONTH',
}
