<div (click)="onSortChanged()" role="columnheader" class="table-header-wrapper" [attr.aria-label]=" displayName+' '+(isActive ? (isAscend ? 'ascendant sorted' : 'descendant sorted') : 'not sorted')">
  <ng-container *ngIf="!checkbox; else hasCheckbox">
    <span class="ap-typography-body ap-font-weight-2" [ngClass]="styleClass">{{displayName}}</span>
    <ng-container *ngIf="icon">
      <span ap-tooltip [tooltipId]="displayName + '-ID'" [tooltipContent]="icon.tooltipContent || ''" [direction]="'top'"
        [ngClass]="['Appkit4-icon', 'icon-'+(icon.icon || 'information-fill'), 'app-background-icon-primary']"
        [class.cursor-pointer]="icon.click" (click)="clickIcon()" tabindex="0" [attr.aria-label]="icon.tooltipContent || 'Information Tooltip'" role="tooltip" ></span>
    </ng-container>
    <ng-container *ngIf="enableSorting">
      <span class="Appkit4-icon cursor-pointer"
        [ngClass]="'icon-sorting-' + (isActive ? (isAscend ? 'ascendant-' : 'descendant-') : '') + 'fill'"></span>
    </ng-container>
  </ng-container>
  <ng-template #hasCheckbox>
    <ap-checkbox [(ngModel)]="checkbox.checked" [disabled]="checkbox.disabled" [indeterminate]="checkbox.indeterminate"
      (onChange)="checkbox.onCheck()" class="ap-typography-body ap-font-weight-2">
      <span>{{displayName}}</span>
    </ap-checkbox>
  </ng-template>
</div>