import { Injectable } from '@angular/core';
import {
  ChangeOrganizationStatusInput,
  SortingType,
  changeOrganizationStatus,
  listUsers,
} from '@core';
import { getIndustries } from '@core/gql/chart-of-accounts.gql';
import {
  ListHierarchicalTenantsInput,
  OnBoardingStatusInput,
  ShowTenantStageInput,
  authorizeConnection,
  createConnection,
  getTenantsNameForUser,
  listAllHierarchicalTenants,
  listHierarchicalAssignedTenants,
  listHierarchicalTenants,
  showTenantStage,
  unauthorizeConnection,
  updateCustomerOnboardStatus,
} from '@core/gql/unmatched-transactions.gql';
import { GqlService } from './gql.service';
import { dateMarker } from './util.service';

@Injectable({
  providedIn: 'root',
})
export class CustomersService {
  constructor(private readonly gqlService: GqlService) {}

  getTenantsNameForUser(input) {
    return this.gqlService.query(getTenantsNameForUser, { input });
  }

  authorizeConnection(
    tenantKey: number,
    connection: string,
    redirectUri: string,
  ) {
    return this.gqlService.mutate(authorizeConnection, {
      tenantKey,
      connection,
      redirectUri,
    });
  }

  createConnection(redirectUri: string) {
    return this.gqlService.mutate(createConnection, { redirectUri });
  }

  unauthorizeConnection(tenantKey: number, connection: string) {
    return this.gqlService.mutate(unauthorizeConnection, {
      tenantKey,
      connection,
    });
  }

  changeOrganizationStatus(input: ChangeOrganizationStatusInput) {
    return this.gqlService.mutate(changeOrganizationStatus, {
      input,
    });
  }
  listHierarchicalTenants(
    listHierarchicalTenantsInput: ListHierarchicalTenantsInput,
  ) {
    return this.gqlService.query(listHierarchicalTenants, {
      listHierarchicalTenantsInput: {
        ...listHierarchicalTenantsInput,
        dateMarker: dateMarker(),
      },
    });
  }

  listAllHierarchicalTenants() {
    return this.gqlService.query(listAllHierarchicalTenants, {
      input: {},
    });
  }

  listHierarchicalAssignedTenants() {
    return this.gqlService.query(listHierarchicalAssignedTenants);
  }

  getIndustries() {
    return this.gqlService.query(getIndustries);
  }
  listUsers() {
    return this.gqlService.query(listUsers, {
      listUsersInput: {
        bkOpt: { includeSA: true, includeSelf: true, registered: true },
        sortingType: SortingType.ASC,
        orderBy: 'NAME',
      },
    });
  }

  updateCustomerOnboardStatus(input: OnBoardingStatusInput) {
    return this.gqlService.mutate(updateCustomerOnboardStatus, {
      input,
    });
  }

  showTenantStage(input: ShowTenantStageInput) {
    return this.gqlService.query(showTenantStage, { input });
  }
}
