import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FooterModule } from '@appkit4/angular-components/footer';
import { UtilService } from '@core';
import { environment } from '@env';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [CommonModule, FooterModule],
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  readonly termsOfUseUrl = environment.termsOfUseUrl;
  footerContent: string =
    '© 2024 PwC. All rights reserved. PwC refers to the US member firm of the PwC network or one of its subsidiaries or affiliates.';
  constructor(private readonly utilsService: UtilService) {}

  openPrivacy() {
    this.utilsService.navigate('/legal/privacy');
  }

  openTerms() {
    window.open(this.termsOfUseUrl);
  }

  triggerCookieSetting() {
    // Get the shadow cookie setting div element
    const element = document.getElementById('ot-sdk-btn');

    // Create a new event
    const event = new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window,
    });

    // Dispatch the event
    element.dispatchEvent(event);
  }
}
