import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GqlService {
  constructor(private readonly apollo: Apollo) {}

  query(query, variables?: any): Observable<any> {
    const options: any = {
      query,
    };
    if (variables) {
      options.variables = variables;
    }
    return this.apollo.query<any>(options).pipe(
      map(({ data }) => {
        return data[Object.keys(data)[0]];
      }),
    );
  }

  mutate(mutation: any, variables: any, context?: any): Observable<any> {
    return this.apollo
      .mutate<any>({
        mutation,
        variables,
        context,
      })
      .pipe(
        map(({ data }) => {
          return data[Object.keys(data)[0]];
        }),
      );
  }

  subscription(query: any, document: any) {
    const queryRef = this.apollo.watchQuery({
      query,
      variables: { where: {} },
    });

    queryRef.subscribeToMore({
      document,
      updateQuery: (prev: any, { subscriptionData }) => {
        const temp = prev.getAssignedTenants.filter(
          (el) =>
            el.tenantKey !== subscriptionData.data.dataSyncUpdate.tenantKey,
        );
        return {
          ...prev,
          getAssignedTenants: [...temp, subscriptionData.data.dataSyncUpdate],
        };
      },
    });

    return queryRef;
  }
}
