import { Injectable } from '@angular/core';
import { LayoutService } from './layout.service';

@Injectable({
  providedIn: 'root',
})
export class PendoService {

  constructor(private readonly layoutService: LayoutService) { }

  generalPendoId(...args: string[]) {
    if (this.layoutService.layoutConfig.overview) {
      if (args[0] === 'help-widget' || args.includes('relaunch-product-tour')) {
        return this.emptyToDash((args.join('.'))).toLowerCase();
      }
      return this.emptyToDash(this.layoutService.layoutConfig.overview.concat('.' + args.join('.'))).toLowerCase();
    }
  }

  emptyToDash(value: string) {
    if (value === null || value === undefined || !value.includes(' ')) {
      return value;
    }
    return value = value.replace(/ /g, '-');
  }

}
