import { Class, ChartsOfAccount } from '@core';
import { State, Selector, Action, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { ExpenseService } from '@core/services';
import { RetrieveExpenseFormData, UpdateLoadingStatus } from './action';
import { map } from 'rxjs/operators';
import { combineLatest } from 'rxjs';

export interface ExpenseFormStateModel {
  classes: Class[];
  chartsOfAccounts: ChartsOfAccount[];
  loading: boolean;
}

@State<ExpenseFormStateModel>({
  name: 'expenseForm',
  defaults: {
    classes: [],
    chartsOfAccounts: [],
    loading: false,
  },
})
@Injectable()
export class ExpenseFormState {
  constructor(private readonly expenseService: ExpenseService) {}

  @Selector()
  static getClasses(state: ExpenseFormStateModel): Class[] {
    return state.classes;
  }

  @Selector()
  static getCategories(state: ExpenseFormStateModel): ChartsOfAccount[] {
    return state.chartsOfAccounts;
  }

  @Selector()
  static isLoading(state: ExpenseFormStateModel): boolean {
    return state.loading;
  }

  @Action(RetrieveExpenseFormData)
  async retrieveExpenseFormData(
    { patchState }: StateContext<ExpenseFormStateModel>,
    { payload }: RetrieveExpenseFormData,
  ) {
    patchState({ loading: true });
    const response = await combineLatest([
      this.getClasses(payload.tenantKey),
      this.getChartsOfAccounts(payload.tenantKey),
    ])
      .pipe(
        map((result) => ({
          classes: result[0],
          chartsOfAccounts: result[1],
        })),
      )
      .toPromise();
    patchState({ loading: false });
    if (response) {
      if (response.classes.status === 'SUCCESS') {
        patchState({ classes: response.classes.data });
      }
      if (response.chartsOfAccounts.status === 'SUCCESS') {
        const chartsOfAccounts = response.chartsOfAccounts.data
          .filter((accountData) => accountData.accountNbr)
          .sort((a, b) => a.accountNbr.localeCompare(b.accountNbr));
        patchState({ chartsOfAccounts });
      }
    }
  }

  @Action(UpdateLoadingStatus)
  updateLoadingStatus(
    { patchState }: StateContext<ExpenseFormStateModel>,
    { payload }: UpdateLoadingStatus,
  ) {
    patchState({ loading: payload });
  }

  private getClasses(tenantKey) {
    return this.expenseService.getClasses(tenantKey);
  }

  private getChartsOfAccounts(tenantKey) {
    return this.expenseService.getChartsOfAccounts({tenantKeys: [tenantKey]});
  }
}
