import { DEFAULT_LIMIT } from '@shared/component/table-pagination/table-pagination.component';
import {
  ColDef,
  GridOptions,
  SuppressHeaderKeyboardEventParams,
  SuppressKeyboardEventParams,
} from 'ag-grid-enterprise';

const GRID_CELL_CLASSNAME = 'ag-cell';

const getAllFocusableElementsOf = (el: HTMLElement) => {
  return Array.from<HTMLElement>(
    el.querySelectorAll(
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])',
    ),
  ).filter((focusableEl) => {
    return focusableEl.tabIndex !== -1;
  });
};
const getEventPath = (event: Event): HTMLElement[] => {
  const path: HTMLElement[] = [];
  let currentTarget: any = event.target;

  while (currentTarget) {
    path.push(currentTarget);
    currentTarget = currentTarget.parentElement;
  }

  return path;
};

export const suppressKeyboardEvent = ({
  event,
}: SuppressKeyboardEventParams<any>) => {
  const { key, shiftKey } = event;
  const path = getEventPath(event);
  const isTabForward = key === 'Tab' && shiftKey === false;
  const isTabBackward = key === 'Tab' && shiftKey === true;

  let suppressEvent = false;

  // Handle cell children tabbing
  if (isTabForward || isTabBackward) {
    const eGridCell = path.find((el) => {
      if (el.classList === undefined) return false;
      return el.classList.contains(GRID_CELL_CLASSNAME);
    });

    if (!eGridCell) {
      return suppressEvent;
    }

    const focusableChildrenElements = getAllFocusableElementsOf(eGridCell);
    const lastCellChildEl =
      focusableChildrenElements[focusableChildrenElements.length - 1];
    const firstCellChildEl = focusableChildrenElements[0];

    // Suppress keyboard event if tabbing forward within the cell and the current focused element is not the last child
    if (focusableChildrenElements.length === 0) {
      return false;
    }

    const currentIndex = focusableChildrenElements.indexOf(
      document.activeElement as HTMLElement,
    );

    if (isTabForward) {
      const isLastChildFocused =
        lastCellChildEl && document.activeElement === lastCellChildEl;

      if (!isLastChildFocused) {
        suppressEvent = true;
        if (currentIndex !== -1 || document.activeElement === eGridCell) {
          event.preventDefault();
          focusableChildrenElements[currentIndex + 1].focus();
        }
      }
    }
    // Suppress keyboard event if tabbing backwards within the cell, and the current focused element is not the first child
    else {
      const cellHasFocusedChildren =
        eGridCell.contains(document.activeElement) &&
        eGridCell !== document.activeElement;

      // Manually set focus to the last child element if cell doesn't have focused children
      if (!cellHasFocusedChildren) {
        lastCellChildEl.focus();
        // Cancel keyboard press, so that it doesn't focus on the last child and then pass through the keyboard press to
        // move to the 2nd last child element
        event.preventDefault();
      }

      const isFirstChildFocused =
        firstCellChildEl && document.activeElement === firstCellChildEl;
      if (!isFirstChildFocused) {
        suppressEvent = true;
        if (currentIndex !== -1 || document.activeElement === eGridCell) {
          event.preventDefault();
          focusableChildrenElements[currentIndex - 1].focus();
        }
      }
    }
  }

  return suppressEvent;
};
export const suppressHeaderKeyboardEvent = (
  event: SuppressHeaderKeyboardEventParams<any> & { column: any; colDef: any },
) => {
  if (event.event.key === 'Enter') {
    const obj = {
      api: event.api,
      ORDER_BY: event.colDef.headerComponentParams.ORDER_BY,
    };
    event.colDef.headerComponentParams.onChangeSort(obj);
    return true;
  }
  return false;
};
export const defaultColDef: ColDef = {
  suppressHeaderMenuButton: true,
  icons: {
    sortAscending: '<i class="Appkit4-icon icon-sorting-ascendant-fill"></i>',
    sortDescending: '<i class="Appkit4-icon icon-sorting-descendant-fill"></i>',
    sortUnSort: '<i class="Appkit4-icon icon-sorting-fill"></i>',
  },
  suppressKeyboardEvent: suppressKeyboardEvent.bind(this),
  suppressHeaderFilterButton: true,
  sortingOrder: ['asc', 'desc'],
};

export const updateDefaultColDef = (colDef: ColDef) => {
  return { ...defaultColDef, ...colDef };
};

export const gridOptions: GridOptions = {
  headerHeight: 48,
  rowHeight: 48,
  domLayout: 'autoHeight',
  suppressRowClickSelection: true,
  pagination: true,
  suppressPaginationPanel: true,
  cacheBlockSize: DEFAULT_LIMIT,
  maxBlocksInCache: 0,
  singleClickEdit: true,
  overlayNoRowsTemplate: '<span>No data available</span>',
  popupParent: document.body,
  suppressContextMenu: true
};

export const updateGridOptions = (options: GridOptions) => {
  return { ...gridOptions, ...options };
};
