export class RetrieveExpenseFormData {
  static readonly type = '[EXPENSE-FORM-DATA] retrieve';
  constructor(public payload: ExpenseFormStateInput) {}
}

export class UpdateLoadingStatus {
  static readonly type = '[LOADING-STATUS] update';
  constructor(public payload: boolean) {}
}

export interface ExpenseFormStateInput {
  tenantKey: number;
}
