import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'shortNumberSuffix',
    standalone: true
})
export class ShortNumberSuffixPipe implements PipeTransform {

  transform(value: any, fixed = 0, prefix = '', suffix = '', isShort = false): any {
    if (Number.isNaN(value) || value === null || value === undefined) {
      return null;
    }

    const suffixes = ['K', 'M', 'B'];
    const abs = Math.abs(value);
    let suffixesIndex = -1;
    let tempValue = abs;
    if (suffix) {
      suffix = ' ' + suffix;
    }

    const maxValue = (isShort ? 999 : 999999) + Math.pow(10, (-fixed - 1)) * 5;
    while (tempValue >= maxValue && suffixesIndex < suffixes.length - 1) {
      tempValue = tempValue / 1000;
      suffixesIndex++;
    }

    if (suffixesIndex >= 0) {
      suffix = suffixes[suffixesIndex] + suffix;
    }

    return `${value >= 0 ? '' : '-'}${prefix || ''}${Number(tempValue.toFixed(fixed)).toLocaleString()}${(suffix || '')}`;
  }

}
