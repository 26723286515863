import { User } from '@models';
import gql from 'graphql-tag';

export const uploadReportFile = gql`
  mutation uploadReportFile($fileUploadInput: FileUploadInput!) {
    uploadReportFile(input: $fileUploadInput) {
      status
      message
    }
  }
`;

export const updateFileAttributes = gql`
  mutation updateFileAttributes($input: [FileAttributesInput]) {
    updateFileAttributes(input: $input) {
      status
      message
    }
  }
`;

export const listFiles = gql`
  query listFiles($listFileInput: ListFileInput) {
    listFiles(input: $listFileInput) {
      status
      message
      count
      data {
        startDate
        endDate
        category
        fileType
        fileName
        reportId
        createdAt
        tenantKey
        reportKey
        visible
        uploader {
          userKey
          avatar
          email
          firstNme
          lastNme
        }
        unreadCommentsCount
        unreadNotesCount
      }
    }
  }
`;

export const downloadFile = gql`
  query file($fileInput: FileInput) {
    file(input: $fileInput) {
      status
      message
      data {
        name
        base64Content
      }
    }
  }
`;

export const bulkDownloadFile = gql`
  query bulkDownloadFile($bulkDownloadFileInput: BulkDownloadFileInput) {
    bulkDownloadFile(input: $bulkDownloadFileInput) {
      status
      message
      data {
        name
        base64Content
      }
    }
  }
`;

export const getFullUserList = gql`
  query getFullUserList($tenantKey: Int!, $includeBK: Boolean!) {
    getFullUserList(tenantKey: $tenantKey, includeBK: $includeBK) {
      userKey
      email
      firstNme
      lastNme
      userType {
        nme
      }
    }
  }
`;

export const getNotifiedUsers = gql`
  query listNotifiedUsers($tenantKey: Int, $reportKeys: [Int]) {
    listNotifiedUsers(tenantKey: $tenantKey, reportKeys: $reportKeys) {
      data {
        user {
          userKey
          email
          firstNme
          lastNme
        }
        reports {
          reportId
        }
      }
    }
  }
`;

export const getVisibleUsers = gql`
  query listUsersForFileVisibility($tenantKey: Int, $reportKey: Int) {
    listUsersForFileVisibility(tenantKey: $tenantKey, reportKey: $reportKey) {
      data {
        accessible
        businessFunction {
          businessFunctionKey
        }
        user {
          userKey
          email
          firstNme
          lastNme
        }
      }
    }
  }
`;

export const getUsersForComment = gql`
  query listUsersForComment($tenantKey: Int, $reportKey: Int) {
    listUsersForComment(tenantKey: $tenantKey, reportKey: $reportKey) {
      data {
        bks {
          userKey
          firstNme
          lastNme
          email
          userType {
            userTypeKey
            nme
          }
        }
        customers {
          userKey
          firstNme
          lastNme
          email
          userType {
            userTypeKey
            nme
          }
        }
      }
    }
  }
`;

export const sendReportUploadNotification = gql`
  mutation sendReportUploadNotification(
    $reportUploadNotifInput: ReportUploadNotifInput
  ) {
    sendReportUploadNotification(input: $reportUploadNotifInput) {
      status
      message
    }
  }
`;

export const listComments = gql`
  query listComments($listCommentsInput: ListCommentsInput!) {
    listComments(input: $listCommentsInput) {
      status
      data {
        commentKey
        report {
          category
          fileName
        }
        content
        user {
          userKey
          email
          firstNme
          lastNme
          avatar
        }
        attachedFileName
        createdAt
        updatedAt
      }
    }
  }
`;

export const saveComment = gql`
  mutation saveComment($commentInput: CommentInput!) {
    saveComment(input: $commentInput) {
      status
      message
    }
  }
`;

export const deleteReportFile = gql`
  mutation deleteReportFile($reportKey: Int!) {
    deleteReportFile(reportKey: $reportKey) {
      status
      message
    }
  }
`;
export const getCommentAttachment = gql`
  query getCommentAttachment($commentKey: Int!) {
    getCommentAttachment(commentKey: $commentKey) {
      status
      message
      data {
        name
        base64Content
      }
    }
  }
`;

export const listReportTypes = gql`
  query listReportTypes($input: ListReportTypesInput) {
    listReportTypes(input: $input) {
      status
      message
      data {
        reportTypeKey
        name
        displayName
        tenantKey
      }
    }
  }
`;

export const getTenantName = gql`
  query getTenantName($tenantKey: Int!) {
    getTenant(where: { tenantKey: $tenantKey }) {
      nme
    }
  }
`;

export const updateFile = gql`
  mutation updateFile($reportKey: Int!, $visibility: Boolean!) {
    updateReportFile(reportKey: $reportKey, visibility: $visibility) {
      status
      message
    }
  }
`;

export const updateFileAccess = gql`
  mutation updateFileAccess($reportKey: Int, $userKeys: [Int]) {
    updateFileAccess(reportKey: $reportKey, userKeys: $userKeys) {
      status
      message
    }
  }
`;

export interface FileUploadInput {
  tenantKey: number;
  fileInfos: FileInfo[];
}

export interface FileInfo {
  visible?: boolean;
  startDate: string;
  endDate: string;
  fileType: string;
  reportTypeKey: number;
  category: string;
  file: any;
}

export interface FileAttributesInput {
  reportKey: number;
  fileName?: string;
  visible?: boolean;
  startDate: Date;
  endDate: Date;
  fileType: string;
  reportTypeKey: number;
  category: string;
}

export interface ListFileInput {
  tenantKey: number;
  searchText: string;
  limit: number;
  offset: number;
  uploaderKey: number;
  startDate: string;
  endDate: string;
  orderBy: string;
  sortingType: string;
  categoryId: number;
  fileType: string;
  BKViewCustomer: boolean;
}

export interface FileListResponse {
  data: FileMata[];
  status: string;
  message: string;
  count: number;
}

export interface FileMata {
  title?: string;
  startDate?: string;
  endDate?: string;
  category?: string;
  fileType?: string;
  fileName?: string;
  tenantKey?: number;
  reportKey?: number;
  storageFileName?: string;
  createdAt?: string;
  reportId?: string;
  isSelected?: boolean;
  visible?: boolean;
  skipApproval?: boolean;
  unreadCommentsCount?: number;
  unreadNotesCount?: number;
}

export interface FileListObj extends FileMata {
  activated?: boolean;
  checked?: boolean;
}

export interface FileInput {
  reportKey: number;
}

export interface FullUserListInput {
  tenantKey: number;
  includeBK: boolean;
}

enum NotificationType {
  EMAIL,
  SMS,
}

export interface ReportUploadNotifInput {
  senderKey: number;
  userKeys: [number];
  reportKeys: [number];
  type: NotificationType;
  message: string;
  sendAttachments: boolean;
}

export interface Comment {
  commentKey?: number;
  report?: any;
  content?: string;
  attachedStorageFileName?: string;
  attachedFileName?: string;
  user?: User;
  createdAt?: string;
  updatedAt?: string;
  deleted?: boolean;
}

export interface CommentInput {
  content: string;
  attachment?: File;
  mentionedUserKeys: number[];
  commentType: COMMENT_TYPE;
  contentKey?: number;
}

export interface ReportType {
  reportTypeKey?: number;
  name?: string;
  displayName?: string;
  tenantKey?: number;
}

export interface ListCommentsInput {
  commentType: COMMENT_TYPE;
  contentKey: number;
}

export interface ListReportTypesInput {
  tenantKey: number;
}

export enum COMMENT_TYPE {
  FILE_COMMENT = 'FILE_COMMENT',
  FILE_NOTE = 'FILE_NOTE',
  TASK_COMMENT = 'TASK_COMMENT',
  TASK_NOTE = 'TASK_NOTE',
}

export interface BulkDownloadFileInput {
  reportKeys: number[];
}
