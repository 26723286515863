import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SortingType, EmitService, UserService, UtilService } from '@core';
import { User } from '@models';
import { isEqual, unescape } from 'lodash';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { FormsModule } from '@angular/forms';
import { NgIf, NgFor } from '@angular/common';
import { ButtonModule } from '@appkit4/angular-components/button';
import { CheckboxModule } from '@appkit4/angular-components/checkbox';
import { AgGridAngular } from 'ag-grid-angular';
import {
  ColDef,
  GridApi,
  GridReadyEvent,
  GridOptions,
} from 'ag-grid-community';
import { plainToClass } from 'class-transformer';
import { suppressKeyboardEvent } from '@core/ag-grid/ag-grid-option';
import { TableEmptyComponent } from '@shared/component/table-empty/table-empty.component';
import { TableHeaderComponent } from '@shared/component/table-header/table-header.component';
import {
  DEFAULT_LIMIT,
  TablePaginationComponent,
} from '@shared/component/table-pagination/table-pagination.component';
import { TableNoRowsComponent } from '@shared/component/table-no-rows/table-no-rows.component';
import { CheckboxCellComponent } from '@shared/component/table-cells/checkbox-cell/checkbox-cell.component';
import { ToggleModule } from '@appkit4/angular-components/toggle';

interface Type {
  checked: boolean;
  indeterminate: boolean;
  displayName: string;
  emailPreferenceTypeKey: number;
}

@Component({
  selector: 'app-email-notification',
  templateUrl: './email-notification.component.html',
  styleUrls: ['./email-notification.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    NgIf,
    NgFor,
    AgGridAngular,
    ButtonModule,
    CheckboxModule,
    TableEmptyComponent,
    TableHeaderComponent,
    TablePaginationComponent,
    ToggleModule,
  ],
})
export class EmailNotificationComponent implements OnInit, OnDestroy {
  user: User;
  nonce = null;
  limit = DEFAULT_LIMIT;
  offset = 0;
  rowCount = 0;
  types: Type[] = [];
  preferences = [];
  sortDir = SortingType.ASC;
  isUpdated = false;
  originalMaps = [];
  originalWeeklyDigest: boolean;
  weeklyDigest: boolean = false;
  allNotification: boolean = false;
  originalAllNotification: boolean = false;
  private subs: Subscription[] = [];

  isEmpty = true;
  gridOptions: GridOptions = {
    headerHeight: 48,
    rowHeight: 48,
    domLayout: 'autoHeight',
    suppressRowHoverHighlight: true,
    noRowsOverlayComponent: TableNoRowsComponent,
    noRowsOverlayComponentParams: {
      clearAll: () => {},
    },
  };
  defaultColDef: ColDef = {
    cellRenderer: CheckboxCellComponent,
    headerClass: ['ap-typography-body'],
    suppressHeaderMenuButton: true,
    sortable: false,
    filter: false,
    suppressKeyboardEvent: suppressKeyboardEvent.bind(this),
  };
  colDefs: ColDef[] = [];
  private gridApi!: GridApi<any>;

  constructor(
    private readonly userService: UserService,
    private readonly emitService: EmitService,
    private readonly utilService: UtilService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
  ) {}

  onGridReady(params: GridReadyEvent<any>) {
    this.gridApi = params.api;
  }

  ngOnInit(): void {
    this.subs.push(
      this.route.queryParams
        .pipe(
          map((params) => params.nonce),
          switchMap((nonce) => {
            if (nonce) {
              this.nonce = nonce;
              this.router.navigateByUrl('email-notification');
            } else {
              this.emitService.startLoading();
            }
            return combineLatest([
              this.userService.getEmailPreferenceTypes(),
              this.userService.getEmailPreferences({
                nonce: this.nonce,
                sortingType: this.sortDir,
                limit: this.limit,
                offset: 0,
              }),
            ]);
          }),
        )
        .subscribe(([typeRes, preferenceRes]) => {
          this.emitService.endLoading();
          this.types =
            typeRes?.data.map((typeRes) => {
              return {
                ...typeRes,
                checked: false,
                indeterminate: false,
              };
            }) || [];
          this.initPreferences(preferenceRes);
        }),
    );
  }

  getPreferences(offset) {
    this.emitService.startLoading();
    this.offset = offset;
    this.subs.push(
      this.userService
        .getEmailPreferences({
          nonce: this.nonce,
          sortingType: this.sortDir,
          limit: this.limit,
          offset,
        })
        .subscribe((res) => {
          this.emitService.endLoading();
          this.initPreferences(res);
        }),
    );
  }

  initPreferences(res) {
    this.user = plainToClass(User, res.user || ({} as User));
    this.rowCount = res.count;
    this.isEmpty = this.rowCount === 0;
    this.originalMaps = [];
    this.originalWeeklyDigest = res.user?.weeklyDigest;
    this.originalAllNotification = res.user?.allNotification;
    this.weeklyDigest = this.originalWeeklyDigest;
    this.allNotification = this.originalAllNotification;
    this.preferences =
      res.data?.map((item) => {
        let preferenceMap = {};
        this.types.forEach((type) => {
          preferenceMap[type.emailPreferenceTypeKey] = true;
        });
        // preference records those unsubscribed preference type keys
        item.preference?.forEach((key) => (preferenceMap[key] = false));
        this.originalMaps.push({
          ...preferenceMap,
          tenantKey: item.tenant.tenantKey,
        });
        this.isUpdated = false;
        return {
          tenantName: unescape(item.tenant.nme),
          tenantKey: item.tenant.tenantKey,
          preferenceMap,
          checked: false,
          indeterminate: false,
        };
      }) || [];
    this.calculateSelectAllAuto();
    this.utilService.scroll2Target('preferences-table-container');
  }

  initColDefs() {
    this.colDefs = [
      {
        headerName: 'Company',
        headerComponent: TableHeaderComponent,
        field: 'tenantName',
        cellRendererParams: ({ data }) => ({
          label: data.tenantName,
          checked: data.checked,
          indeterminate: data.indeterminate,
          justifyContent: 'start',
          onCheck: () => {
            data.checked = !data.checked;
            this.onCheckCompany(data);
          },
        }),
        width: 160,
        sortable: false,
        pinned: 'left',
      },
    ];
    const widths = [180, 200, 180, 220, 160, 220, 180, 220, 160, 200, 160];
    for (let i = 0; i < this.types.length; i++) {
      const header = this.types[i];
      const mapKey = header.emailPreferenceTypeKey;
      this.colDefs.push({
        headerName: header.displayName,
        headerComponent: TableHeaderComponent,
        headerComponentParams: {
          checkbox: {
            checked: header.checked,
            indeterminate: header.indeterminate,
            onCheck: () => {
              header.checked = !header.checked;
              this.onCheckHeader(header);
            },
          },
        },
        field: 'preferenceMap',
        valueGetter: (p: any) => p.data.preferenceMap[mapKey],
        cellRendererParams: (params) => ({
          checked: params.data.preferenceMap[mapKey],
          onCheck: () => {
            params.data.preferenceMap[mapKey] =
              !params.data.preferenceMap[mapKey];
            this.onCheck();
          },
        }),
        width: widths[i],
        sortable: false,
      });
    }
    this.gridApi.setGridOption('columnDefs', this.colDefs);
    this.gridApi.refreshHeader();
    this.gridApi.refreshCells();
  }

  calculateSelectAllAuto() {
    this.types.forEach((type) => {
      const cells = this.preferences.map((row) => {
        const pMap = row.preferenceMap;
        row.checked = Object.keys(pMap).every((key) => pMap[key]);
        row.indeterminate =
          !row.checked && Object.keys(pMap).some((key) => pMap[key]);
        return pMap[type.emailPreferenceTypeKey];
      });
      type.checked = cells.every((c) => c);
      type.indeterminate = !type.checked && cells.some((c) => c);
    });
    this.allNotification = this.types.every((t) => t.checked);
    this.initColDefs();
    this.checkUpdated();
  }

  sub2All() {
    if (this.allNotification) {
      this.preferences.forEach((row) => {
        row.preferenceMap = {};
        this.types.forEach((type) => {
          row.preferenceMap[type.emailPreferenceTypeKey] = true;
          type.checked = true;
        });
        row.checked = true;
      });
    }
    this.calculateSelectAllAuto();
  }

  onCheckHeader(type: Type) {
    this.preferences.forEach(
      (p) => (p.preferenceMap[type.emailPreferenceTypeKey] = type.checked),
    );
    this.calculateSelectAllAuto();
  }

  onCheck() {
    this.calculateSelectAllAuto();
  }

  onCheckCompany(row) {
    Object.keys(row.preferenceMap).forEach(
      (key) => (row.preferenceMap[key] = row.checked),
    );
    this.calculateSelectAllAuto();
  }

  checkUpdated() {
    const currentMaps = this.preferences.map((p) => ({
      ...p.preferenceMap,
      tenantKey: p.tenantKey,
    }));
    this.isUpdated = !isEqual(
      [this.originalMaps, this.originalWeeklyDigest],
      [currentMaps, this.weeklyDigest],
    );
  }

  onPageChange(page: number) {
    this.getPreferences((page - 1) * this.limit);
  }

  onLimitChange(limit: number) {
    this.limit = limit;
    this.onPageChange(1);
  }

  save() {
    const subscription = [];
    this.preferences.forEach((p) => {
      const preference = Object.keys(p.preferenceMap)
        .filter((key) => !p.preferenceMap[key])
        .map((key) => Number(key));
      subscription.push({ tenantKey: p.tenantKey, preference });
    });
    this.emitService.startLoading();
    this.subs.push(
      this.userService
        .updateEmailPreferences({
          nonce: this.nonce,
          subscription,
          weeklyDigest: this.weeklyDigest,
          allNotification: this.allNotification,
        })
        .subscribe(({ status }) => {
          this.emitService.endLoading();
          if (status === 'SUCCESS') {
            this.utilService.addToastMessage(
              'Notification preferences have been saved.',
            );
            this.getPreferences(this.offset);
          } else {
            this.utilService.addBannerMessage(
              'Notification preferences failed to save.',
            );
          }
        }),
    );
  }

  selectAllNotifs($event: any) {
    const { switchState } = $event;
    this.allNotification = switchState;
    if (switchState) {
      this.sub2All();
    } else {
      this.preferences.forEach((row, i) => {
        row.preferenceMap = {};
        this.types.forEach((type) => {
          row.preferenceMap[type.emailPreferenceTypeKey] = false;
          type.checked = false;
        });
        row.checked = false;
      });
      this.calculateSelectAllAuto();
    }
  }

  canDeactivate(): Observable<boolean> {
    if (this.isUpdated) {
      return this.utilService.unsavedChangeAlert(
        'Cancel Edit Notification Preferences',
      );
    } else {
      return of(true);
    }
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
