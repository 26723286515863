import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { BreadcrumbModule } from '@appkit4/angular-components/breadcrumb';
import { NgxPermissionsModule } from 'ngx-permissions';
import { TooltipModule } from '@appkit4/angular-components/tooltip';

@Component({
  selector: 'app-breadcrumb-cus',
  templateUrl: './breadcrumbCus.component.html',
  styleUrls: ['./breadcrumbCus.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    BreadcrumbModule,
    NgxPermissionsModule,
    TooltipModule,
  ],
})
export class BreadcrumbCusComponent {
  @Input() crumbList = [
    {
      title: 'Example',
      icon: 'icon-folder-closed-outline',
      path: '/',
      // permissions: [], add permissions here
      // onClick: this.customizedOnClick.bind(this), add onClick here
    },
  ];
  constructor(private router: Router) {}
  navigate(item) {
    this.router.navigateByUrl(item.path);
  }
}
