import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RedirectLegalPrivacyGuard {
  constructor(private readonly store: Store, private router: Router) {}
  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | boolean {
    return this.store
      .select((state) => state.user.user)
      .pipe(
        map((user) => {
          if (user) {
            this.router.navigate(['/loggedIn/legal/privacy']);
          }
          return true;
        }),
      );
  }
}
