import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'percentCalc',
    standalone: true
})
export class PercentCalcPipe implements PipeTransform {

  transform(value: any): string | null {
    if (Number.isNaN(value) || value === null || value === undefined) {
      return null;
    }

    const abs = Math.min(10, Math.abs(value));
    return `${value >= 0 ? '' : '-'}${Math.round(abs * 100)}%`;
  }

}
