import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgIf } from '@angular/common';
import { ButtonModule } from '@appkit4/angular-components/button';
import { PanelModule } from '@appkit4/angular-components/panel';

@Component({
  selector: 'app-table-empty',
  templateUrl: './table-empty.component.html',
  styleUrls: ['./table-empty.component.scss'],
  standalone: true,
  imports: [FormsModule, NgIf, ButtonModule, PanelModule],
})
export class TableEmptyComponent {
  @Input() tip: string = '';
  @Input() label: string = '';
  @Input() disableCreateNewBtn = false;
  @Input() secondaryLabel: string = '';
  @Output() createNew: EventEmitter<number> = new EventEmitter();
  @Output() createAnother: EventEmitter<number> = new EventEmitter();
}
