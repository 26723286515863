import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  standalone: true,
  selector: 'app-validation-message',
  templateUrl: './validation-message.component.html',
  styleUrls: ['./validation-message.component.scss'],
  imports: [CommonModule],
})
export class ValidationMessageComponent {
  @Input() control: AbstractControl;
  @Input() condition: boolean;
  @Input() name: string;
  @Input() message: string;
}
