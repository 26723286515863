<ap-panel [bordered]='true' [styleClass]="'table-empty-panel ap-mt-spacing-6'">
  <div class="flex-normal flex-justify-center align-items-center wrapper">
    <div>
      <img src="./assets/images/empty-templates.svg" alt="empty table">
      <div class="ap-typography-heading-m ap-mt-spacing-5 ap-mb-spacing-6 text-center" role="alert">{{tip}}</div>
      <div class="flex-normal flex-justify-center flex-direction-column align-items-center flex-gap-5">
        <ap-button *ngIf="label" [btnType]="'primary'" [icon]="'plus-outline'" [label]="label"
          [disabled]="disableCreateNewBtn" (onClick)="createNew.emit()">
        </ap-button>
        <ap-button *ngIf="secondaryLabel" [btnType]="'secondary'" [label]="secondaryLabel"
          (onClick)="createAnother.emit()">
        </ap-button>
      </div>
    </div>
  </div>
</ap-panel>
