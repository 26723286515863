import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router, RouterOutlet, Scroll } from '@angular/router';
import { filter } from 'rxjs';
import { FooterComponent } from '../footer/footer.component';
import { StyleControlService } from '@core/services/style-control.service';
import { EncapsulatedBannerComponent } from '../encapsulated-banner/encapsulated-banner.component';
@Component({
  selector: 'app-pre-login-container',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    FooterComponent,
    EncapsulatedBannerComponent,
  ],
  templateUrl: './pre-login-container.component.html',
  styleUrls: ['./pre-login-container.component.scss'],
})
export class PreLoginContainerComponent implements OnInit {
  showPositionContainer: boolean = true;
  constructor(
    private router: Router,
    private readonly styleControlService: StyleControlService,
  ) {}

  ngOnInit(): void {
    this.router.events
      .pipe(filter((event) => event instanceof Scroll))
      .subscribe((event: Scroll) => {
        const { routerEvent } = event;
        this.showPositionContainer = !routerEvent.url.includes('register');
      });
  }
}
