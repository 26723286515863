import { Component } from '@angular/core';
import { ButtonModule } from '@appkit4/angular-components/button';
import { DynamicDialogService } from '@appkit4/angular-components/modal';

export const GENERAL_MODAL_ID = 'generalModal';

@Component({
  selector: 'app-general-modal',
  templateUrl: './general-modal.component.html',
  styleUrls: ['./general-modal.component.scss'],
  standalone: true,
  imports: [ButtonModule],
})
export class GeneralModalComponent {
  labelStr: string = 'Continue';
  primaryBtnType: string = 'primary';
  title: string = '';
  content: string = 'You have unsaved changes. Are you sure you want to continue?';
  extraLabel: string = null;
  constructor(private readonly dynamicDialogService: DynamicDialogService) {}

  cancel() {
    this.dynamicDialogService.closeModal(GENERAL_MODAL_ID);
  }

  extra() {
    this.dynamicDialogService.closeModal(GENERAL_MODAL_ID, 'extra');
  }

  continue() {
    this.dynamicDialogService.closeModal(GENERAL_MODAL_ID, 'continue');
  }
}
