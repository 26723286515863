import gql from 'graphql-tag';

export const tenantFragment = gql`
  fragment tenantFragment on Tenant {
    tenantKey
    nme
  }
`;

export enum MonthEnum {
  JANUARY = 'JANUARY',
  FEBRUARY = 'FEBRUARY',
  MARCH = 'MARCH',
  APRIL = 'APRIL',
  MAY = 'MAY',
  JUNE = 'JUNE',
  JULY = 'JULY',
  AUGUST = 'AUGUST',
  SEPTEMBER = 'SEPTEMBER',
  OCTOBER = 'OCTOBER',
  NOVEMBER = 'NOVEMBER',
  DECEMBER = 'DECEMBER',
}

export interface TimeGroupType {
  year?: number;
  months?: MonthEnum[];
}

export const healthCheck = gql`
  query healthCheck {
    healthCheck {
      status
      message
    }
  }
`;

export interface Industry {
  industryKey: number;
  name: string;
}

export interface DropdownListInput {
  value: any;
  label: string;
  disabled?: boolean;
  descValue?: string;
  iconName?: string;
  badgeValue?: string;
}
