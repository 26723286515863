import { Pipe, PipeTransform } from '@angular/core';
import { unescape } from 'lodash';

@Pipe({
    name: 'decodeString',
    standalone: true
})
export class DecodeStringPipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): unknown {
    return unescape(value);
  }
}
