import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingModule} from '@appkit4/angular-components/loading';

@Component({
    selector: 'app-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.scss'],
    standalone: true,
    imports: [CommonModule, LoadingModule]
})
export class SpinnerComponent {
    @Input() styleClass: string;
}
