import gql from 'graphql-tag';

export const sendNotification = gql`
  mutation sendNotification($notificationInput: NotificationInput) {
    sendNotification(input:$notificationInput){
      status
      message
    }
  }
`;

export interface NotificationInput {
    tenantKey: number;
    message: string;
  }

export interface NotificationVariable {
  notificationInput: NotificationInput;
}
