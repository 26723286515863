import gql from 'graphql-tag';

export const getBankFeeds = gql`
  query getBankFeeds($bankFeedQueryInput: BankFeedQueryInput) {
    getBankFeeds(input: $bankFeedQueryInput) {
      status
      count
      data {
        bankFeedKey
        bankName
        description
        amount
        categoryOrMatch
        memo
        selectCategory {
          accountKey
          nme
          accountNbr
        }
        tenantKey
        transactionDate
        action
        bankDetail
        hashBankDetail
        transactionType
        vendor {
          vendorKey
          name
          displayName
          type
        }
      }
    }
  }
`;

export const getChartsOfAccount = gql`
  query getChartsOfAccount($chartsOfAccountInput: COAQueryInput) {
    getChartsOfAccount(input: $chartsOfAccountInput) {
      status
      message
      count
      unconfirmedCount
      data {
        accountKey
        accountNbr
        nme
        industry
        customerName
        accountTags {
          name
          displayName
          accountTagKey
        }
        originalType
        modifiedType
        status
        parentNbr
        parentNme
        typeDetails
        lastSyncedAt
        accountType
      }
    }
  }
`;

export const getClasses = gql`
  query getClasses($tenantKey: Int!) {
    getClasses(tenantKey: $tenantKey) {
      status
      message
      data {
        classKey
        name
        description
        tenantKey
      }
    }
  }
`;

export const submitBankFeed = gql`
  mutation submitBankFeed($bankFeedInput: [BankFeedInput]) {
    submitBankFeed(input: $bankFeedInput) {
      status
      message
      conflictCount
      data {
        tenantKey
        bankFeedKey
        transactionDate
        amount
        bankName
        description
        selectCategory {
          accountKey
          nme
          accountNbr
        }
        memo
        categoryOrMatch
      }
    }
  }
`;

export interface BankFeedQueryInput {
  orgGroupId?: string;
  tenantKey: number;
  limit?: number;
  offset?: number;
  status: BankFeedQueryStatus;
}

export interface BankFeedVariable {
  bankFeedQueryInput: BankFeedQueryInput;
}

export interface BankFeedInput {
  bankFeedKey: number;
  status: BankFeedSubmitStatus;
  accountKey?: number;
  classKey?: number;
  vendorKey?: number;
  customerProjectKey?: number;
  memo?: string;
}

export interface BankFeedFormVariable {
  bankFeedInput?: Array<BankFeedInput>;
}

export interface ChartsOfAccountInput {
  tenantKeys?: number[];
  limit?: number;
  offset?: number;
  customerName?: string;
  industryKey?: number;
  orderBy?: string;
  sortingType?: string;
}

export interface ClassVariable {
  tenantKey?: number;
}

export enum BankFeedSubmitStatus {
  awaiting = 'AWAITING_CUSTOMER_ACTION',
  modified = 'MODIFIED',
}

export enum BankFeedQueryStatus {
  new = 'NEW',
  awaiting = 'AWAITING_CUSTOMER_ACTION',
}
