import gql from 'graphql-tag';

export const getEmailTemplates = gql`
  query listEmailTemplates($input: ListEmailTemplateInput) {
    listEmailTemplates(input: $input) {
      status
      count
      data {
        templateKey
        displayName
        owner {
          avatar
          email
          firstNme
          lastNme
        }
        isActive
        updatedAt
      }
    }
  }
`;

export const switchTemplateStatus = gql`
  mutation switchTemplateStatus($input: SwitchTemplateStatusInput) {
    switchTemplateStatus(input: $input) {
      status
      message
    }
  }
`;

export const copyEmailTemplate = gql`
  mutation copyEmailTemplate($input: CopyEmailTemplateInput) {
    copyEmailTemplate(input: $input) {
      status
      message
    }
  }
`;

export const getEmailTemplate = gql`
  query getEmailTemplate($templateKey: Int) {
    getEmailTemplate(templateKey: $templateKey) {
      message
      status
      data {
        templateKey
        displayName
        subject
        body
        theme
        ownerKey
        emailAttachments {
          emailAttachmentKey
          fileName
        }
      }
    }
  }
`;

export const getEmailAttachment = gql`
  query getEmailAttachment($emailAttachmentKey: Int!) {
    getEmailAttachment(emailAttachmentKey: $emailAttachmentKey) {
      message
      status
      data {
        emailAttachmentKey
        fileName
        attachment  {
          name
          base64Content
        }
      }
    }
  }
`;

export const createEmailTemplate = gql`
  mutation createEmailTemplate($input: CreateEmailTemplateInput) {
    createEmailTemplate(input: $input) {
      status
      message
    }
  }
`;

export const updateEmailTemplate = gql`
  mutation updateEmailTemplate($input: UpdateEmailTemplateInput) {
    updateEmailTemplate(input: $input) {
      status
      message
    }
  }
`;

export interface UpdateEmailTemplateInput {
  templateKey: number;
  displayName: string;
  subject?: string;
  body?: string;
  theme?: string;
  ownerKey: number;
  newAtchs?: any[];
  atchKeysToBeRmvd?: number[];
}

export interface CreateEmailTemplateInput {
  displayName: string;
  subject?: string;
  body?: string;
  theme?: string;
  ownerKey: number;
  emailAttachments?: any[];
}
