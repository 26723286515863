import gql from 'graphql-tag';

export const getIndustries = gql`
  query listIndustry {
    listIndustry {
      status
      message
      data {
        industryKey
        name
      }
    }
  }
`;

export const listTags = gql`
  query listTags($accountTagSearchInput: AccountTagSearchInput) {
    listTags(input: $accountTagSearchInput) {
      status
      message
      data {
        name
        displayName
        accountTagKey
      }
    }
  }
`;

export const mapAccountTag = gql`
  mutation mapAccountTag($mapAccountTagInput: [MapAccountTagInput]!) {
    mapAccountTag(input: $mapAccountTagInput) {
      status
      message
      data {
        name
        displayName
        accountTagKey
      }
    }
  }
`;

export const addAccountTag = gql`
  mutation addAccountTag($accountTagInput: AccountTagInput) {
    addAccountTag(input: $accountTagInput) {
      status
      message
    }
  }
`;

export const removeAccountTagMapping = gql`
  mutation removeAccountTagMapping($mapAccountTagInput: MapAccountTagInput) {
    removeAccountTagMapping(input: $mapAccountTagInput) {
      status
      message
    }
  }
`;

export const createAndMapAccountTag = gql`
  mutation createAndMapAccountTag($accountTagInput: AccountTagInput) {
    createAndMapAccountTag(input: $accountTagInput) {
      status
      message
    }
  }
`;

export const updateAccountType = gql`
  mutation updateAccountType($updatedAccountType: UpdatedAccountType) {
    updateAccountType(input: $updatedAccountType) {
      status
      message
    }
  }
`;

export const confirmAllAccountType = gql`
  mutation confirmAllAccountType($confirmAllTypes: ConfirmAllTypesInput) {
    confirmAllAccountType(input: $confirmAllTypes) {
      status
      message
    }
  }
`;

export interface ChartsOfAccount {
  accountKey: number;
  accountNbr: string;
  nme: string;
  industry: string;
  customerName: string;
  accountTags: AccountTag[];
  tagInput?: string;
}

export interface AccountTag {
  name: string;
  displayName?: string;
  accountTagKey: number;
}

export interface AccountTagInput {
  name: string;
  nextHubAccountKey: number;
}

export interface AccountTagSearchInput {
  name: string;
}

export interface MapAccountTagInput {
  nextHubAccountKey: number;
  accountTagKey: number;
}

export enum BasicAccountType {
  Asset = 'Asset',
  Liability = 'Liability',
  Revenue = 'Revenue',
  Expense = 'Expense',
  Equity = 'Equity',
}

export enum AccountTypeStatus {
  CONFIRMED = 'CONFIRMED',
  DENIED = 'DENIED',
}

export interface UpdatedAccountType {
  nextHubAccountKey: number;
  modifiedType: BasicAccountType;
  status: AccountTypeStatus;
}
