import { Injectable } from '@angular/core';
import { GqlService } from '@core';
import {
  FileInput,
  FileUploadInput,
  ListFileInput,
  FullUserListInput,
  ReportUploadNotifInput,
  FileListResponse,
  downloadFile,
  listFiles,
  uploadReportFile,
  getFullUserList,
  getNotifiedUsers,
  getVisibleUsers,
  sendReportUploadNotification,
  CommentInput,
  saveComment,
  listComments,
  deleteReportFile,
  getCommentAttachment,
  listReportTypes,
  ListCommentsInput,
  getTenantName,
  updateFile,
  updateFileAccess,
  ListReportTypesInput,
  BulkDownloadFileInput,
  bulkDownloadFile,
  FileAttributesInput,
  updateFileAttributes,
} from '@core/gql/reports.gql';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ReportsService {
  constructor(private readonly gqlService: GqlService) {}

  uploadReport(fileUploadInput: FileUploadInput) {
    return this.gqlService.mutate(
      uploadReportFile,
      { fileUploadInput },
      {
        useMultipart: true,
      },
    );
  }

  updateFileAttributes(input: FileAttributesInput[]) {
    return this.gqlService.mutate(updateFileAttributes, { input });
  }

  listFiles(input: ListFileInput): Observable<FileListResponse> {
    const variables = { listFileInput: { ...input } };
    return this.gqlService.query(listFiles, variables);
  }

  downloadFile(input: FileInput) {
    const variables = { fileInput: { ...input } };
    return this.gqlService.query(downloadFile, variables);
  }

  bulkDownloadFile(bulkDownloadFileInput: BulkDownloadFileInput) {
    return this.gqlService.query(bulkDownloadFile, { bulkDownloadFileInput });
  }

  getUserList(input: FullUserListInput) {
    const variables = { ...input };
    return this.gqlService.query(getFullUserList, variables);
  }

  getNotifiedUsers(tenantKey: number, reportKeys: number[]) {
    return this.gqlService.query(getNotifiedUsers, { tenantKey, reportKeys });
  }

  getVisibleUsers(tenantKey: number, reportKey: number) {
    return this.gqlService.query(getVisibleUsers, { tenantKey, reportKey });
  }

  sendReportUploadNotification(input: ReportUploadNotifInput) {
    const variables = { reportUploadNotifInput: { ...input } };
    return this.gqlService.query(sendReportUploadNotification, variables);
  }
  listComments(listCommentsInput: ListCommentsInput) {
    return this.gqlService.query(listComments, { ...listCommentsInput });
  }
  saveComment(input: CommentInput) {
    const variables = { commentInput: { ...input } };
    return this.gqlService.mutate(saveComment, variables, {
      useMultipart: true,
    });
  }

  getCommentAttachment(commentKey: number): Observable<any> {
    return this.gqlService.query(getCommentAttachment, { commentKey });
  }
  listReportTypes(input: ListReportTypesInput) {
    return this.gqlService.query(listReportTypes, { input });
  }
  deleteReport(reportKey: number) {
    return this.gqlService.mutate(deleteReportFile, { reportKey });
  }
  getTenantName(tenantKey) {
    return this.gqlService.query(getTenantName, { tenantKey });
  }
  updateFile(reportKey: number, visibility: boolean) {
    return this.gqlService.mutate(updateFile, { reportKey, visibility });
  }
  updateFileAccess(reportKey: number, userKeys: number[]) {
    return this.gqlService.mutate(updateFileAccess, { reportKey, userKeys });
  }
}
