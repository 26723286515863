import { Injectable } from '@angular/core';
import {
  CommentInput,
  getCommentAttachment,
  GqlService,
  listComments,
  ListCommentsInput,
  saveComment,
  getUsersForComment,
} from '@core';
import { markNotification } from '@core/gql/notifications.gql';
import { getUsersInTaskList } from '@core/gql/task.gql';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CommentsNotesService {
  constructor(private readonly gqlService: GqlService) {}
  listComments(listCommentsInput: ListCommentsInput) {
    return this.gqlService.query(listComments, { listCommentsInput });
  }
  markNotification(listCommentsInput: ListCommentsInput) {
    return this.gqlService.mutate(markNotification, { markNotificationInput: { ...listCommentsInput } });
  }
  getUsersInTaskList(tenantKey: number) {
    return this.gqlService.query(getUsersInTaskList, { tenantKey });
  }
  getUsersForComment(tenantKey: number, reportKey: number) {
    return this.gqlService.query(getUsersForComment, { tenantKey, reportKey });
  }
  getCommentAttachment(commentKey: number): Observable<any> {
    return this.gqlService.query(getCommentAttachment, { commentKey });
  }
  saveComment(input: CommentInput) {
    const variables = { commentInput: { ...input } };
    return this.gqlService.mutate(saveComment, variables, { useMultipart: true });
  }
}
