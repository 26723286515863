import { Injectable } from '@angular/core';
import { GqlService } from '@core';
import {
  listNotifications,
  markNotification,
  ListNotifInput,
  MarkNotificationInput
} from '@core/gql/notifications.gql';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {

  unreadCount = 0;
  
  constructor(private gqlService: GqlService) {}

  getNotifications(listNotifInput: ListNotifInput) {
    return this.gqlService.query(listNotifications, { listNotifInput });
  }

  markNotification(markNotificationInput: MarkNotificationInput) {
    return this.gqlService.mutate(markNotification, { markNotificationInput });
  }
}
