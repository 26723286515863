import { Pipe, PipeTransform } from '@angular/core';
import { NOTIFICATION_TYPE } from '@core/gql/notifications.gql';

@Pipe({
    name: 'notificationType',
    standalone: true
})
export class NotificationTypePipe implements PipeTransform {
  transform(value: any, ...args: unknown[]): unknown {
    switch (value) {
      case NOTIFICATION_TYPE.QUICK_LINK:
        return 'Quick Link';
      case NOTIFICATION_TYPE.CUSTOMER_CHANGED:
        return 'Customer';
      case NOTIFICATION_TYPE.TASKS:
        return 'Task';
      case NOTIFICATION_TYPE.FILE_COMMENTS:
      case NOTIFICATION_TYPE.TASK_COMMENTS:
        return 'Comment';
      case NOTIFICATION_TYPE.FILE_NOTES:
      case NOTIFICATION_TYPE.TASK_NOTES:
        return 'Note';
      default:
        return null;
    }
  }
}
