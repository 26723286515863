import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'notAvailable',
    standalone: true
})
export class NotAvailablePipe implements PipeTransform {

  transform(value: any): any {
    return !value && value !== 0 ? 'N/A' : value;
  }

}
