import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'decodeUri',
    standalone: true
})
export class DecodeUriPipe implements PipeTransform {
  transform(value: string): unknown {
    return decodeURIComponent(value);
  }
}
