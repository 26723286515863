import { Pipe, PipeTransform } from '@angular/core';
import { NumberToDaywithLastPipe } from './number-to-day-with-last';

@Pipe({
  name: 'numberToDayWithNA',
  standalone: true,
})
export class NumberToDayWithNAPipe implements PipeTransform {
  transform(value: number): string {
    if (!value) {
      return 'N/A';
    }
    return `${value}${new NumberToDaywithLastPipe().nth(value)}`;
  }

  getDayArray(month): number[] {
    const dayCount = this.getDaysInMonth(new Date().getFullYear(), month);
    const days = [];
    for (let i = 1; i <= dayCount; i++) {
      days.push(i);
    }
    days.push(null);
    return days;
  }

  getDaysInMonth(year, month) {
    return new Date(year, month, 0).getDate();
  }
}

export const numberToDayWithNA = (value: number): string => {
  if (!value) {
    return 'N/A';
  }
  return `${value}${new NumberToDaywithLastPipe().nth(value)}`;
};
