import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AuthService } from '@core';
import { interval, Observable } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';
import { AsyncPipe } from '@angular/common';
import { DynamicDialogService } from '@appkit4/angular-components/modal';
import { InfoModalComponent } from '@shared/component/modal/info-modal/info-modal.component';

export const TIMEOUT_DIALOG_MODAL_ID = 'timeoutDialogModal';
@Component({
  selector: 'app-timeout-dialog',
  templateUrl: './timeout-dialog.component.html',
  styleUrls: ['./timeout-dialog.component.scss'],
  standalone: true,
  imports: [InfoModalComponent, CommonModule, AsyncPipe],
})
export class TimeoutDialogComponent implements OnInit {
  countdown$: Observable<string>;

  constructor(
    private readonly dynamicDialogService: DynamicDialogService,
    private readonly authService: AuthService,
  ) {}

  ngOnInit() {
    const start = 60;
    this.countdown$ = interval(1000).pipe(
      startWith(0),
      map((i) => start - i - 1),
      tap((i) => {
        if (i === 0) {
          this.authService.timeout();
        }
      }),
      map((i) => Math.max(0, i)),
      map((i) => (i < 10 ? `0${i}` : `${i}`)),
    );
  }

  logout() {
    this.dynamicDialogService.closeModal(TIMEOUT_DIALOG_MODAL_ID, 'logout');
  }

  close() {
    this.dynamicDialogService.closeModal(TIMEOUT_DIALOG_MODAL_ID, 'refresh');
  }
}
