<app-info-modal title="No recent activity" cancelText="Log out" saveText="Extend Session" [hasBadge]="true"
  btnType="primary" (save)="close()" (cancel)="logout()">
  <p>
    Your session is about to expire. You will be automatically logged out.
  </p>
  <div class="ap-mt-spacing-5 ap-typography-heading-s app-text-heading">
    Time Remaining
  </div>
  <div class="ap-mt-spacing-3 ap-typography-heading-m app-text-heading ap-font-weight-3">
    00:{{ countdown$ | async }}
  </div>
</app-info-modal>