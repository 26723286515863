import { SortingType } from '@core';
import gql from 'graphql-tag';

export const getAssignedUnmatchedTransactions = gql`
  query getAssignedUnmatchedTransactions(
    $unmatchedTransactionsQueryInput: UnmatchedTransactionsQueryInput
  ) {
    getAssignedUnmatchedTransactions(input: $unmatchedTransactionsQueryInput) {
      status
      message
      count
      data {
        tenant {
          tenantKey
          nme
          orgStatus
        }
        unMatchedTransactionStats {
          active
          await
        }
        lastUpdatedAt
      }
    }
  }
`;

export const getTenantsNameForUser = gql`
  query getTenantsForUser($input: GetTenantsForUserInput) {
    getTenantsForUser(input: $input) {
      count
      data {
        tenant {
          nme
        }
      }
    }
  }
`;

export const authorizeConnection = gql`
  mutation authorizeConnection(
    $tenantKey: Int
    $connection: ConnOptions
    $redirectUri: String
  ) {
    authorizeConnection(
      tenantKey: $tenantKey
      connection: $connection
      redirectUri: $redirectUri
    ) {
      redirectUri
    }
  }
`;

export const createConnection = gql`
  mutation createConnection($redirectUri: String!) {
    createConnection(redirectUri: $redirectUri) {
      status
      message
    }
  }
`;

export const unauthorizeConnection = gql`
  mutation unauthorizeConnection($tenantKey: Int, $connection: String) {
    unauthorizeConnection(tenantKey: $tenantKey, connection: $connection) {
      status
      lastUpdatedAt
      lastSyncedAt
    }
  }
`;

export const hierarchicalTenantsFragment = gql`
  fragment hierarchicalTenantsFragment on Tenant {
    tenantKey
    createdAt
    nme
    entityType
    accountType
    calendarYear
    assigned
    lineage
    onboardingStatus
    orgStatus
    orgStatusUpdatedAt
    contractBilling {
      billingRepeat
      closeDueRepeat
      billingMonth
      closeDueMonth
      billingStartFrom
      closeDueStartFrom
      frequency
      monthlyCloseDue
      signedDate
      contractRenewal
      fee
      arr
    }
    expansionArrs {
      expansionArrKey
      arr
      comment
    }
    teamLead {
      firstNme
      lastNme
      bio
      avatar
      email
    }
    reviewer {
      firstNme
      lastNme
      bio
      avatar
      email
    }
    integrationTenants {
      lastSyncedAt
      status
    }
    preonboardingChart {
      newCount
      openCount
      closedCount
      pastDueCount
      totalCount
    }
    onboardingChart {
      newCount
      openCount
      closedCount
      pastDueCount
      totalCount
    }
    bookkeepingChart {
      newCount
      openCount
      closedCount
      pastDueCount
      totalCount
    }
    miscellaneousChart {
      newCount
      openCount
      closedCount
      pastDueCount
      totalCount
    }
    taskCount
    industry {
      industryKey
      name
    }
    primaryUser {
      firstNme
      lastNme
      email
      phone
    }
    monthEndClose {
      dueDate
      closedAt
      previousClosedAt
    }
    deliverableSent {
      dueDate
      closedAt
      createdAt
    }
  }
`;

export const hierarchicalTenantsDetailsFragment = gql`
  fragment hierarchicalTenantsDetailsFragment on Tenant {
    tenantKey
    nme
    entityType
    accountType
    calendarYear
    onboardingStatus
    orgStatus
    assigned
    contractBilling {
      billingRepeat
      closeDueRepeat
      billingMonth
      closeDueMonth
      billingStartFrom
      closeDueStartFrom
      frequency
      monthlyCloseDue
      signedDate
      contractRenewal
      fee
      arr
      billingWbsCode
    }
    expansionArrs {
      expansionArrKey
      arr
      comment
    }
    teamLead {
      firstNme
      lastNme
      bio
      avatar
      email
    }
    reviewer {
      firstNme
      lastNme
      bio
      avatar
      email
    }
    industry {
      industryKey
      name
    }
    adminEmail
    primaryUser {
      firstNme
      lastNme
      email
      phone
      busiFuncNm
      sendInvitation
      aliasEmail
    }
    parentTenant {
      tenantKey
      nme
    }
    address {
      addressKey
      address
      addressAddition
      city
      state
      zip
      timeZone
    }
    yearEndClose
    visibleBKs
    invisibleBKs
    saNames
    showedInterestIn
    addtlComments
    partners {
      partnerInfoKey
      firstNme
      lastNme
      serviceNme
    }
    generalLedger
    payBy
    tenantAttachments {
      fileName
    }
    timeChargeWbsCode
  }
`;

export const listHierarchicalTenants = gql`
  ${hierarchicalTenantsFragment}
  query listHierarchicalTenants(
    $listHierarchicalTenantsInput: ListHierarchicalTenantsInput
  ) {
    listHierarchicalTenants(input: $listHierarchicalTenantsInput) {
      status
      message
      count
      activeCount
      deactivatedCount
      data {
        ...hierarchicalTenantsFragment
        subentities {
          parent
          ...hierarchicalTenantsFragment
          subentities {
            parent
            ...hierarchicalTenantsFragment
            subentities {
              parent
              ...hierarchicalTenantsFragment
              subentities {
                parent
                ...hierarchicalTenantsFragment
                subentities {
                  tenantKey
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const listAllHierarchicalTenants = gql`
  ${hierarchicalTenantsDetailsFragment}
  query listAllHierarchicalTenants($input: ListAllHierarchicalTenantsInput) {
    listAllHierarchicalTenants(input: $input) {
      status
      message
      count
      activeCount
      deactivatedCount
      data {
        ...hierarchicalTenantsDetailsFragment
        subentities {
          parent
          ...hierarchicalTenantsDetailsFragment
          subentities {
            parent
            ...hierarchicalTenantsDetailsFragment
            subentities {
              parent
              ...hierarchicalTenantsDetailsFragment
              subentities {
                parent
                ...hierarchicalTenantsDetailsFragment
                subentities {
                  tenantKey
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const assignedTenantsFragment = gql`
  fragment assignedTenantsFragment on Tenant {
    tenantKey
    nme
    entityType
    assigned
    lineage
    industry {
      industryKey
      name
    }
    entityType
  }
`;

export const listHierarchicalAssignedTenants = gql`
  ${assignedTenantsFragment}
  query listHierarchicalAssignedTenants {
    listHierarchicalAssignedTenants {
      status
      message
      data {
        ...assignedTenantsFragment
        subentities {
          ...assignedTenantsFragment
          subentities {
            ...assignedTenantsFragment
            subentities {
              ...assignedTenantsFragment
              subentities {
                ...assignedTenantsFragment
                subentities {
                  tenantKey
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const updateCustomerOnboardStatus = gql`
  mutation updateCustomerOnboardStatus($input: OnBoardingStatusInput!) {
    updateCustomerOnboardStatus(input: $input) {
      status
      message
    }
  }
`;

export const showTenantStage = gql`
  query showTenantStage($input: ShowTenantStageInput) {
    showTenantStage(input: $input) {
      status
      message
      data {
        tenantKey
        createdAt
        onboardingStatus
        onboardingStatusUpdatedAt
        ratifyMonthlyTasks
        monthEndClose {
          dueDate
          closedAt
          previousClosedAt
        }
        deliverableSent {
          dueDate
          closedAt
          createdAt
        }
        tenantStage {
          firstMonthEndClose
          previousMonthEndClose
          nextMonthEndClose
          lastMonthEndClose
        }
      }
    }
  }
`;

export interface UnmatchedTransactionsQueryInput {
  tenantKeys?: number[];
  filterType?: TransFilterType;
  limit?: number;
  offset?: number;
  orderBy?: string;
  sortingType?: string;
  searchText?: string;
}

export interface UnmatchedTransactionsVariable {
  unmatchedTransactionsQueryInput: UnmatchedTransactionsQueryInput;
}

export enum TransFilterType {
  ALL = 'ALL',
  ACTIVE = 'ACTIVE',
  DEACTIVATED = 'DEACTIVATED',
}

export interface ListHierarchicalTenantsInput {
  searchText: string;
  dateMarker?: Date | string;
  filterType: HierarchicalTenantsFilterType;
  orderBy: HierarchicalTenantsSortingType;
  sortingType: SortingType;
  offset: number;
  limit: number;
  mutualFilters?: MutualFilter[];
}

export enum HierarchicalTenantsFilterType {
  ALL = 'ALL',
  ACTIVE = 'ACTIVE',
  DEACTIVATED = 'DEACTIVATED',
}

export enum HierarchicalTenantsSortingType {
  TIMESTAMP = 'TIMESTAMP',
  CUSTOMER = 'CUSTOMER',
  INDUSTRY = 'INDUSTRY',
  ENTITY_TYPE = 'ENTITY_TYPE',
  STAGE = 'STAGE',
  STATUS = 'STATUS',
  ONBOARDING = 'ONBOARDING_PROGRESS',
  GENERAL_LEDGER = 'GENERAL_LEDGER',
  TEAM_LEAD = 'TEAM_LEAD',
  REVIEWER = 'REVIEWER',
  PERIOD = 'PERIOD',
  ACCOUNT_BASIS = 'ACCOUNT_BASIS',
  BILLING_DUE = 'BILLING_DUE',
  MONTHLY_CLOSE_DUE = 'MONTHLY_CLOSE_DUE',
  DEACTIVATION_DATE = 'DEACTIVATION_DATE',
}

export enum ONBOARDING_STATUS_TYPE {
  ALL = 'ALL',
  ONBOARDING = 'ONBOARDING',
  PREONBOARDING = 'PREONBOARDING',
  BOOKKEEPING = 'BOOKKEEPING',
}

export const StatusList = [
  {
    name: ONBOARDING_STATUS_TYPE.ALL,
    displayName: 'All',
  },
  {
    name: ONBOARDING_STATUS_TYPE.PREONBOARDING,
    displayName: 'Pre-onboarding',
  },
  {
    name: ONBOARDING_STATUS_TYPE.ONBOARDING,
    displayName: 'Onboarding',
  },
  {
    name: ONBOARDING_STATUS_TYPE.BOOKKEEPING,
    displayName: 'Bookkeeping',
  },
];

export const IndustryList = [
  {
    industryKey: 1,
    name: 'ALL',
    displayName: 'All',
  },
];

export enum MutualFilterType {
  INDUSTRY = 'INDUSTRY',
  TEAM_LEAD = 'TEAM_LEAD',
  REVIEWER = 'REVIEWER',
  ONBOARDING_STATUS = 'ONBOARDING_STATUS',
}

export enum TenantOrderType {
  NAME = 'NAME',
  ROLE = 'ROLE',
  BUSINESS_FUNCTION = 'BUSINESS_FUNCTION',
  LAST_UPDATED_AT = 'LAST_UPDATED_AT',
}

export interface MutualFilter {
  type: MutualFilterType;
  key?: number;
  name?: string;
  status?: ONBOARDING_STATUS_TYPE;
}

export interface OnBoardingStatusInput {
  tenantKey: number;
  status: ONBOARDING_STATUS_TYPE;
}

export interface OnBoardingStatusType {
  label: string;
  value: ONBOARDING_STATUS_TYPE;
}

export interface TaskCountChart {
  newCount?: number;
  openCount?: number;
  closedCount?: number;
  pastDueCount?: number;
  totalCount?: number;
}

export interface MonthEndClosePeriod {
  dueDate: Date;
  closedAt: Date; // current month
  previousClosedAt: Date; // previous month
}

export interface TenantStagePeriod {
  firstMonthEndClose: Date;
  previousMonthEndClose: Date;
  nextMonthEndClose: Date;
  lastMonthEndClose: Date;
}

export interface DeliverableSentPeriod {
  dueDate: Date;
  closedAt: Date;
  createdAt: Date;
}

export interface TenantStage {
  tenantKey: number;
  createdAt: Date;
  onboardingStatus: String;
  onboardingStatusUpdatedAt: Date;
  ratifyMonthlyTasks: Boolean;
  monthEndClose: MonthEndClosePeriod;
  deliverableSent: DeliverableSentPeriod;
  tenantStage: TenantStagePeriod;
}

export interface ShowTenantStageInput {
  tenantKey: number;
  timestamp: Date;
}

export interface StagesBlock {
  status: String;
  closeDate?: Date;
  expectedDate?: Date;
  datePeriod?: number;
}
