import { CommonModule, DatePipe } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from '@appkit4/angular-components/button';
import { FieldModule } from '@appkit4/angular-components/field';
import { TooltipModule } from '@appkit4/angular-components/tooltip';
import { EmitService, LayoutService, UtilService } from '@core';
import { CommentsNotesService } from '@core/services/comments-notes.service';
import { TruncateLongNameDirective } from '../../../directive/truncate-directive/truncate-long-name.directive';
import { DecodeUriPipe } from '../../../pipe/decode-string/decode-uri.pipe';
import { CommentsNotesBaseComponent } from '../comments-notes.base';
import { ValidationMessageComponent } from '../../validation-message/validation-message.component';
import { AvatarComponent } from '@shared/component/appkit4-avatar/avatar.component';
import { TextEditorModule } from '@appkit4/angular-text-editor/text-editor';

@Component({
  selector: 'app-notes-panel',
  templateUrl: './notes-panel.component.html',
  styleUrls: ['./notes-panel.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    CommonModule,
    TruncateLongNameDirective,
    DatePipe,
    DecodeUriPipe,
    ButtonModule,
    FieldModule,
    TooltipModule,
    ButtonModule,
    ValidationMessageComponent,
    AvatarComponent,
    TextEditorModule
  ],
})
export class NotesPanelComponent
  extends CommentsNotesBaseComponent
  implements OnInit, AfterViewInit
{
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('commentsContainer') commentsContainerElement: ElementRef;
  commentHeight = 0;
  text = '';
  isFocused = false;

  constructor(
    protected commentsNotesService: CommentsNotesService,
    protected readonly layoutService: LayoutService,
    protected readonly emitService: EmitService,
    protected readonly utilService: UtilService,
  ) {
    super(commentsNotesService, layoutService, emitService, utilService);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }
}
