import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
    selector: '[appTruncateLongName]',
    standalone: true
})
export class TruncateLongNameDirective implements AfterViewInit {
  constructor(private readonly ele: ElementRef) {}
  ngAfterViewInit() {
    if (this.ele.nativeElement.offsetWidth > 372) {
      let str = this.ele.nativeElement.innerHTML;
      str = str.substr(0, 38) + ' ... ' + str.substring(str.length - 12);
      this.ele.nativeElement.innerHTML = str;
    }
  }
}
