<div class="comment-panel" cdkTrapFocus>
  <ap-drawer #panel [(visible)]="isVerticalDrawerVisible" [placement]="'right'" [mask]="false"
    [drawerTitle]="panelInfo?.taskInfo?.name | decodeUri" [initialFocusIndex]="-1" [styleClass]="'drawer-container'"
    [resizable]="false" appClickOutside (clickOutside)="onClickedOutside($event)" (onClose)="closePanel()"
    [clickOutsideEnabled]="clickOutsideEnabled">
    <app-comments-panel
      *ngIf="panelInfo.toggle && (panelInfo?.panelType === PanelType.TASK_COMMENT || panelInfo?.panelType === PanelType.FILE_COMMENT)"
      [panelInfo]="panelInfo">
    </app-comments-panel>
    <app-notes-panel
      *ngIf="panelInfo.toggle && (panelInfo?.panelType === PanelType.TASK_NOTE || panelInfo?.panelType === PanelType.FILE_NOTE)"
      [panelInfo]="panelInfo">
    </app-notes-panel>
  </ap-drawer>
</div>
