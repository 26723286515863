<div class="notification-panel" appClickOutside (clickOutside)="onClickedOutside($event)" [clickOutsideEnabled]="clickOutsideEnabled">
  <ap-drawer [visible]="emitService.showNotificationsPanel$ | async" [placement]="'right'" [mask]="false" [initialFocusIndex]="0"
  [drawerTitle]="'Notifications'" (onClose)="closeNotificationPanel()">
  <div tabindex="0"></div>
    <div class="flex-normal flex-gap-3 flex-justify-end align-items-center ap-pr-spacing-2">
      <ap-button [btnType]="'secondary'" [disabled]="notificationsService.unreadCount <= 0" (click)="markAllNotifications()" (keyup.enter)="markAllNotifications()" [styleClass]="'mark-read'" [compact]="true" [label]="'Mark all as read'"></ap-button>
      <div class="divider"></div>
      <ap-toggle [styleClass]="'read-only'" [checked]="false" (onChange)="onToggleChange($event)">
        <span>Unread only</span>
      </ap-toggle>
    </div>
    <div class="read-split ap-mt-spacing-3"></div>
    <ng-container *ngIf="isHasNotifications(); else noResults">
      <div class="content flex-normal flex-direction-column flex-gap-4 ap-mr-spacing-2 ap-mt-spacing-6" (scroll)="handleScroll($event)">
        <div class="notification-item flex-normal align-items-center" *ngFor="let notification of notifications"
        (mouseenter)="notification.showOperations = true" (mouseleave)="notification.showOperations = false"
        tabindex="0" (focus)="notification.showOperations = true" role="listitem"
        >
          <span class="notification-left notification-dot ap-mr-spacing-4" [ngClass]="{'unread': notification.isRead}"></span>
          <div class="notification-center flex-normal align-items-center ap-mr-spacing-3" [ngSwitch]="notification.type">
            <ng-container *ngSwitchCase="TYPE.FILE_COMMENTS">
              <span class="notification-icon notification-icon-size Appkit4-icon {{'icon-' + (notification.attachedFileName ? 'link' : 'comment') + '-outline'}}"></span>
              <div class="flex-direction-column ap-ml-spacing-4 ">
                <div class="text-wrap-2">
                  <span class="ap-font-weight-2">{{notification.userName}}</span> commented on <span class="ap-font-weight-2">{{notification.reportId}}</span>
                  <span *ngIf="notification.attachedFileName"> with an attached file</span>
                  <span>:</span>
                </div>
                <div class="text-wrap-2" [innerHTML]="notification.content"></div>
                <div class="ap-typography-body-s">{{notification.createdAt | date:'MM/dd/yyyy hh:mm a'}}</div>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="TYPE.FILE_NOTES">
              <span class="notification-icon notification-icon-size Appkit4-icon icon-note-outline"></span>
              <div class="flex-direction-column ap-ml-spacing-4">
                <div class="text-wrap-2 break-all-word">
                  <span class="ap-font-weight-2">{{notification.userName}}</span> added a note on file
                  <span class="ap-font-weight-2">{{notification.reportId}}</span>
                  <span *ngIf="notification.attachedFileName"> with an attached file</span>
                  <span>:</span>
                </div>
                <div class="text-wrap-2 break-all-word" [innerHTML]="notification.content"></div>
                <div class="ap-typography-body-s">{{notification.createdAt | date:'MM/dd/yyyy hh:mm a'}}</div>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="TYPE.QUICK_LINK">
              <span class="notification-icon notification-icon-size Appkit4-icon icon-link-outline"></span>
              <div class="flex-direction-column ap-ml-spacing-4">
                <div class="text-wrap-2 break-all-word">
                  <span class="ap-font-weight-2">{{notification.userName}}</span> {{notification.action}} quick link:
                  <span>{{notification.linkName}}</span>
                </div>
                <div class="ap-typography-body-s">{{notification.createdAt | date:'MM/dd/yyyy hh:mm a'}}</div>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="TYPE.CUSTOMER_CHANGED">
              <span class="notification-icon notification-icon-size Appkit4-icon icon-avatar-outline"></span>
              <div class="flex-direction-column ap-ml-spacing-4">
                <div class="text-wrap-2 break-all-word">
                  <span class="ap-font-weight-2">{{notification.tenantName}}</span> is added to Bookkeeping Connect
                </div>
                <div class="ap-typography-body-s">{{notification.createdAt | date:'MM/dd/yyyy hh:mm a'}}</div>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="TYPE.TASKS">
              <span class="notification-icon notification-icon-size Appkit4-icon icon-check-hook-outline"></span>
              <div class="flex-direction-column ap-ml-spacing-4">
                <div class="text-wrap-2 break-all-word">
                  <ng-container
                    *ngTemplateOutlet="taskList;context:{taskLib: notification?.content?.taskLib, tenant: notification?.tenant}">
                  </ng-container>
                </div>
                <div class="ap-typography-body-s">{{notification.createdAt | date:'MM/dd/yyyy hh:mm a'}}</div>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="TYPE.TASK_COMMENTS">
              <span class="notification-icon notification-icon-size Appkit4-icon icon-comment-outline"></span>
              <div class="flex-direction-column ap-ml-spacing-4">
                <div class="text-wrap-2 break-all-word">
                  <span class="ap-font-weight-2">{{notification.userName}}</span> commented on Task
                  <span class="ap-font-weight-2">"{{notification.taskName}}"</span>
                  <span *ngIf="notification.attachedFileName"> with an attached file</span>
                  <span>:</span>
                </div>
                <div class="text-wrap-2 break-all-word" [innerHTML]="notification.content"></div>
                <div class="ap-typography-body-s">{{notification.createdAt | date:'MM/dd/yyyy hh:mm a'}}</div>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="TYPE.TASK_NOTES">
              <span class="notification-icon notification-icon-size Appkit4-icon icon-note-outline"></span>
              <div class="flex-direction-column ap-ml-spacing-4">
                <div class="text-wrap-2 break-all-word">
                  <span class="ap-font-weight-2">{{notification.userName}}</span> added a note on Task
                  <span  class="ap-font-weight-2">"{{notification.taskName}}"</span>
                  <span *ngIf="notification.attachedFileName"> with an attached file</span>
                  <span>:</span>
                </div>
                <div class="text-wrap-2 break-all-word" [innerHTML]="notification.content"></div>
                <div class="ap-typography-body-s">{{notification.createdAt | date:'MM/dd/yyyy hh:mm a'}}</div>
              </div>
            </ng-container>
          </div>
          <div class="notification-right" *ngIf="notification.showOperations">
            <span class="Appkit4-icon notification-icon notification-operator-icon ap-mr-spacing-4" 
              [ngClass]="{'icon-circle-checkmark-outline': !notification.isRead, 'icon-circle-checkmark-fill': notification.isRead}"
              [tooltipContent]="'Mark as '+(notification.isRead ? 'un' : '')+'read'" [direction]="'top'"
              [tooltipId]="'notification-read-tooltip'" ap-tooltip
              [attr.aria-label]="'Mark as '+(notification.isRead ? 'un' : '')+'read'" [attr.tabindex]="0" [attr.role]="'button'" role="button"
              (click)="markNotification(notification)" tabindex="0" (keyup.enter)="markNotification(notification)"
            ></span>
            <span class="Appkit4-icon icon-right-chevron-outline notification-icon notification-operator-icon"
              [tooltipContent]="'View '+(notification.type | notificationType)+' details'" [direction]="'top'"
              [tooltipId]="'notification-view-tooltip'" ap-tooltip
              [attr.aria-label]="'View '+(notification.type | notificationType)+' details'" role="button"
              (click)="markNotification(notification, true)" tabindex="0" (keyup.enter)="markNotification(notification, true)"
            ></span>
          </div>
        </div>
      </div>
      <div class="ap-mt-spacing-4">
        <ap-loading *ngIf="isLoadingMore" [loadingType]="'circular'" [indeterminate]="true"></ap-loading>
      </div>
      <ng-template #taskList let-taskLib="taskLib" let-tenant="tenant">
        <ng-container [ngSwitch]="taskLib?.taskNotifType">
          <ng-container *ngSwitchCase="TASK_TYPE.TASKLIST_PUBLISHED">
            <!-- <BK User name> published Bookkeeping Task List for <Tenant name> -->
            <span class="ap-font-weight-2">{{taskLib.userFullNm}}</span> published a task list for
            <span class="ap-font-weight-2">{{tenant.nme | decodeString}}</span>
          </ng-container>
          <ng-container *ngSwitchCase="TASK_TYPE.ASSIGN_TASK_ASSIGNEE">
            <!-- <BK User name> assigned a Task "<Taskname>" to <User Name> -->
            <span class="ap-font-weight-2">{{taskLib.userFullNm}}</span> assigned a Task <span class="ap-font-weight-2">"{{taskLib.taskNm}}"</span> to
            <span class="ap-font-weight-2">{{taskLib.targetUserFullNm}}</span>
          </ng-container>
          <ng-container *ngSwitchCase="TASK_TYPE.ASSIGN_TASK_REVIEWER">
            <!-- <BK User name> assigned <user name> as a reviewer for Task "<Task name>"  -->
            <span class="ap-font-weight-2">{{taskLib.userFullNm}}</span> assigned <span class="ap-font-weight-2">{{taskLib.targetUserFullNm}}</span> as a reviewer
            for Task <span class="ap-font-weight-2">"{{taskLib.taskNm}}"</span>
          </ng-container>
          <ng-container *ngSwitchCase="TASK_TYPE.RECURRING_TASK_ASSIGNEE">
            <!-- Task "<Taskname>" assigned to <User Name> -->
            Task <span class="ap-font-weight-2">"{{taskLib.taskNm}}"</span> assigned to <span class="ap-font-weight-2">{{taskLib.targetUserFullNm}}</span>
          </ng-container>
          <ng-container *ngSwitchCase="TASK_TYPE.RECURRING_TASK_REVIEWER">
            <!-- <User name> is assgiend as a reviewer for Task "<Task name>" -->
            <span class="ap-font-weight-2">{{taskLib.targetUserFullNm}}</span> is assigned as a reviewer for Task <span class="ap-font-weight-2">"{{taskLib.taskNm}}
              "</span>
          </ng-container>
          <ng-container *ngSwitchCase="TASK_TYPE.MARK_TASK_CLOSED">
            <!-- <BK User name> closed Task "<Task name>" for Onboarding Task List -->
            <span class="ap-font-weight-2">{{taskLib.userFullNm}}</span> closed Task <span class="ap-font-weight-2">"{{taskLib.taskNm}}"</span>
          </ng-container>
          <ng-container *ngSwitchCase="TASK_TYPE.MARK_TASK_COMPLETED">
            <!-- <Customer user name> marked Task "<Task name>" as complete -->
            <span class="ap-font-weight-2">{{taskLib.userFullNm}}</span> marked Task <span class="ap-font-weight-2">"{{taskLib.taskNm}}"</span> as complete
          </ng-container>
          <ng-container *ngSwitchCase="TASK_TYPE.MARK_TASK_ON_HOLD">
            <!-- <BK user name> changed the status of Task "<Task Name>" to On Hold -->
            <span class="ap-font-weight-2">{{taskLib.userFullNm}}</span> changed the status of Task <span class="ap-font-weight-2">"{{taskLib.taskNm}}"</span> to On
            Hold
          </ng-container>
          <ng-container *ngSwitchCase="TASK_TYPE.MARK_TASK_IN_PROGRESS">
            <!-- <BK user name> changed the status of Task "<Task Name>" to In Progress -->
            <span class="ap-font-weight-2">{{taskLib.userFullNm}}</span> changed the status of Task <span class="ap-font-weight-2">"{{taskLib.taskNm}}"</span> to In
            Progress
          </ng-container>
          <ng-container *ngSwitchCase="TASK_TYPE.MARK_TASK_WAITING_ON_CLIENT">
            <!-- <BK user name> changed the status of Task "<Task Name>" to Waiting on Client -->
            <span class="ap-font-weight-2">{{taskLib.userFullNm}}</span> changed the status of Task <span class="ap-font-weight-2">"{{taskLib.taskNm}}"</span> to
            Waiting on Client
          </ng-container>
          <ng-container *ngSwitchCase="TASK_TYPE.MARK_TASK_READY_FOR_REVIEW">
            <!-- <BK user name> changed the status of Task "<Task Name>" to Ready for Review -->
            <span class="ap-font-weight-2">{{taskLib.userFullNm}}</span> changed the status of Task <span class="ap-font-weight-2">"{{taskLib.taskNm}}"</span> to
            Ready for Review
          </ng-container>
          <ng-container *ngSwitchCase="TASK_TYPE.MARK_TASK_NOT_STARTED">
            <!-- <BK user name> changed the status of Task "<Task Name>" to Not Started -->
            <span class="ap-font-weight-2">{{taskLib.userFullNm}}</span> changed the status of Task <span class="ap-font-weight-2">"{{taskLib.taskNm}}"</span> to
            Not Started
          </ng-container>
          <ng-container *ngSwitchCase="TASK_TYPE.TASK_PAST_DUE">
            <!-- Task "<Task Name>" from Onboarding Task List is past due -->
            Task <span class="ap-font-weight-2">"{{taskLib.taskNm}}"</span> from Onboarding Task list is past due
          </ng-container>
          <ng-container *ngSwitchCase="TASK_TYPE.TASK_DUE_SOON">
            <!-- Task "<Task Name>" from Onboarding Task List will due within 3 days -->
            Task <span class="ap-font-weight-2">"{{taskLib.taskNm}}"</span> from Onboarding Task list will due within 3 days
          </ng-container>
          <ng-container *ngSwitchCase="TASK_TYPE.TASK_SKIP_APPROVAL">
            <!-- Task "<task name>" is marked as "Skip Approval" -->
            Task <span class="ap-font-weight-2">"{{taskLib.taskNm}}"</span> is marked as "Skip Approval"
          </ng-container>
        </ng-container>
      </ng-template>
    </ng-container>
    <ng-template #noResults>
      <div class="no-results flex-normal align-items-center flex-direction-column flex-justify-center">
        <div><img src="./assets/images/task-unreadied.svg" alt="no-results" /></div>
        <div class="ap-typography-heading-m ap-font-weight-2 ap-mt-spacing-6">You're all caught up!</div>
        <div class="ap-mt-spacing-6" *ngIf="unReadOnly">You don't have any unread activity here.</div>
        <div *ngIf="unReadOnly">Use the toggle to switch between read and unread activity.</div> 
      </div>
    </ng-template>
  </ap-drawer>
</div>
