import gql from 'graphql-tag';

export const changeOrganizationStatus = gql`
  mutation changeOrganizationStatus($input: ChangeOrganizationStatusInput) {
    changeOrganizationStatus(input: $input) {
      message
      status
      organization {
        product
        organizationId
        organizationName
        organizationStatus
      }
    }
  }
`;

export interface ChangeOrganizationStatusInput {
  organizationId: string;
  action: OrganizationStatusEnum;
  product: string;
  tenantKey: number;
}

export enum OrganizationStatusEnum {
  disable = 'DISABLE',
  enable = 'ENABLE',
}
