import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'currencyCalc',
    standalone: true
})
export class CurrencyCalcPipe implements PipeTransform {

  transform(value: any): string | null {
    if (value === null || value === undefined) {
      return null;
    }

    const strValue = value.toString();
    return strValue.charAt(0) === '-' ? `-$${strValue.substring(1)}` : `$${strValue}`;
  }

}
