import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { DynamicDialogService } from '@appkit4/angular-components/modal';
import { User } from '@models';
import { InfoModalComponent } from '@shared/component/modal/info-modal/info-modal.component';
import { AvatarComponent } from '@shared/component/appkit4-avatar/avatar.component';

export const DEACTIVATION_DIALOG_MODAL_ID = 'deactivationDialogModal';
@Component({
  selector: 'app-deactivation-dialog',
  templateUrl: './deactivation-dialog.component.html',
  styleUrls: ['./deactivation-dialog.component.scss'],
  standalone: true,
  imports: [InfoModalComponent, CommonModule, AvatarComponent],
})
export class DeactivationDialogComponent {
  data: User;
  constructor(private readonly dynamicDialogService: DynamicDialogService) {}

  logout() {
    this.dynamicDialogService.closeModal(DEACTIVATION_DIALOG_MODAL_ID);
  }
}
