<div class="ap-field-validation-error ap-typography-body-xs">
  <ng-container *ngIf="!control && condition; else hasControl">
    {{message}}
  </ng-container>
  <ng-template #hasControl>
    <ng-container *ngIf="control?.errors && (control.dirty || control.touched)">
      {{ name }} is required.
    </ng-container>
  </ng-template>
</div>
