import { Injectable } from '@angular/core';
import { GqlService } from '@core';
import {
  createEmailTemplate,
  copyEmailTemplate,
  getEmailTemplates,
  switchTemplateStatus,
  getEmailTemplate,
  updateEmailTemplate,
  CreateEmailTemplateInput,
  UpdateEmailTemplateInput,
  getEmailAttachment
} from '@core/gql/email-templates.gql';

@Injectable({
  providedIn: 'root',
})
export class EmailTemplatesService {
  constructor(private gqlService: GqlService) {}

  getTemplates(input) {
    return this.gqlService.query(getEmailTemplates, { input });
  }

  switchTemplateStatus(input) {
    return this.gqlService.mutate(switchTemplateStatus, { input });
  }

  copyTemplate(input) {
    return this.gqlService.mutate(copyEmailTemplate, { input });
  }

  getEmailTemplate(input: number) {
    return this.gqlService.query(getEmailTemplate, { templateKey: input });
  }

  createEmailTemplate(input: CreateEmailTemplateInput) {
    return this.gqlService.mutate(createEmailTemplate, { input }, { useMultipart: true });
  }

  updateEmailTemplate(input: UpdateEmailTemplateInput) {
    return this.gqlService.mutate(updateEmailTemplate, { input }, { useMultipart: true });
  }

  downloadEmailAttachment(emailAttachmentKey: number) {
    return this.gqlService.query(getEmailAttachment, { emailAttachmentKey });
  }
}
