import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { EmitService, LayoutService } from '@core';
import { EncapsulatedBannerComponent } from '../encapsulated-banner/encapsulated-banner.component';
import { FooterComponent } from '../footer/footer.component';
import { SpinnerComponent } from '../spinner/spinner.component';

@Component({
  selector: 'app-non-login-container',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    FooterComponent,
    EncapsulatedBannerComponent,
    SpinnerComponent,
  ],
  templateUrl: './non-login-container.component.html',
  styleUrls: ['./non-login-container.component.scss'],
})
export class NonLoginContainerComponent {
  constructor(
    public layoutService: LayoutService,
    public emitService: EmitService,
  ) {}
}
