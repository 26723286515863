export class RetrieveAssignedTenants {
  static readonly type = '[Tenants] Retrieve';
}

export class RetrieveTenant {
  static readonly type = `[Tenants] Retrieve one tenant`;
  constructor(
    public payload: number,
    public force = false,
    public orgGroupId = null,
  ) {}
}

export class RetrieveAllAssignedTenants {
  static readonly type = '[Tenants] all Retrieve';
}
