import { Injectable, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  CanDeactivateFn,
  RouterStateSnapshot,
} from '@angular/router';
import { FeatureManagementService } from '@core/services/feature-management.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ViewCustomerGuard {
  constructor(
    private readonly service: FeatureManagementService,
    public readonly permissionsService: NgxPermissionsService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    return forkJoin([
      this.service.listPermissions4CustomerAdmin(),
      this.service.getTenantDisPermission(Number(route.params.tenantKey)),
    ]).pipe(
      map(([{ data }, { disablePermissions }]) => {
        this.service.oriPermission = this.permissionsService.getPermissions();
        const customerAdminPermission = data
          .map((item) => item.nme)
          .filter((item) => !disablePermissions.includes(item));
        this.permissionsService.loadPermissions(customerAdminPermission);
        return true;
      }),
    );
  }

  canDeactivate(): boolean {
    this.permissionsService.loadPermissions(
      Object.keys(this.service.oriPermission),
    );
    return true;
  }
}

export const ViewCustomerCanActivateGuard: CanActivateFn = (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
): Observable<boolean> => {
  return inject(ViewCustomerGuard).canActivate(next, state);
};

export const ViewCustomerCanDeactivateGuard: CanDeactivateFn<
  CanComponentDeactivate
> = (): boolean => {
  return inject(ViewCustomerGuard).canDeactivate();
};

export interface CanComponentDeactivate {
  canDeactivate: () => boolean;
}
