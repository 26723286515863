import { MediaMatcher } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MediaQueryServiceService {

  private readonly mobileQuery: MediaQueryList = this.media.matchMedia('(max-width: 767px)');

  private readonly tabletQuery: MediaQueryList = this.media.matchMedia('(max-width: 1023px) and (min-width: 768px)');

  private readonly desktopQuery: MediaQueryList = this.media.matchMedia('(min-width: 1024px)');

  get isMobile(): boolean {
    return this.mobileQuery.matches;
  }

  get isTablet(): boolean {
    return this.tabletQuery.matches;
  }

  get isDesktop(): boolean {
    return this.desktopQuery.matches;
  }

  get isSafari(): boolean {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  }

  constructor(private readonly media: MediaMatcher) {
  }

}
