import { Injectable } from '@angular/core';
import { GqlService } from './gql.service';
import {
  BankFeedVariable,
  BankFeedQueryInput,
  getBankFeeds,
  getChartsOfAccount,
  getClasses,
  BankFeedInput,
  BankFeedFormVariable,
  submitBankFeed,
  ClassVariable,
  ChartsOfAccountInput,
} from '../gql';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ExpenseService {
  showSubmitButtonDisabled$: BehaviorSubject<boolean> = new BehaviorSubject(
    true,
  );

  selectedMemoChanged$: BehaviorSubject<Array<any>> = new BehaviorSubject([]);

  selectedCheckboxChecked$: BehaviorSubject<Array<any>> = new BehaviorSubject(
    [],
  );

  valueChanged$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private readonly gqlService: GqlService) {}

  getBankFeeds(bankFeedQueryInput: BankFeedQueryInput) {
    const variables: BankFeedVariable = {
      bankFeedQueryInput: {
        ...bankFeedQueryInput,
      },
    };
    return this.gqlService.query(getBankFeeds, variables);
  }

  getChartsOfAccounts(params: ChartsOfAccountInput) {
    const variables = {
      chartsOfAccountInput: {
        ...params,
      },
    };
    return this.gqlService.query(getChartsOfAccount, variables);
  }

  getClasses(tenantKey?: number) {
    const variables: ClassVariable = {
      tenantKey,
    };
    return this.gqlService.query(getClasses, variables);
  }

  submitBankFeedSubmission(bankFeedInput: BankFeedInput[]) {
    const variables: BankFeedFormVariable = {
      bankFeedInput: [...bankFeedInput],
    };
    return this.gqlService.mutate(submitBankFeed, variables);
  }
}
