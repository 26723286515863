import {
  DeliverableSentPeriod,
  MonthEndClosePeriod,
  StagesBlock,
  TenantStagePeriod,
} from '@core/gql/unmatched-transactions.gql';
import { Type } from 'class-transformer';
import { Unescape } from '../decorators';
import { TenantType } from './tenant-type.model';
import { User } from './user.model';

export enum OrgStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export class Tenant {
  tenantKey: number;

  @Unescape()
  nme: string;

  asgMtVersion: string;

  tenantStatus: string;

  connectionStatus: string;

  adminEmail: string;

  orgStatus: OrgStatus;

  orgStatusUpdatedAt: Date;

  initialLoaded: boolean;

  active: number;

  await: number;

  isBudgeting: boolean;

  onboardingStatus: string;

  onboardingStatusUpdatedAt: Date;

  monthEndClose: MonthEndClosePeriod;

  deliverableSent: DeliverableSentPeriod;

  tenantStage: TenantStagePeriod;

  taskCount?: number;

  publishedTaskCount?: number;
  lineage?: string;

  industry: { industryKey: number; name: string };

  entityType?: string;

  stageBlockList?: StagesBlock[];

  createdAt?: Date;

  @Type(() => User)
  users: User[];

  @Type(() => User)
  bkUsers?: User[];

  @Type(() => TenantType)
  tenantType: TenantType;

  get isConnected(): boolean {
    return this.connectionStatus === 'CONNECTED';
  }

  get isDisconnected(): boolean {
    return this.connectionStatus === 'DISCONNECTED';
  }

  get isNew(): boolean {
    return this.tenantStatus === 'NEW';
  }

  get isNotRegistered(): boolean {
    return this.tenantStatus === 'NOT_REGISTERED';
  }

  get connectionStatusDot(): string {
    switch (this.connectionStatus) {
      case 'CONNECTED':
        return 'datatable-dot-green';
      case 'DISCONNECTED':
        return 'datatable-dot-red';
    }
  }
}

export class TenantFilter {
  customer?: string;
  lastSuccessGeneration = '';
  dashboardStatus = '';
  connectionStatus = '';
  orgStatusUpdatedAt = '';
}
