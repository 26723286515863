<ap-breadcrumb>
  <ng-container *ngFor="let crumb of crumbList; let idx = index">
    <ng-container *ngxPermissionsOnly="crumb.permissions?.length > 0 ? null : crumb.permissions">
      <ap-breadcrumb-item path="{{crumb.path}}">
        <span *ngIf="crumb.icon" class="Appkit4-icon {{crumb.icon}}"></span>
        <span *ngIf="crumb.path" tabindex="0" role="link" (keyup.enter)="navigate(crumb)">{{crumb.title}}</span>
        <span *ngIf="!crumb.path" class="tenant-nme ap-font-weight-1 app-text-heading text-wrap-ellipsis" ap-tooltip
          [direction]="'top'" [tooltipId]="'tenantNme'" [tooltipContent]="crumb.title" ariaDescribedby="'Tenant Nme'"
          tabindex="-1">{{crumb.title}}</span>
      </ap-breadcrumb-item>
    </ng-container>
  </ng-container>
</ap-breadcrumb>