import gql from 'graphql-tag';

export const retrieveAccounts = gql`
  query retrieveAccounts(
    $retrieveAccountsInput: RetrieveAccountsInput!,
    $balanceGroupInput: BalanceGroupInput!
  ) {
    retrieveAccounts(input: $retrieveAccountsInput) {
      nme
      parentAccount {
        nme
        parentAccount {
          nme
        }
      }
      accountKey
      deep
      balances(input: $balanceGroupInput) {
        delta
        year
        deltaYtd
        monthlyDeltaChange
        month {
          monthKey
        }
      }
    }
  }
`;

export interface RetrieveAccountsInput {
  accountTypes?: string[];
  levelsDeep?: number[];
  tenantKey?: number;
}

export interface BalanceGroupInput {
  balanceKey?: number;
  monthKey?: number[];
  months?: string[];
  yearKey?: number[];
  years?: number[];
  accountTypeKey?: number[];
  accountTypes?: string[];
}

export interface RetrieveAccountsVariable {
  retrieveAccountsInput?: RetrieveAccountsInput;
  balanceGroupInput: BalanceGroupInput;
}
