import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AuthService,
  EmitService,
  UtilService,
  COMMENT_TYPE,
  LayoutService,
} from '@core';
import {
  NOTIFICATION_TASK_TYPE,
  NOTIFICATION_TYPE,
} from '@core/gql/notifications.gql';
import { NotificationsService } from '@core/services/notifications.service';
import { environment } from '@env';
import { Subscription, BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { unescape } from 'lodash';
import { User } from '@models';
import { plainToClass } from 'class-transformer';
import { NotificationTypePipe } from './notifications.pipe';
import { DecodeStringPipe } from '../../pipe/decode-string/decode-string.pipe';
import { CommonModule } from '@angular/common';
import { ClickOutsideDirective } from '@shared/directive/click-outside/click-outside.directive';
import { DrawerModule } from '@appkit4/angular-components/drawer';
import { ToggleModule } from '@appkit4/angular-components/toggle';
import { ButtonModule } from '@appkit4/angular-components/button';
import { FormsModule } from '@angular/forms';
import { TooltipModule } from '@appkit4/angular-components/tooltip';
import { LoadingModule } from '@appkit4/angular-components/loading';
import { AccessMode } from '@core/services/layout.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    DrawerModule,
    ToggleModule,
    ButtonModule,
    FormsModule,
    TooltipModule,
    LoadingModule,
    ClickOutsideDirective,
    DecodeStringPipe,
    NotificationTypePipe,
  ],
})
export class NotificationsComponent implements OnInit, OnDestroy {
  readonly TYPE = NOTIFICATION_TYPE;
  readonly TASK_TYPE = NOTIFICATION_TASK_TYPE;
  readonly AccessMode = AccessMode;
  isBK = false;
  notifications = [];
  tenantKey: number;
  scrollEvent$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  unReadOnly = false;
  unReadOnlySwitchState = false;
  isLoadingMore = false;
  limit = 25;
  remainCount = 0;
  scrollTarget: HTMLElement;
  clickOutsideEnabled = false;
  private readonly subs: Subscription[] = [];

  constructor(
    public readonly notificationsService: NotificationsService,
    public readonly emitService: EmitService,
    private readonly authService: AuthService,
    private readonly utilService: UtilService,
    private readonly router: Router,
    public layoutService: LayoutService,
  ) {}

  ngOnInit(): void {
    this.isBK = this.layoutService.accessMode === AccessMode.BOOKKEEPER;
    if (this.isBK) {
      this.getNotifications();
    }
    this.subs.push(
      // Customer user need to fetch notifications data when logging in to the system for the first time, or switching tenants
      this.emitService.switchTenant$.subscribe((e: any) => {
        if (e.type === 'init' || e.type === 'tenantSwitched') {
          this.tenantKey = e.tenantKey;
          if (this.layoutService.accessMode === AccessMode.CUSTOMER) {
            this.getNotifications();
          }
        }
      }),
      // Need to fetch notifications data every time open the panel
      this.emitService.showNotificationsPanel$.subscribe((isOpened) => {
        if (isOpened) {
          if (this.scrollTarget) {
            this.scrollTarget.scrollTop = 0;
          }
          setTimeout(() => {
            this.clickOutsideEnabled = true;
          }, 1000);
          this.getNotifications();
        } else {
          this.clickOutsideEnabled = false;
        }
      }),
      this.scrollEvent$
        .pipe(debounceTime(10), distinctUntilChanged())
        .subscribe((isReachingBottom) => {
          if (isReachingBottom) {
            this.getNotifications(true);
          }
        }),
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
    if (this.emitService.loadingAPICount > 0) {
      this.emitService.loadingAPICount = 0;
    }
  }

  handleScroll(ev: any) {
    const target = ev.target as HTMLElement;
    this.scrollTarget = target;
    const leftover = target.scrollHeight - target.scrollTop;
    const containerHeight = target.offsetHeight;
    const bottomScope = 180;
    this.scrollEvent$.next(leftover - containerHeight <= bottomScope);
  }

  getNotifications(isScrolled: boolean = false) {
    let truncTime = null;
    if (isScrolled) {
      if (this.remainCount === 0) return;
      truncTime = this.notifications[this.notifications.length - 1].createdAt;
    } else {
      this.emitService.startLoading();
    }
    this.isLoadingMore = isScrolled;
    this.subs.push(
      this.notificationsService
        .getNotifications({
          tenantKey: this.tenantKey,
          type: this.TYPE.ALL,
          limit: this.limit,
          truncTime,
        })
        .subscribe((data) => {
          this.unReadOnly = this.unReadOnlySwitchState;
          this.notificationsService.unreadCount = data.unreadCount;
          this.remainCount = data.remainCount;
          const feeds = data.data
            ?.filter((notification) => {
              return !this.unReadOnly || !notification.isRead;
            })
            .map((notification) => {
              const collectedData = this.collectNotificationData(notification);
              return {
                ...notification,
                ...collectedData,
                showOperations: false,
              };
            });

          if (isScrolled) {
            this.notifications = this.notifications.concat(feeds);
            this.isLoadingMore = false;
          } else {
            this.notifications = feeds;
            this.emitService.endLoading();
          }
        }),
    );
  }

  collectNotificationData(notification) {
    let collectedData;
    switch (notification.type) {
      case NOTIFICATION_TYPE.FILE_COMMENTS:
        const fileComment = notification.content?.fileComment;
        collectedData = {
          reportId: fileComment?.report?.reportId,
          userName:
            fileComment?.user?.firstNme + ' ' + fileComment?.user?.lastNme,
          attachedFileName: fileComment?.attachedFileName,
          content: fileComment?.content?.replace(
            /(\@[\w| ]+)\#\&/g,
            `<span class="ap-font-weight-2">$1 </span>`,
          ),
        };
        break;
      case NOTIFICATION_TYPE.FILE_NOTES:
        const fileNote = notification.content?.fileNote;
        collectedData = {
          reportId: fileNote?.report?.reportId,
          userName: fileNote?.user?.firstNme + ' ' + fileNote?.user?.lastNme,
          attachedFileName: fileNote?.attachedFileName,
          content: fileNote?.content?.replace(
            /(\@[\w| ]+)\#\&/g,
            `<span class="ap-font-weight-2">$1 </span>`,
          ),
        };
        break;
      case NOTIFICATION_TYPE.TASK_COMMENTS:
        collectedData = {
          taskProgressKey:
            notification.content?.taskComment?.task?.taskProgressKey,
          taskName: notification.content?.taskComment?.task?.name,
          userName:
            notification.content?.taskComment?.user?.firstNme +
            ' ' +
            notification.content?.taskComment?.user?.lastNme,
          attachedFileName: notification.content?.taskComment?.attachedFileName,
          content: notification.content?.taskComment?.content?.replace(
            /(\@[\w| ]+)\#\&/g,
            `<span class="ap-font-weight-2">$1 </span>`,
          ),
        };
        break;
      case NOTIFICATION_TYPE.TASK_NOTES:
        collectedData = {
          taskProgressKey:
            notification.content?.taskNote?.task?.taskProgressKey,
          taskName: notification.content?.taskNote?.task?.name,
          userName:
            notification.content?.taskNote?.user?.firstNme +
            ' ' +
            notification.content?.taskNote?.user?.lastNme,
          attachedFileName: notification.content?.taskNote?.attachedFileName,
          content: notification.content?.taskNote?.content?.replace(
            /(\@[\w| ]+)\#\&/g,
            `<span class="ap-font-weight-2">$1</span>`,
          ),
        };
        break;
      case NOTIFICATION_TYPE.QUICK_LINK:
        const { quickLinkOper } = notification.addition;
        const { quickLink } = notification.content;
        const notificationTime = new Date(notification.createdAt).getTime();
        const quickLinkTime = new Date(quickLink?.createdAt).getTime();
        collectedData = {
          userName: quickLinkOper?.firstNme + ' ' + quickLinkOper?.lastNme,
          action:
            notificationTime === quickLinkTime ? 'created a new' : 'updated a',
          linkName: quickLink?.name,
        };
        break;
      case NOTIFICATION_TYPE.CUSTOMER_CHANGED:
        const { nme } = notification.tenant;
        collectedData = {
          tenantName: unescape(nme),
        };
        break;
      default:
        collectedData = {};
    }
    return collectedData;
  }

  markNotification(notification: any, needRedirect: boolean = false) {
    if (needRedirect && notification.isRead) {
      this.needRedirectFromNotification(notification);
    } else {
      this.emitService.startLoading();
      this.subs.push(
        this.notificationsService
          .markNotification({
            userXNotificationKey: notification.id,
            tenantKey: this.tenantKey,
            isRead: !notification.isRead,
          })
          .subscribe((res) => {
            this.emitService.endLoading();
            if (res.status === 'SUCCESS') {
              if (needRedirect) {
                this.needRedirectFromNotification(notification);
              } else {
                notification.isRead = !notification.isRead;
                notification.isRead
                  ? this.notificationsService.unreadCount--
                  : this.notificationsService.unreadCount++;
                if (this.unReadOnly) {
                  this.notifications = this.notifications.filter(
                    (n) => !n.isRead,
                  );
                }
              }
            }
          }),
      );
    }
  }

  needRedirectFromNotification(notification: any) {
    this.getNotifications();
    this.closeNotificationPanel();
    this.viewNotification(notification);
  }

  markAllNotifications() {
    this.emitService.startLoading();
    this.subs.push(
      this.notificationsService
        .markNotification({
          userXNotificationKey: null,
          tenantKey: this.tenantKey,
          markAll: true,
          isRead: true,
        })
        .subscribe(() => {
          this.emitService.endLoading();
          this.notifications.forEach((n) => (n.isRead = true));
          this.notificationsService.unreadCount = 0;
          if (this.unReadOnly) {
            this.notifications = [];
          }
        }),
    );
  }

  onToggleChange(event) {
    this.unReadOnlySwitchState = event.switchState;
    this.getNotifications();
  }

  viewNotification(notification) {
    switch (notification.type) {
      case NOTIFICATION_TYPE.FILE_COMMENTS:
      case NOTIFICATION_TYPE.FILE_NOTES:
        let filePageUrl = '';
        let fileCommentQueryParams = {
          queryParams: {
            reportId: notification.reportId,
            panelType:
              notification.type === NOTIFICATION_TYPE.FILE_COMMENTS
                ? COMMENT_TYPE.FILE_COMMENT
                : COMMENT_TYPE.FILE_NOTE,
          },
        };
        if (this.isBK) {
          filePageUrl = this.getNotificationUrl(
            notification.tenantKey,
            '/reports',
          );
          this.utilService.reloadPage(filePageUrl, fileCommentQueryParams);
        } else if (/\/reports/.test(this.router.url)) {
          filePageUrl = `${environment.iam.redirectUri}/customer/reports`;
          window.location.replace(
            `${filePageUrl}?reportId=${notification.reportId}&panelType=${COMMENT_TYPE.FILE_COMMENT}&tenantKey=${notification.tenantKey}`,
          );
        } else {
          filePageUrl = '/customer/reports';
          Object.assign(fileCommentQueryParams.queryParams, {
            tenantKey: notification.tenantKey,
          });
          this.utilService.reloadPage(filePageUrl, fileCommentQueryParams);
        }
        break;
      case NOTIFICATION_TYPE.TASK_COMMENTS:
        let taskCommentUrl = '';
        let taskCommentQueryParams = null;
        if (!this.isBK) {
          if (
            /\/customer\/task-list\/customers-task-list\#allTasksTab\?tenantKey=\d+&taskProgressKey=\d+&panelType=Comment/.test(
              this.router.url,
            )
          ) {
            // Use window.location because unchanged url not re-trigger the initial of reports component
            window.location.replace(
              `${environment.iam.redirectUri}${taskCommentUrl}`,
            );
          } else {
            taskCommentUrl = `/customer/task-list/customers-task-list`;
            taskCommentQueryParams = {
              fragment: 'allTasksTab',
              queryParams: {
                tenantKey: this.tenantKey,
                taskProgressKey: notification.taskProgressKey,
                panelType: 'Comment',
              },
            };
            this.utilService.reloadPage(taskCommentUrl, taskCommentQueryParams);
          }
        } else {
          taskCommentUrl = this.getNotificationUrl(
            notification.tenantKey,
            '/onboarding/task-list',
          );
          taskCommentQueryParams = {
            queryParams: {
              taskProgressKey: notification.taskProgressKey,
              panelType: 'Comment',
            },
          };
          this.utilService.reloadPage(taskCommentUrl, taskCommentQueryParams);
        }
        break;
      case NOTIFICATION_TYPE.TASK_NOTES:
        if (this.isBK) {
          const taskNoteUrl = this.getNotificationUrl(
            notification.tenantKey,
            '/onboarding/task-list',
          );
          const taskNoteQueryParams = {
            queryParams: {
              taskProgressKey: notification.taskProgressKey,
              panelType: 'Note',
            },
          };
          this.utilService.reloadPage(taskNoteUrl, taskNoteQueryParams);
        }
        break;
      case NOTIFICATION_TYPE.QUICK_LINK:
        this.router.navigate(['/customer/quick-links']);
        break;
      case NOTIFICATION_TYPE.CUSTOMER_CHANGED:
        this.router.navigate([
          this.getNotificationUrl(
            notification.tenantKey,
            '/customer-detail',
            'customer-form/',
          ),
        ]);
        break;
      case NOTIFICATION_TYPE.TASKS:
        const tenantKey = notification?.tenantKey;
        const taskProgressKey = notification?.content?.taskLib?.taskProgressKey;
        const taskNotifType = notification?.content?.taskLib?.taskNotifType;

        if (!this.isBK) {
          this.utilService.reloadPage(
            `/customer/task-list/customers-task-list`,
            {
              fragment:
                taskNotifType === this.TASK_TYPE.MARK_TASK_COMPLETED
                  ? null
                  : 'allTasksTab',
              queryParams: {
                tenantKey: tenantKey,
                taskProgressKey: taskProgressKey,
              },
            },
          );
        } else {
          this.utilService.reloadPage(
            this.getNotificationUrl(tenantKey, '/onboarding/task-list'),
            {
              queryParams: {
                taskProgressKey: taskProgressKey,
              },
            },
          );
        }
        break;
      default:
    }
  }

  isHasNotifications() {
    return this.notifications.length > 0;
  }

  closeNotificationPanel() {
    this.emitService.showNotificationsPanel$.next(false);
  }

  onClickedOutside(e: any) {
    if (!e.srcElement?.classList?.contains('panel-target')) {
      this.emitService.showNotificationsPanel$.next(false);
    }
  }

  getNotificationUrl(tenantKey: any, path: string, prePath: string = '') {
    return `/bookkeeper/customers/${prePath}${tenantKey}${path}`;
  }
}
